import { Component, OnInit } from '@angular/core';
import { PhotographerService } from 'src/app/services/photographer.service';

@Component({
  selector: 'app-finished-photographer',
  templateUrl: './finished-photographer.component.html',
  styleUrls: ['./finished-photographer.component.scss'],
})
export class FinishedPhotographerComponent implements OnInit {

  photographerList:any [] = [];

  constructor(private photographerService:PhotographerService) { }

  ngOnInit() {
    this.listphotographer();
  }


  listphotographer() {

    this.photographerService.listPhotographer().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'finishedPhotos'){
            this.photographerList.push(item)
          }
        }
      },
      error => { console.error(error) }
    )
  }


}
