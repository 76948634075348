import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-contractspage-broker',
  templateUrl: './contractspage-broker.component.html',
  styleUrls: ['./contractspage-broker.component.scss'],
})
export class ContractspageBrokerComponent implements OnInit {

  schedulesNews: any = [];

  schedulesSelected: any;

  isModalsproposals = false;

  constructor(
    private router: Router,
    private proposalService: ProposalService,
  ) { }

  ngOnInit() {
    
    this.proposalService.listProposalBroker().subscribe(
      success => {
        for (const item of success) {
          if(item){
            this.schedulesNews.push(item)
          }
          
        }
      },
      error => { console.error(error) }
    )
  }

  goPropertyProposal(item) {
    localStorage.setItem('proposalId', JSON.stringify(item));
    this.router.navigate(['logged/proposal-broker']);
  }

}
