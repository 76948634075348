import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AppointmentTimeAddRequestDto } from "../dtos/appointment-time-add-register-request.dto";
import { AppointmentTimeRemoveRequestDto } from "../dtos/appointment-time-remove-appointment-time-request.dto";
import { AppointmentTimesListResponseDto } from "../dtos/appointment-times-list-response.dto";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class AppointmentTimesService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/appointment-times`;

    reload: boolean = false;

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    list(): Observable<AppointmentTimesListResponseDto> {
        return this.httpClient
            .get(`${this.url}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    removeHourInDay(dto: AppointmentTimeRemoveRequestDto) {
        return this.httpClient
        .post(`${this.url}/remove-appointment-time`, dto, this.authorizedHeader())
        .pipe(map(this.extractData), catchError(this.serviceError));
    }

    addHourInDay(dto: AppointmentTimeAddRequestDto) {
        return this.httpClient
        .post(`${this.url}/add-appointment-time`, dto, this.authorizedHeader())
        .pipe(map(this.extractData), catchError(this.serviceError));
    }

}