import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister6RequestDto } from '../../../dtos/announcement/announcement-register-6-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-six',
  templateUrl: './advertise-property-stage-six.component.html',
  styleUrls: ['./advertise-property-stage-six.component.scss'],
})
export class AdvertisePropertyStageSixComponent implements OnInit {
  form: FormGroup;
  valueCurrency: 0;

  request: AnnouncementRegister6RequestDto;

  response: AnnouncementGetResponseDto;

  showWhichFloorOfTheProperty: boolean = true;
  showNumberOfTowers: boolean = true;
  showHowManyFloors: boolean = true;
  showApartmentsPerrFloor: boolean = true;
  showElevatorsPerTower: boolean = true;

  nwalkrealestate = 0;
  ntowers = 0;
  nwalktowers = 0;
  nunitfloors = 0;
  ntowerelevators = 0;


  selectKeys = true;
  selectPassword = false;
  selectBiometric = false;

  yesPassword = false;
  noPassword = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      conciergeOpeningHours: [''],
      whichFloorOfTheProperty: [0],
      numberOfTowers: [0],
      howManyFloors: [0],
      apartmentsPerrFloor: [0],
      elevatorsPerTower: [0],
      typeOfKeys: [''],
      keyLocation: [''],
      digpassword: [''],
      biometria: [''],
      lockPassword: ['']
    });
  }



  ngOnInit() { }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    console.log(success)
    this.response = success;
    if (this.response.propertyCharacteristics === 'terreno' || this.response.propertyCharacteristics === 'casa') {
      this.showWhichFloorOfTheProperty = false;
      this.showNumberOfTowers = false;
      this.showHowManyFloors = false;
      this.showApartmentsPerrFloor = false;
      this.showElevatorsPerTower = false;
      this.form.patchValue({
        typeOfKeys: success.typeOfKeys,
        keyLocation: success.keyLocation,
        conciergeOpeningHours: success.conciergeOpeningHours,
      })
    } else {
      if(this.response.typeOfKeys !== null) {
        this.form.patchValue({
          whichFloorOfTheProperty: success.whichFloorOfTheProperty,
          numberOfTowers: success.numberOfTowers,
          howManyFloors: success.howManyFloors,
          apartmentsPerrFloor: success.apartmentsPerrFloor,
          elevatorsPerTower: success.elevatorsPerTower,
          typeOfKeys: success.typeOfKeys,
          keyLocation: success.keyLocation,
          conciergeOpeningHours: success.conciergeOpeningHours,
          lockPassword: success.lockPassword
        })
      }
    }
  }


  confirm() {
    this.request = Object.assign({}, this.form.value);
    
    if (this.response.propertyCharacteristics === 'terreno' || this.response.propertyCharacteristics === 'casa') {
      if (this.request.typeOfKeys !== this.response.typeOfKeys || this.request.keyLocation !== this.response.keyLocation || this.request.conciergeOpeningHours !== this.response.conciergeOpeningHours) {
        this.announcementService.updateStep6(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-seven']);
      }
    } else if (this.response.propertyCharacteristics === 'edificio') {
      if (this.request.typeOfKeys !== this.response.typeOfKeys || this.request.keyLocation !== this.response.keyLocation || this.request.whichFloorOfTheProperty !== this.response.whichFloorOfTheProperty || this.request.numberOfTowers !== this.response.numberOfTowers || this.request.apartmentsPerrFloor !== this.response.apartmentsPerrFloor || this.request.howManyFloors !== this.response.howManyFloors || this.request.elevatorsPerTower !== this.response.elevatorsPerTower || this.request.conciergeOpeningHours !== this.response.conciergeOpeningHours) {
        this.announcementService.updateStep6(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-seven']);
      }
    }
  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Informações sobre portaria, área externa e chaves cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-seven']);
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar informações sobre portaria, área externa e chaves!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }

  addProperty() {
    if (this.nwalkrealestate <= 1000) {
      this.nwalkrealestate++;
    }
  }
  removeProperty() {
    if (this.nwalkrealestate >= 1) {
      this.nwalkrealestate--;
    }
  }
  addTower() {
    if (this.ntowers <= 1000) {
      this.ntowers++;
    }
  }
  removeTower() {
    if (this.ntowers >= 1) {
      this.ntowers--;
    }
  }
  addWalkTower() {
    if (this.nwalktowers <= 1000) {
      this.nwalktowers++;
    }
  }
  removeWalkTower() {
    if (this.nwalktowers >= 1) {
      this.nwalktowers--;
    }
  }
  addUnitfloor() {
    if (this.nunitfloors <= 1000) {
      this.nunitfloors++;
    }
  }
  removeUnitfloor() {
    if (this.nunitfloors >= 1) {
      this.nunitfloors--;
    }
  }
  addTowerelevators() {
    if (this.ntowerelevators <= 1000) {
      this.ntowerelevators++;
    }
  }
  removeTowerelevators() {
    if (this.ntowerelevators >= 1) {
      this.ntowerelevators--;
    }
  }

  keysSelect(){
    this.selectKeys = true;
    this.selectPassword = false;
    this.selectBiometric = false;
  }
  PasswordSelect(){
    this.selectKeys = false;
    this.selectPassword = true;
    this.selectBiometric = false;
  }

  biometricSelect(){
    this.selectKeys = false;
    this.selectPassword = false;
    this.selectBiometric = true;
  }


  Passwordno(){
    this.noPassword = true;
    this.yesPassword = false;

  }

  Passwordyes(){
    this.noPassword = false;
    this.yesPassword = true;
  }

}
