import { Component, OnInit } from '@angular/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { environment } from '../../../../../../../environments/environment';
import { AnnouncementVisitGetResponseDto } from '../../../../../../dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementGetResponseDto } from '../../../../../../dtos/announcement/announcement-get-response.dto';

@Component({
  selector: 'app-data-surveyor',
  templateUrl: './data-surveyor.component.html',
  styleUrls: ['./data-surveyor.component.scss'],
})
export class DataSurveyorComponent implements OnInit {

  response: AnnouncementVisitGetResponseDto;

  completeAddress: string;
  mapImgLink: string;

  constructor(
    private callNumber: CallNumber,
  ) { }

  ngOnInit() {
    let anuncio = localStorage.getItem('proposalSelected');
    this.response = JSON.parse(anuncio);
    this.completeAddress = `${this.response.announcement.publicPlaceAddress},${this.response.announcement.districtAddress},${this.response.announcement.cityAddress},${this.response.announcement.ufAddress}`;

    this.updateMap()
  }

  updateMap() {
    this.mapImgLink = `https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=400x400&markers=color:red|${this.completeAddress}&key=${environment.google.apiKey}`;
  }

  call(item) {
    this.callNumber.callNumber(item, true)
  }

}
