import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Splash1Component } from './splash1/splash1.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { WelcomeComponent } from './welcome.component';

const routes: Routes = [
    {
        path: '',
        component: WelcomeComponent,
        children: [
            {
                path: '',
                component: Splash1Component,
            },
            {
                path: 'welcome',
                component: WelcomePageComponent,
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class WelcomeRouterModule { }
