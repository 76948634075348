import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-and-privacy',
  templateUrl: './policy-and-privacy.component.html',
  styleUrls: ['./policy-and-privacy.component.scss'],
})
export class PolicyAndPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
