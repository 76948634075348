import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class S3FilesService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/s3`

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    upload(file: any): Observable<any> {
        return this.httpClient
            .post(`${this.url}/upload`, file, this.authorizedHeaderFile())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    downloadTgtBucket(key: string): Observable<any> {
        const bucket = 'tgt-dev-bucket';
        return this.httpClient
            .post(`${this.url}/download/bucket/${bucket}/key/${key}`,null, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

}