import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AnnouncementRegisterValueRequestDto } from '../../../dtos/announcement/announcement-register-value-request.dto';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-advertise-property-stage-value',
  templateUrl: './advertise-property-stage-value.component.html',
  styleUrls: ['./advertise-property-stage-value.component.scss'],
})
export class AdvertisePropertyStageValueComponent implements OnInit {

  form: FormGroup;

  request: AnnouncementRegisterValueRequestDto;

  response: AnnouncementGetResponseDto;

  viewSale: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      leaseValue: [0],
      saleValue: [0]
    })
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )

  }

  organizeData(success) {
    this.response = success;
    if(this.response.typeOfAd == 'rent'){
      this.viewSale = false;
    }
    this.form.patchValue({
      leaseValue: success.leaseValue || 0,
      saleValue: success.saleValue || 0
    })
  }

  back() {
    this.router.navigate(['logged/advertise-property-stage-four']);
  }

  view(valueView: string) {
    if (valueView === 'rent') {
      this.viewSale = false
    } else {
      this.viewSale = true
    }
  }

  async confirm() {

    this.request = Object.assign({}, this.form.value);

    if (this.response.typeOfAd === 'both') {
      if (this.request.leaseValue === 0 || this.request.saleValue === 0) {
        const toast = await this.toastController.create({
          message: `Cadastre o valor de compra e aluguel!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        return
      }
    }
    if (this.response.typeOfAd === 'sale') {
      if (this.request.saleValue === 0) {
        const toast = await this.toastController.create({
          message: `Cadastre o valor para venda!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        return
      }
    }
    if (this.response.typeOfAd === 'rent') {
      if (this.request.leaseValue === 0) {
        const toast = await this.toastController.create({
          message: `Cadastre o valor da locação!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        return
      }
    }
    if (Number(this.response.saleValue) !== this.request.saleValue || Number(this.response.leaseValue) !== this.request.leaseValue) {


      this.announcementService.updateValue(this.response._id, this.request).subscribe({
        next: async data => {
          const toast = await this.toastController.create({
            message: `Valor do imóvel cadastrado com sucesso!`,
            duration: 1500,
            position: 'top',
            color: 'success',
          });
          toast.present();
          this.router.navigate(['logged/advertise-property-stage-five']);
        },
        error: async error => {
          const toast = await this.toastController.create({
            message: `Erro ao cadastrar valor do imovel!`,
            duration: 1500,
            position: 'top',
            color: 'danger',
          });
          toast.present();
        }
      })
    } else {
      this.router.navigate(['logged/advertise-property-stage-five']);
    }
  }

}
