import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Profile, UserGetResponseDto } from '../dtos/user-get-response.dto';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { UserService } from '../services/user.service';
import { ToastController } from '@ionic/angular';
import { ProfileService } from '../services/profile.service';
import { RequestProfileDto } from '../dtos/requestProfile.dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileClientEnum } from '../dtos/enum/profile-client.enum';
import { S3FilesService } from '../services/s3-files.service';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss'],
})
export class LoggedComponent implements OnInit {
  private myToast: any;

  user: UserGetResponseDto;
  userProfile: Profile[] = [];
  profileList: Profile[] = [];
  isModalbroker = false;
  isModalProperty = false;
  isOpenForm = false;
  profileSelected: Profile;

  profileRequestSelected: Profile;

  form: FormGroup;

  rgDoc: any[] = [];
  uploadrg = true;
  spinnerrg = true;
  checkmarkrg = false;

  selectedDocumentRg: any;

  rgDocumentSend: any;
  documentOwner: any = [];

  request: RequestProfileDto;

  constructor(
    private socialSharing: SocialSharing,
    protected router: Router,
    private userService: UserService,
    private toastController: ToastController,
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private s3FilesService: S3FilesService,
    route: ActivatedRoute
  ) {
    route.params.subscribe((val) => {
      this.user = JSON.parse(localStorage.getItem('userDto'));
      this.profileService.list().subscribe(
        (success) => {
          this.profileList = success.filter(
            (profile) => profile.name != 'admin'
          );
        },
        (error) => {
          console.error(error);
        }
      );
    });

    this.form = this.formBuilder.group({
      rg: [''],
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
    this.profileSelected = this.user?.profiles[0];
    this.userProfile = this.user?.profiles;
  }

  ionViewWillEnter() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
    this.profileSelected = this.user?.profiles[0];
    this.userProfile = this.user?.profiles;
  }

  goOwner() {
    this.router.navigate(['logged/owner-registration']);
  }
  goHome() {
    this.router.navigate(['logged/home']);
  }

  setCloseBroker(isOpen: boolean) {
    this.isModalbroker = isOpen;
  }

  // click solicitação
  async requestPermission(isOpen: boolean) {
    this.isOpenForm = isOpen;
    this.isModalbroker = isOpen;

    if (this.checkmarkrg) {
      const result = await this.upload(this.rgDocumentSend);
      let item = {
        documentKey: result.key,
      };
      this.documentOwner.push(item);
    }

    setTimeout(() => {
      let perfil = '';
      if ((this.profileRequestSelected.name = 'corretor')) {
        perfil = this.profileRequestSelected._id;
      } else if ((this.profileRequestSelected.name = 'fotógrafo')) {
        perfil = this.profileRequestSelected._id;
      } else if ((this.profileRequestSelected.name = 'vistoriador')) {
        perfil = this.profileRequestSelected._id;
      }
      this.request = {
        profileId: perfil,
        document: this.documentOwner[0].documentKey,
      };
      this.userService.requestProfile(this.request).subscribe(
        async (success) => {
          const toast = await this.toastController.create({
            message:
              `Perfil ` +
              this.profileRequestSelected.description +
              ` solicitado com sucesso`,
            duration: 1500,
            position: 'top',
            color: 'success',
          });
          toast.present();
          this.profileRequestSelected = null;
        },
        async (error) => {
          const toast = await this.toastController.create({
            message:
              `Já exite uma solicitação para o perfil ` +
              this.profileRequestSelected.description,
            duration: 1500,
            position: 'top',
            color: 'danger',
          });
          toast.present();
          this.profileRequestSelected = null;
          this.form.reset(['']);
        }
      );
      // this.router.navigate(['logged/broker-registration']);
    }, 200);
  }

  upload(documentSend) {
    return this.s3FilesService.upload(documentSend).toPromise();
  }

  setCloseGoProperty(isOpen: boolean) {
    this.isModalProperty = isOpen;
    setTimeout(() => {
      this.router.navigate(['logged/broker-registration']);
    }, 200);
  }

  setCloseProperty(isOpen: boolean) {
    this.isModalProperty = isOpen;
  }

  isVerifyProfile(profile: Profile) {
    const name = profile.name.toLowerCase();
    const filterName = JSON.parse(localStorage.userDto).profiles.filter(item => item.name == name)
    const validProfile = JSON.parse(localStorage.userDto).profiles.some(item => item.name == name)
    

    if (name === 'proprietário') {
      this.profileSelected = filterName[0];
      this.router.navigate(['logged/owner-registration']);
      return;
    }
    if (name === 'indicação') {
      this.profileSelected = filterName[0];
      this.router.navigate(['logged/home']);
      return;
    }

    let exist = this.userProfile.some(
      (item) => item.name.toLowerCase() == name
    );

    if (exist) {
      if (name == 'fotógrafo') {
        this.profileSelected = filterName[0];
        this.router.navigate(['logged/photographer']);
        return;
      }

      if (name == 'corretor'&& validProfile) {        
        this.profileSelected = filterName[0];
        this.router.navigate(['logged/broker-registration']);
        
        return;
      }

      if (name == 'corretor_parceiro') {
        this.profileSelected = profile;
        this.router.navigate(['logged/broker-registration']);
        return;
      }

      if (name == 'vistoriador') {
        this.profileSelected = filterName[0];
        this.router.navigate(['logged/surveyor']);
        return;
      }

      this.profileRequestSelected = profile;
      this.isModalbroker = true;
    } else {
      this.profileRequestSelected = profile;
      this.isModalbroker = true;
    }
  }

  compartilhar() {
    this.socialSharing.share('https://www.imoveistock.com.br/');
  }

  onSelectRg(event) {
    this.rgDoc.push(event.target.files[0]);
    this.selectedDocumentRg = <File>event.target.files[0];
    this.rgDocumentSend = new FormData();
    this.rgDocumentSend.append(
      'file',
      this.selectedDocumentRg,
      this.selectedDocumentRg.name
    );
    setTimeout(() => {
      this.spinnerrg = false;
      this.checkmarkrg = true;
      this.uploadrg = false;
    }, 1000);
  }

  checkOut() {
    localStorage.removeItem('user');
    this.router.navigate(['auth/insert-tel']);
  }
}
