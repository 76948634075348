import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {

  // bgWelcome1: boolean = true;
  // bgWelcome2: boolean = false;
  // bgWelcome3: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  // changeBg(value) {
  //   if (value === 'bg-welcome-1') {
  //     var content = document.getElementById('content');
  //     content.classList.remove("bg-welcome-2");
  //     content.classList.remove("bg-welcome-3");
  //     content.classList.add("bg-welcome-1");
  //     this.bgWelcome1 = true;
  //     this.bgWelcome2 = false;
  //     this.bgWelcome3 = false;
  //   } else if (value === 'bg-welcome-2') {
  //     var content = document.getElementById('content');
  //     content.classList.remove("bg-welcome-1");
  //     content.classList.remove("bg-welcome-3");
  //     content.classList.add("bg-welcome-2");
  //     this.bgWelcome2 = true;
  //     this.bgWelcome1 = false;
  //     this.bgWelcome3 = false;
  //   } else if (value === 'bg-welcome-3') {
  //     var content = document.getElementById('content');
  //     content.classList.remove("bg-welcome-1");
  //     content.classList.remove("bg-welcome-2");
  //     content.classList.add("bg-welcome-3");
  //     this.bgWelcome3 = true;
  //     this.bgWelcome2 = false;
  //     this.bgWelcome1 = false;
  //   }
  // }

  next() {
    this.router.navigate(['auth/login-or-register'])
  }

  goToLogin() {
    this.router.navigate(['auth/login-or-register'])
  }

}
