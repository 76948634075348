import { Component, OnInit } from '@angular/core';
import {ToastController} from "@ionic/angular";
import {PhotographerService} from "../../../../services/photographer.service";
import {AcceptPhotographerDto} from "../../../../dtos/accept-photographer.dto";
import {AuthenticationService} from "../../../../services/authentication.service";
import {ProfileClientEnum} from "../../../../dtos/enum/profile-client.enum";

@Component({
  selector: 'app-news-photographer',
  templateUrl: './news-photographer.component.html',
  styleUrls: ['./news-photographer.component.scss'],
})
export class NewsPhotographerComponent implements OnInit {

  today: Date;
  photographerList:any [] = [];

  optionsPhotographer: any [];
  select = 0;
  request: AcceptPhotographerDto
  idteste:string;


  constructor(
    private toastController: ToastController,
    private photographerService:PhotographerService,
    private  authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.listphotographer();

  }

  listphotographer() {

    this.photographerService.listPhotographer().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'pendingPhotos'){
            this.photographerList.push(item);
          }
        }
      },
      error => { console.error(error) }
    )
  }



  async acceptVisit(i: number) {
    this.request ={
      status : 'scheduledphoto',
      photographerId: ProfileClientEnum.fotografo
    };
    this.photographerService.updateStatus(this.photographerList[i]._id, this.request).subscribe(
      async success =>{
        this.photographerList.splice(i,1)
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )
  }

  async refuseVisit() {
    let filter = this.optionsPhotographer.indexOf(this.select);
    this.optionsPhotographer.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Sua visita foi removida da lista!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    // this.isModalsproposals = false
  }


}
