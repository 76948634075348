import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AuthComponent } from '../auth/auth.component';
import { IonMaskDirective } from '../directives/mask.directive';
import { AuthenticationService } from '../services/authentication.service';
import { ProfileService } from '../services/profile.service';
import { UserService } from '../services/user.service';
import { AuthRouterModule } from './auth.router';
import { InsertCodeComponent } from './insert-code/insert-code.component';
import { InsertTelComponent } from './insert-tel/insert-tel.component';
import { LoginOrRegisterComponent } from './login-or-register/login-or-register.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TermComponent } from '../shared/term/term.component';
import { PoliticComponent } from '../shared/politic/politic.component';
import { AuthorizationHttpInterceptor } from '../interceptors/http-interceptor';

@NgModule({
    declarations: [
        AuthComponent,
        LoginOrRegisterComponent,
        InsertTelComponent,
        IonMaskDirective,
        InsertCodeComponent,
        SignUpComponent,
        TermComponent,
        PoliticComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        AuthRouterModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true
        },
        AuthenticationService,
        UserService,
        ProfileService
    ]

})
export class AuthModule { }
