import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { UserEditPersonalDataRequestDto } from '../../../../../dtos/user-edit-personal-data-request.dto';
import { UserEditRequestDto } from '../../../../../dtos/user-edit-request.dto';
import { UserGetResponseDto } from '../../../../../dtos/user-get-response.dto';
import { states, cities } from 'estados-cidades';
import { UserService } from 'src/app/services/user.service';
import { element } from 'protractor';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent implements OnInit {
  @Output() onClick = new EventEmitter;

  form: FormGroup;

  userRequestEdit: UserEditRequestDto;

  userPersonalDataRequest: UserEditPersonalDataRequestDto;

  user: UserGetResponseDto;

  dateFormated: string = '';

  public maskBirthDate: Array<any> = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public maskCep: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  public maskCpf: Array<any> = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public maskTel: Array<any> = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskCurrent: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/];
  public maskAgency: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/];

  selectBank = [
    { bank: 'Banco BMG' },
    { bank: 'Banco do Brasil' },
    { bank: 'Banco do Nordeste' },
    { bank: 'Banco Original' },
    { bank: 'Banco Safra' },
    { bank: 'Banrisul' },
    { bank: 'Bradesco' },
    { bank: 'BTG Pactual' },
    { bank: 'C6 bank' },
    { bank: 'Caixa' },
    { bank: 'Citibank Brasil' },
    { bank: 'Itaú' },
    { bank: 'Neon' },
    { bank: 'Next' },
    { bank: 'Nubank S.A' },
    { bank: 'PagBank' },
    { bank: 'Santander' },
    { bank: 'Sicoob' },
    { bank: 'Sicredi' },
    { bank: "Banco da Amazônia S."},
    { bank: "Banco do Nordeste do Brasil S.A."},
    { bank: "Banco Nacional de Desenvolvimento Econômico e Social BNDES"},
    { bank: "Credicoamo Crédito Rural Cooperativa"},
    { bank: "Credit Suisse Hedging-Griffo Corretora de Valores S.A."},
    { bank: "Banco Inbursa S.A."},
    { bank: "Natixis Brasil S.A. Banco Múltiplo"},
    { bank: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A."},
    { bank: "Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul"},
    { bank: "BNY Mellon Banco S.A."},
    { bank: "Banco Tricury S.A."},
    { bank: "Banestes S.A. Banco do Estado do Espírito Santo"},
    { bank: "Banco Bandepe S.A."},
    { bank: "Banco Alfa S.A."},
    { bank: "Banco Itaú Consignado S.A."},
    { bank: "Banco Santander (Brasil) S. A."},
    { bank: "Banco Bradesco BBI S.A."},
    { bank: "Banco do Estado do Pará S.A."},
    { bank: "Banco Cargill S.A."},
    { bank: "Banco do Estado do Rio Grande do Sul S.A."},
    { bank: "Banco do Estado de Sergipe S.A."},
    { bank: "Confidence Corretora de Câmbio S.A."},
    { bank: "Hipercard Banco Múltiplo S.A."},
    { bank: "Banco Bradescard S.A."},
    { bank: "Goldman Sachs do Brasil  Banco Múltiplo S. A."},
    { bank: "Banco AndBank (Brasil) S.A."},
    { bank: "Banco Morgan Stanley S. A."},
    { bank: "Banco Crefisa S.A."},
    { bank: "Banco de Brasília S.A."},
    { bank: "Banco J. Safra S.A."},
    { bank: "Banco ABN Amro S.A."},
    { bank: "Banco KDB do Brasil S.A."},
    { bank: "Banco Inter S.A."},
    { bank: "Haitong Banco de Investimento do Brasil S.A."},
    { bank: "Banco Original do Agronegócio S.A."},
    { bank: "BT Corretora de Câmbio Ltda."},
    { bank: "BBN Banco Brasileiro de Negocios S.A."},
    { bank: "Banco Topazio S.A."},
    { bank: "Banco da China Brasil S.A."},
    { bank: "Uniprime Norte do Paraná - Cooperativa de Crédito Ltda."},
    { bank: "Cooperativa Central de Crédito Urbano - Cecred"},
    { bank: "Cooperativa de Crédito Rural da Região da Mogiana"},
    { bank: "Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred"},
    { bank: "BRK S.A. Crédito, Financiamento e Investimento"},
    { bank: "Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte"},
    { bank: "Banco Finaxis S.A."},
    { bank: "Banco Confidence de Câmbio S.A."},
    { bank: "Banco BMFBovespa de Serviços de Liquidação e Custódia S/A"},
    { bank: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi"},
    { bank: "Credialiança Cooperativa de Crédito Rural"},
    { bank: "Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda."},
    { bank: "Planner Corretora de Valores S.A."},
    { bank: "Renascença Distribuidora de Títulos e Valores Mobiliários Ltda."},
    { bank: "XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A."},
    { bank: "Caixa Econômica Federal"},
    { bank: "Lecca Crédito, Financiamento e Investimento S/A"},
    { bank: "Banco Bocom BBM S.A."},
    { bank: "PortoCred S.A. Crédito, Financiamento e Investimento"},
    { bank: "Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A."},
    { bank: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios"},
    { bank: "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP"},
    { bank: "Advanced Corretora de Câmbio Ltda."},
    { bank: "Standard Chartered Bank (Brasil) S.A. Banco de Investimento"},
    { bank: "Banco Western Union do Brasil S.A."},
    { bank: "Banco Rodobens SA"},
    { bank: "Banco Agibank S.A."},
    { bank: "Banco Bradesco BERJ S.A."},
    { bank: "Banco Woori Bank do Brasil S.A."},
    { bank: "Brasil Plural S.A. Banco Múltiplo"},
    { bank: "BR Partners Banco de Investimento S.A."},
    { bank: "Codepe Corretora de Valores e Câmbio S.A."},
    { bank: "MS Bank S.A. Banco de Câmbio"},
    { bank: "UBS Brasil Banco de Investimento S.A."},
    { bank: "Caruana S.A. Sociedade de Crédito, Financiamento e Investimento"},
    { bank: "Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda."},
    { bank: "ICBC do Brasil Banco Múltiplo S.A."},
    { bank: "Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e"},
    { bank: "BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda."},
    { bank: "Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A."},
    { bank: "Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil"},
    { bank: "Multimoney Corretora de Câmbio Ltda"},
    { bank: "Get Money Corretora de Câmbio S.A."},
    { bank: "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo"},
    { bank: "Easynvest - Título Corretora de Valores SA"},
    { bank: "Broker Brasil Corretora de Câmbio Ltda."},
    { bank: "Treviso Corretora de Câmbio S.A."},
    { bank: "Bexs Banco de Câmbio S.A."},
    { bank: "Levycam - Corretora de Câmbio e Valores Ltda."},
    { bank: "Guitta Corretora de Câmbio Ltda."},
    { bank: "Facta Financeira S.A. - Crédito Financiamento e Investimento"},
    { bank: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda."},
    { bank: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor"},
    { bank: "Commerzbank Brasil S.A. - Banco Múltiplo"},
    { bank: "Banco Olé Bonsucesso Consignado S.A."},
    { bank: "Albatross Corretora de Câmbio e Valores S.A"},
    { bank: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A."},
    { bank: "Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento"},
    { bank: "Guide Investimentos S.A. Corretora de Valores"},
    { bank: "CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."},
    { bank: "Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento"},
    { bank: "Socred S.A. - Sociedade de Crédito ao Microempreendedor"},
    { bank: "Banco Itaú BBA S.A."},
    { bank: "Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores"},
    { bank: "HS Financeira S/A Crédito, Financiamento e Investimentos"},
    { bank: "Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio"},
    { bank: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda."},
    { bank: "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda."},
    { bank: "Fair Corretora de Câmbio S.A."},
    { bank: "Stone Pagamentos S.A."},
    { bank: "Banco Bradesco Cartões S.A."},
    { bank: "Banco BTG Pactual S.A."},
    { bank: "Banco Original S.A."},
    { bank: "Banco Arbi S.A."},
    { bank: "Banco John Deere S.A."},
    { bank: "Banco BS2 S.A."},
    { bank: "Banco Credit Agrícole Brasil S.A."},
    { bank: "Banco Fibra S.A."},
    { bank: "Banco Cifra S.A."},
    { bank: "Banco Bradesco S.A."},
    { bank: "Banco Clássico S.A."},
    { bank: "Banco Máxima S.A."},
    { bank: "Banco ABC Brasil S.A."},
    { bank: "Banco Investcred Unibanco S.A."},
    { bank: "BCV - Banco de Crédito e Varejo S/A"},
    { bank: "Bexs Corretora de Câmbio S/A"},
    { bank: "Parana Banco S. A."},
    { bank: "Nu Pagamentos S.A."},
    { bank: "Banco Fator S.A."},
    { bank: "Banco Cédula S.A."},
    { bank: "Barigui Companhia Hipotecária"},
    { bank: "HSBC Brasil S.A. Banco de Investimento"},
    { bank: "IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda."},
    { bank: "Banco de la Nacion Argentina"},
    { bank: "Banco BMG S.A."},
    { bank: "China Construction Bank (Brasil) Banco Múltiplo S/A"},
    { bank: "Itaú Unibanco  S.A."},
    { bank: "Banco Société Générale Brasil S.A."},
    { bank: "Banco Mizuho do Brasil S.A."},
    { bank: "Banco J. P. Morgan S. A."},
    { bank: "Banco Mercantil do Brasil S.A."},
    { bank: "Banco Bradesco Financiamentos S.A."},
    { bank: "Kirton Bank S.A. - Banco Múltiplo"},
    { bank: "Banco Capital S. A."},
    { bank: "Banco Safra S.A."},
    { bank: "Banco MUFG Brasil S.A."},
    { bank: "Banco Sumitomo Mitsui Brasileiro S.A."},
    { bank: "Banco Caixa Geral - Brasil S.A."},
    { bank: "Citibank N.A."},
    { bank: "Banco ItauBank S.A."},
    { bank: "Deutsche Bank S.A. - Banco Alemão"},
    { bank: "JPMorgan Chase Bank, National Association"},
    { bank: "ING Bank N.V."},
    { bank: "Banco de La Republica Oriental del Uruguay"},
    { bank: "Banco de La Provincia de Buenos Aires"},
    { bank: "Banco Credit Suisse (Brasil) S.A."},
    { bank: "Senso Corretora de Câmbio e Valores Mobiliários S.A."},
    { bank: "Banco Luso Brasileiro S.A."},
    { bank: "Banco Industrial do Brasil S.A."},
    { bank: "Banco VR S.A."},
    { bank: "Banco Paulista S.A."},
    { bank: "Banco Guanabara S.A."},
    { bank: "Omni Banco S.A."},
    { bank: "Banco Pan S.A."},
    { bank: "Banco Ficsa S. A."},
    { bank: "Banco Intercap S.A."},
    { bank: "Banco Rendimento S.A."},
    { bank: "Banco Triângulo S.A."},
    { bank: "Banco Sofisa S. A."},
    { bank: "Banco Alvorada S.A."},
    { bank: "Banco Pine S.A."},
    { bank: "Itaú Unibanco Holding S.A."},
    { bank: "Banco Indusval S. A."},
    { bank: "Banco A. J. Renner S.A."},
    { bank: "Banco Votorantim S.A."},
    { bank: "Banco Daycoval S.A."},
    { bank: "Banco Ourinvest S.A."},
    { bank: "Banif - Bco Internacional do Funchal (Brasil) S.A."},
    { bank: "Banco Neon S.A."},
    { bank: "Banco Cetelem S.A."},
    { bank: "Banco Ribeirão Preto S.A."},
    { bank: "Banco Semear S.A."},
    { bank: "Banco Citibank S.A."},
    { bank: "Banco Modal S.A."},
    { bank: "Banco Rabobank International Brasil S.A."},
    { bank: "Banco Cooperativo Sicredi S. A."},
    { bank: "Scotiabank Brasil S.A. Banco Múltiplo"},
    { bank: "Banco BNP Paribas Brasil S.A."},
    { bank: "Novo Banco Continental S.A. - Banco Múltiplo"},
    { bank: "Banco Sistema S.A."},
    { bank: "Bank of America Merrill Lynch Banco Múltiplo S.A."},
    { bank: "Banco Cooperativo do Brasil S/A - Bancoob"},
    { bank: "Banco Keb Hana do Brasil S. A." }

  ]


  stateSelected: string;
  cities: any[];
  states: any[];



  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      birthdate: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      nameBank: ['', [Validators.required]],
      agency: ['', [Validators.required]],
      current: ['', [Validators.required]],
      pix: [''],
      dropdown: [''],
    })
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));

    let dateTotal = new Date(this.user.personalData?.birthDate);
    let dayBirthDate: any = dateTotal.getDate();
    let monthBirthDate: any = dateTotal.getMonth() + 1;
    if (monthBirthDate < 10) {
      monthBirthDate = `0${monthBirthDate}`
    }
    let yearBirthDate = dateTotal.getFullYear();

    if (dayBirthDate < 10) {
      dayBirthDate = `0${dayBirthDate}`
    }

    this.dateFormated = `${dayBirthDate}/${monthBirthDate}/${yearBirthDate}`;

    if (this.user.personalData === null) {
      this.form.patchValue({
        birthdate: '',
        state: '',
        city: '',
        nameBank: '',
        agency: '',
        current: '',
        pix: ''
      })
    } else if (this.user.personalData) {
      this.form.patchValue({
        birthdate: this.dateFormated.toString(),
        state: this.user.personalData?.state,
        city: this.user.personalData?.city,
        nameBank: this.user.personalData?.bankInfo?.name,
        agency: this.user.personalData?.bankInfo?.agencyNumber,
        current: this.user.personalData?.bankInfo?.accountNumber,
        pix: this.user.personalData?.pix
      })
      this.stateSelected = this.user.personalData?.state;

      for (let i = 0; i < this.selectBank.length; i++) {
        if (this.selectBank[i].bank === this.user.personalData?.bankInfo?.name) {
          let filter = this.selectBank.indexOf(this.selectBank[i]);
          this.selectBank.splice(filter, 1);
        }
      }
      this.selectBank.unshift({ bank: this.user.personalData?.bankInfo?.name })
    }


    this.form.patchValue({
      name: this.user.name,
      email: this.user.email,
      cpf: this.user.cpf,
      phone: this.user.phone.slice(2, 13),
    })



    this.states = states();
  }

  getCities() {
    this.cities = cities(this.stateSelected);
  }

  closePersonalDataForm(event) {
    this.onClick.emit(event);
  }

  async confirm() {


    if ((this.form.controls.name.value !== this.user.name || this.form.controls.phone.value.replace(/\D/g, '') !== this.user.phone.slice(2, 13) || this.form.controls.cpf.value.replace(/\D/g, '') !== this.user.cpf && this.form.controls.birthdate.value !== this.dateFormated || this.form.controls.state.value !== this.user.personalData?.state || this.form.controls.city.value !== this.user.personalData?.city || this.form.controls.nameBank.value !== this.user.personalData?.bankInfo?.name || this.form.controls.agency.value !== this.user.personalData?.bankInfo?.agencyNumber || this.form.controls.current.value !== this.user.personalData?.bankInfo?.accountNumber || this.form.controls.pix.value !== this.user.personalData?.pix)) {

      this.editUserComplete();

    } else if (this.form.controls.birthdate.value === '' || this.form.controls.state.value === '' || this.form.controls.city.value === '' || this.form.controls.nameBank.value === ''
      || this.form.controls.agency.value === '' || this.form.controls.current.value === '' || this.form.controls.pix.value === '') {
      const toast = await this.toastController.create({
        message: `Preencha todos os campos para alterar perfil!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();

    } else if (this.form.controls.birthdate.value !== this.dateFormated || this.form.controls.state.value
      !== this.user.personalData?.state || this.form.controls.city.value !== this.user.personalData?.city ||
      this.form.controls.nameBank.value !== this.user.personalData?.bankInfo?.name || this.form.controls.agency.value
      !== this.user.personalData?.bankInfo?.agencyNumber || this.form.controls.current.value !== this.user.personalData?.bankInfo?.accountNumber || this.form.controls.pix.value !== this.user.personalData?.pix) {

      let dateFormatBrs = this.form.controls.birthdate.value.split("/")
      let date: Date = new Date(+dateFormatBrs[2], dateFormatBrs[1] -1, +dateFormatBrs[0]);


      this.userPersonalDataRequest = {
        birthDate: date,
        city: this.form.controls.city.value,
        state: this.form.controls.state.value,
        bankInfo: {
          name: this.form.controls.nameBank.value,
          accountNumber: this.form.controls.current.value,
          agencyNumber: this.form.controls.agency.value,
        },
        pix: this.form.controls.pix.value,
      }


      this.userService.editPersonalData(this.userPersonalDataRequest).subscribe(
        success => {
          console.log(this.userPersonalDataRequest)
          this.editSuccess('personalData')
        },
        async error => {
          const toast = await this.toastController.create({
            message: `Erro ao alterar perfil!`,
            duration: 1500,
            position: 'top',
            color: 'danger',
          });
          toast.present();
          console.error(error)
        }
      )
    }

  }

  editUserComplete() {
    /* edit user */
    let cpfMask = this.form.controls.cpf.value
    if (this.form.controls.cpf.value.length < 14)
      cpfMask = `${this.form.controls.cpf.value[0]}${this.form.controls.cpf.value[1]}${this.form.controls.cpf.value[2]}.${this.form.controls.cpf.value[3]}${this.form.controls.cpf.value[4]}${this.form.controls.cpf.value[5]}.${this.form.controls.cpf.value[6]}${this.form.controls.cpf.value[7]}${this.form.controls.cpf.value[8]}-${this.form.controls.cpf.value[9]}${this.form.controls.cpf.value[10]}`

    let niverFormatBr = this.form.controls.birthdate.value.split("/")
    let niver: Date = new Date(+niverFormatBr[2], niverFormatBr[1] - 1, +niverFormatBr[0]);
    this.userRequestEdit = {
      birthDate: niver,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      bankInfo: {
        name: this.form.controls.nameBank.value,
        accountNumber: this.form.controls.current.value,
        agencyNumber: this.form.controls.agency.value,
      },
      pix: this.form.controls.pix.value,
      cpf: cpfMask,
      name: this.form.controls.name.value,
      phone: `55${this.form.controls.phone.value}`,
    }


    // if (this.form.controls.cpf.value.length < 14) {
    //   let cpfMask = `${this.form.controls.cpf.value[0]}${this.form.controls.cpf.value[1]}${this.form.controls.cpf.value[2]}.${this.form.controls.cpf.value[3]}${this.form.controls.cpf.value[4]}${this.form.controls.cpf.value[5]}.${this.form.controls.cpf.value[6]}${this.form.controls.cpf.value[7]}${this.form.controls.cpf.value[8]}-${this.form.controls.cpf.value[9]}${this.form.controls.cpf.value[10]}`
    //   this.userRequestEdit = {
    //     cpf: cpfMask,
    //     name: this.form.controls.name.value,
    //     phone: `55${this.form.controls.phone.value}`,
    //   }
    // } else {
    //   this.userRequestEdit = {
    //
    //     cpf: this.form.controls.cpf.value,
    //     name: this.form.controls.name.value,
    //     phone: `55${this.form.controls.phone.value}`,
    //   }
    // }

    this.userService.editPersonalData(this.userRequestEdit).subscribe(
      success => {
        console.log(this.userRequestEdit)
        this.editSuccess('personalData')
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao alterar perfil!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        console.error(error)
      }
    )



    /* edit personal data */
    let dateFormatBr = this.form.controls.birthdate.value.split("/")
    let date: Date = new Date(+dateFormatBr[2], dateFormatBr[1] - 1, +dateFormatBr[0]);


    this.userPersonalDataRequest = {
      birthDate: date,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      bankInfo: {
        name: this.form.controls.nameBank.value,
        accountNumber: this.form.controls.current.value,
        agencyNumber: this.form.controls.agency.value,
      },
      pix: this.form.controls.pix.value,
    }
  }

  async editSuccess(value) {

    this.closePersonalDataForm('Editado');

    if (value === 'user') {
      this.userService.getUser().subscribe(
        success => {
          let user = JSON.stringify(success);
          localStorage.setItem('userDto', user)
        },
        error => {
          console.error(error)
        }
      )
      const toast = await this.toastController.create({
        message: `Perfil alterado com sucesso!`,
        duration: 1500,
        position: 'top',
        color: 'success',
      });
      toast.present();
    }

    if (value === 'personalData') {
      this.userService.getUser().subscribe(
        success => {
          let user = JSON.stringify(success);
          localStorage.setItem('userDto', user)
        },
        error => {
          console.error(error)
        }
      )
      const toast = await this.toastController.create({
        message: `Perfil alterado com sucesso!`,
        duration: 1500,
        position: 'top',
        color: 'success',
      });
      toast.present();
    }
  }


}
