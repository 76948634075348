import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
})
export class ProposalComponent implements OnInit {

  proposalList: any[] = [];
  proposalById: any;

  constructor(
    private router: Router,
    private proposalService: ProposalService,
  ) { }

  ngOnInit() {
    this.listproposal();
  }

  listproposal() {
    let user: any = localStorage.getItem('userDto');
    user = JSON.parse(user)

    // this.proposalService.listProposalAdvertiser(user._id).subscribe(
    //   success => {
    //     for (const item of success) {
    //       this.proposalList.push(item)
    //     }
    //      console.log(success, 'amigo estou aq')
    //   },
    //   error => { console.error(error) }
    // )

    this.proposalService.listProposalUser().subscribe(
      success => {
        this.proposalList = success;
      },
      error => {
        console.error(error);
      }
    )
  }

  goPropertyProposal(item) {
    localStorage.setItem('proposalId',  JSON.stringify(item));
    this.router.navigate(['logged/property-proposal']);
  }
}
