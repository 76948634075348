/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister3RequestDto } from '../../../dtos/announcement/announcement-register-3-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-three',
  templateUrl: './advertise-property-stage-three.component.html',
  styleUrls: ['./advertise-property-stage-three.component.scss'],
})
export class AdvertisePropertyStageThreeComponent implements OnInit {
  form: FormGroup;
  public maskRemodeling: Array<any> = [/\d/, /\d/, /\d/, /\d/];
  ndormitories = 0;
  nsuites = 0;
  nbathroom = 0;
  renovated: any = [];
  request: AnnouncementRegister3RequestDto;

  response: AnnouncementGetResponseDto;

  showAppearanceOfTheProperty: boolean = true;

  retired = {
    retired: '',
    _id: ''
  }


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      appearanceOfTheProperty: ['', [Validators.required]],
      dateOfLastRenovation: [''],
      whatWasRenovated: [''],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {

    this.renovated = [];

    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success;

    if (this.response.propertyStatus === 'new' || this.response.propertyCharacteristics === 'ground') {
      this.router.navigate(['logged/advertise-property-stage-four'])
    } else {

      let dateOfLastRenovation: Date = new Date(success.dateOfLastRenovation);
      let day = dateOfLastRenovation.getDate();
      let month = dateOfLastRenovation.getMonth() + 1;
      let year = dateOfLastRenovation.getFullYear();

      let dateFormated;

      if (month < 10) {
        dateFormated = `${day}/0${month}/${year}`;
      } else {
        dateFormated = `${day}/${month}/${year}`;
      }

      if(this.response.appearanceOfTheProperty !== null) {
        this.form.patchValue({
          appearanceOfTheProperty: success.appearanceOfTheProperty,
          dateOfLastRenovation: dateOfLastRenovation.getFullYear(),
        })
      }


      for (let i = 0; i < success.whatWasRenovated.length; i++) {
        this.retired = {
          retired: success.whatWasRenovated[i].retired,
          _id: success.whatWasRenovated[i]._id
        }
        this.renovated.push(this.retired)
      }
    }
  }

  addTags() {
    if (this.form.controls['whatWasRenovated'].value !== '') {
      let retired = {
        retired: this.form.controls['whatWasRenovated'].value
      }
      this.renovated.push(retired);
      this.form.patchValue({
        whatWasRenovated: ''
      })
    }
  }

  deleteTag(item) {
    let filter = this.renovated.indexOf(item);
    this.renovated.splice(filter, 1);

    if(item._id) {
      this.announcementService.deletedWhatWasRenovated(item._id).subscribe(
        success => {
        },
        error => console.error(error)
      )
    }
  }

  confirm() {

    // let ano = `${this.form.controls['dateOfLastRenovation'].value[6]}${this.form.controls['dateOfLastRenovation'].value[7]}${this.form.controls['dateOfLastRenovation'].value[8]}${this.form.controls['dateOfLastRenovation'].value[9]}`;

    // let mes = parseInt(`${this.form.controls['dateOfLastRenovation'].value[3]}${this.form.controls['dateOfLastRenovation'].value[4]}`) - 1;

    // let dia = `${this.form.controls['dateOfLastRenovation'].value[0]}${this.form.controls['dateOfLastRenovation'].value[1]}`;

    let renovatedFilter: any = [];

    for (let i = 0; i < this.renovated.length; i++) {
      if(!this.renovated[i]._id) {
        renovatedFilter.push(this.renovated[i])
      }
    }

    const dateOfLastRenovation = new Date()
    dateOfLastRenovation.setFullYear(parseInt(this.form.controls['dateOfLastRenovation'].value))

    this.request = {
      appearanceOfTheProperty: this.form.controls['appearanceOfTheProperty'].value,
      dateOfLastRenovation: dateOfLastRenovation,
      whatWasRenovated: this.renovated
    }
    

    this.announcementService.updateStep3(localStorage.getItem('announcementId'), this.request).subscribe(
      success => this.registerSuccess(success),
      error => this.runError(error)
    )
  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Informações sobre aparência do imóvel cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-four']);
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar informações sobre aparência do imóvel!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }


}


