import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PropertyIndicationService } from 'src/app/services/property-indication.service';
import { BalanceResponseDto } from '../../dtos/balance-response.dto';
import { FinancialMovementExtractRequestDto } from '../../dtos/financial-movement-extract-request.dto';
import { ExtractResponseDto } from '../../dtos/property-indication/property-indication-extract-response.dto';
import { FinancialMovementService } from '../../services/financial-movement.service';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss'],
})
export class StatementComponent implements OnInit, AfterViewInit {

  @ViewChildren('btnMonth') btnMonths: QueryList<any>;
  @ViewChild('contentMonths') contentMonths: ElementRef;

  myBalance: any;
  loadedBalance: boolean = false;
  notextract = false;
  months = [
    {
      name: 'Janeiro',
      number: '1',
    },
    {
      name: 'Fevereiro',
      number: '2',
    },
    {
      name: 'Março',
      number: '3',
    },
    {
      name: 'Abril',
      number: '4',
    },
    {
      name: 'Maio',
      number: '5',
    },
    {
      name: 'Junho',
      number: '6',
    },
    {
      name: 'Julho',
      number: '7',
    },
    {
      name: 'Agosto',
      number: '8',
    },
    {
      name: 'Setembro',
      number: '9',
    },
    {
      name: 'Outubro',
      number: '10',
    },
    {
      name: 'Novembro',
      number: '11',
    },
    {
      name: 'Dezembro',
      number: '12',
    }
  ];

  statements: ExtractResponseDto[] = [];



  constructor(
    private propertyIndicationService: PropertyIndicationService,
    private financialMovementService: FinancialMovementService
  ) { }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.financialMovementService.getBalance().subscribe({
      next: data => {
        this.myBalance = data.balance;
        this.loadedBalance = true;
      },
      error: error => {
        console.error(error);
        this.loadedBalance = false;
      }
    })
  }

  ngAfterViewInit(): void {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let currentDate = `${day}/${month}/${year}`;


    this.btnMonths.map(results => {
      if (results.nativeElement?.id === month.toString()) {
        results.nativeElement.className = 'btn btn-months-statement-active';

        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        let request: FinancialMovementExtractRequestDto = {
          start: primeiroDia.toISOString(),
          end: ultimoDia.toISOString()
        }

        this.financialMovementService.getExtract(request).subscribe({
          next: data => {
            this.statements = data;
          },
          error: error => {
            console.error(error)
          }
        })

      }
    })
  }



  checar(el) {
    this.btnMonths.map(results => {
      if (results.nativeElement?.id === el.toString()) {
        results.nativeElement.className = 'btn btn-months-statement-active';
        const now = new Date();
        let checkDate = new Date(now.getFullYear(), el - 1, now.getDay());

        var primeiroDia = new Date(checkDate.getFullYear(), checkDate.getMonth(), 1);
        var ultimoDia = new Date(checkDate.getFullYear(), checkDate.getMonth() + 1, 0);

        let request: FinancialMovementExtractRequestDto = {
          start: primeiroDia.toISOString(),
          end: ultimoDia.toISOString()
        }

        this.financialMovementService.getExtract(request).subscribe({
          next: data => {
            this.statements = data;
          },
          error: error => {
            console.error(error)
          }
        })


      } else {
        results.nativeElement.className = 'btn btn-months-statement';
      }
    })
  }

}
