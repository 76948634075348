import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-description',
  templateUrl: './edit-description.component.html',
  styleUrls: ['./edit-description.component.scss'],
})
export class EditDescriptionComponent implements OnInit {
  formdescription: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.formdescription = this.formBuilder.group({
      description: ['', [Validators.required]],
    });
  }

  ngOnInit() {}


  gobackEditProperties(){
    this.router.navigate(['logged/edit-properties']);
  }
}
