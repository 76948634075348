import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-property-proposal-broker',
  templateUrl: './property-proposal-broker.component.html',
  styleUrls: ['./property-proposal-broker.component.scss'],
})
export class PropertyProposalBrokerComponent implements OnInit {
  isModalsproposals = false;
  proposalById: any;

  constructor(
    private router: Router,
    private proposalService: ProposalService,
  ) { }

  ngOnInit() {
    this.getProposal();

  }

  getProposal() {

    let proposal: any = localStorage.getItem('proposalId');
    proposal = JSON.parse(proposal)
    this.proposalService.getById(proposal._id).subscribe(
      success => {
        this.proposalById = success;
        console.log(this.proposalById)
      },
      error => { console.error(error) }
    )

  }
  backHome() {
    this.router.navigate(['logged/contracts-page-broker']);
  }
  setOpen(isOpen: boolean) {
    this.isModalsproposals = isOpen;
  }

}
