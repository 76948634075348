import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BalanceResponseDto } from "../dtos/balance-response.dto";
import { FinancialMovementExtractRequestDto } from "../dtos/financial-movement-extract-request.dto";
import { GetMyBalanceResponseDto } from "../dtos/get-balance-response.dto";
import { ExtractResponseDto } from "../dtos/property-indication/property-indication-extract-response.dto";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class FinancialMovementService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/financial-movement`

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    getExtract(dto: FinancialMovementExtractRequestDto): Observable<ExtractResponseDto[]> {
        return this.httpClient
        .get(`${this.url}/extract-month/?start=${dto.start}&end=${dto.end}`, this.authorizedHeader())
        .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getBalance(): Observable<any> {
        return this.httpClient
        .get(`${this.url}/balance`, this.authorizedHeader())
        .pipe(map(this.extractData), catchError(this.serviceError));
    }

}
