import { Component, OnInit } from '@angular/core';
import { UserGetResponseDto } from '../../../../../dtos/user-get-response.dto';

@Component({
  selector: 'app-work-data',
  templateUrl: './work-data.component.html',
  styleUrls: ['./work-data.component.scss'],
})
export class WorkDataComponent implements OnInit {

  user: UserGetResponseDto;

  constructor() { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
  }

}
