import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister4RequestDto } from '../../../dtos/announcement/announcement-register-4-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-four',
  templateUrl: './advertise-property-stage-four.component.html',
  styleUrls: ['./advertise-property-stage-four.component.scss'],
})
export class AdvertisePropertyStageFourComponent implements OnInit {
  form: FormGroup;
  public maskArea: Array<any> = [/\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/];
  public maskConstruction: Array<any> = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  ngarage = 0;
  ncovered = 0;

  request: AnnouncementRegister4RequestDto;

  response: AnnouncementGetResponseDto;

  showRent: boolean;
  valueSale:string;

  showInfo: boolean = false;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      condominiumValue: ['', [Validators.required]],
      valueOfIptu: ['', [Validators.required]],
      additionalIptu: [''],
      additionalIptuValue: [''],
      howIsTheIptuPaid: [''],
      // leaseValue: [0],
      // saleValue: [0],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )

    this.valueSale = localStorage.getItem('typeSale')

  }

  seeInfo() {
    this.showInfo = true;
  }

  organizeData(success) {
    this.response = success;
   if(this.response.typeOfAd !== null && this.response.saleValue !== null || this.response.leaseValue !== null) {
     this.form.patchValue({
       condominiumValue: this.response.condominiumValue,
       valueOfIptu: this.response.valueOfIptu,
       additionalIptu: this.response.additionalIptu,
       howIsTheIptuPaid: this.response.howIsTheIptuPaid,
      //  leaseValue: this.response.leaseValue,
      //  saleValue: this.response.saleValue,
     })
   }
    // if (this.response.typeOfAd === 'rent') {
    //   this.showRent = true;
    // } else {
    //   this.showRent = false;
    // }
  }


  confirm() {
    this.request = Object.assign({}, this.form.value);

    if (this.showRent) {
      if (this.request.condominiumValue !== this.response.condominiumValue || this.request.valueOfIptu !== this.response.valueOfIptu || this.request.additionalIptu !== this.response.additionalIptu || this.request.howIsTheIptuPaid !== this.response.howIsTheIptuPaid) {
        this.announcementService.updateStep4(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )

      } else {
        this.router.navigate(['logged/advertise-property-stage-value']);
      }
    } else {
      if (this.request.condominiumValue !== this.response.condominiumValue || this.request.valueOfIptu !== this.response.valueOfIptu || this.request.additionalIptu !== this.response.additionalIptu || this.request.howIsTheIptuPaid !== this.response.howIsTheIptuPaid) {
        this.announcementService.updateStep4(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-value']);
      }
    }

  }


  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Valor de condomínio e IPTU cadastrados com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.removeItem('typeSale')
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-value']);
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar valor de condomínio e IPTU do anúncio!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }
  addGarage() {
    if (this.ngarage <= 3) {
      this.ngarage++;
    }
  }
  removeGarage() {
    if (this.ngarage >= 1) {
      this.ngarage--;
    }
  }
  addCovered() {
    if (this.ncovered <= 3) {
      this.ncovered++;
    }
  }
  removeCovered() {
    if (this.ncovered >= 1) {
      this.ncovered--;
    }
  }

  back() {
    if(this.response.propertyStatus === 'new' || this.response.propertyCharacteristics === 'ground') {
      this.router.navigate(['logged/advertise-property-stage-two']);
    } else {
      this.router.navigate(['logged/advertise-property-stage-three']);
    }
  }

}
