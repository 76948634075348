import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProposalUpdateStatusDto } from 'src/app/dtos/proposal-update-status.dto';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-property-proposal',
  templateUrl: './property-proposal.component.html',
  styleUrls: ['./property-proposal.component.scss'],
})
export class PropertyProposalComponent implements OnInit {
  isModalsproposals = false;
  proposalById: any;
  announcement: any;
  response: any;
  status: ProposalUpdateStatusDto;
  constructor(
    private router: Router,
    private proposalService: ProposalService,
  ) { }

  ngOnInit() {

    this.getProposal();


  }

  getProposal() {

    let proposal: any = localStorage.getItem('proposalId')
    proposal = JSON.parse(proposal)
    console.log(proposal.proposal)

    if (proposal.proposal !== null && proposal.proposal !== undefined) {
      this.proposalService.getById(proposal.proposal._id).subscribe(
        success => {
          this.response = success;
          if (success?.counterProposal) {
            this.proposalById = success.counterProposal;
          } else {
            this.proposalById = success.proposal;
          }

          this.announcement = success.proposal.announcement;

        },
        error => { console.error(error) }
      )
    } else {
      this.proposalService.getById(proposal._id).subscribe(
        success => {
          this.response = success;
          if (success?.counterProposal) {
            this.proposalById = success.counterProposal;
          } else {
            this.proposalById = success.proposal;
          }

          this.announcement = success.proposal.announcement;

        },
        error => { console.error(error) }
      )
    }

  }

  backHome() {
    this.router.navigate(['logged/owner-registration']);
  }

  goCounterProposal(item) {
    localStorage.setItem('proposalId', JSON.stringify(item))
    this.router.navigate(['logged/counter-proposal']);
  }
  setOpen(isOpen: boolean) {
    this.isModalsproposals = isOpen;
  }
  setClose(_id: string) {

    this.status = {
      status: "approved",
    }
    this.proposalService.updateProposal(_id, this.status).subscribe(
      async success => {
        setTimeout(() => {
          this.isModalsproposals = false;
          this.router.navigate(['logged/owner-registration']);
        }, 300);
      }
    )
    error => { console.error(error) }
    this.isModalsproposals = false;

  }
}
