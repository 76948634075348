/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/semi */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { UserRegisterRequestDto } from '../../dtos/user-register-request.dto';
import { ProfileService } from '../../services/profile.service';
import { UserService } from '../../services/user.service';
import { TermComponent } from '../../shared/term/term.component';
import { PoliticComponent } from '../../shared/politic/politic.component';
import { ProfileClientEnum } from '../../dtos/enum/profile-client.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  form: FormGroup;

  public maskTel: Array<any> = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public maskCep: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  public maskCpf: Array<any> = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  btnSubmitting: boolean = false;

  request: UserRegisterRequestDto;

  nameInvalid: boolean = false;
  cpfInvalid: boolean = false;
  cepInvalid: boolean = false;
  emailInvalid: boolean = false;
  telefoneInvalido: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private router: Router,
    private userService: UserService,
    private profileService: ProfileService,
    private modalController: ModalController
  ) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      addressZipCode: ['', [Validators.required]],
      terms: [false, [Validators.requiredTrue]],
    });
  }

  ngOnInit() {
    this.form.controls['name'].valueChanges.subscribe({
      next: data => {
        this.validatorName(data)
      }
    })

    this.form.controls['cpf'].valueChanges.subscribe({
      next: data => {
        this.validatorCPF(data)
      }
    })

    this.form.controls['addressZipCode'].valueChanges.subscribe({
      next: data => {
        this.validatorCEP(data)
      }
    })

    this.form.controls['email'].valueChanges.subscribe({
      next: data => {
        this.validatorEmail(data)
      }
    })

    this.form.controls['phone'].valueChanges.subscribe({
      next: data => {
        this.validatorTelefone(data)
      }
    })


  }

  validatorName(name: string) {
    if (name !== null && name.length >= 2) {
      this.nameInvalid = false;
    } else {
      this.nameInvalid = true;
    }
  }

  validatorCPF(cpf: string) {
    const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  
    if (cpf !== null && cpfPattern.test(cpf)) {
      this.cpfInvalid = false;
    } else {
      this.cpfInvalid = true;
    }
  }
  
  validatorEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    if (email !== null && emailPattern.test(email)) {
      this.emailInvalid = false;
    } else {
      this.emailInvalid = true;
    }
  }
  

  validatorCEP(cep: string) {
    if (cep !== null && cep.length >= 9) {
      this.cepInvalid = false;
    } else {
      this.cepInvalid = true;
    }
  }

  validatorTelefone(telefone: string) {
    const telefonePattern = /^\d{2} \d{5}-\d{4}$/;
  
    if (telefone !== null && telefonePattern.test(telefone)) {
      this.telefoneInvalido = false;
    } else {
      this.telefoneInvalido = true;
    }
  }

  async confirm() {
    this.request = {
      cpf: this.form.controls.cpf.value,
      email: this.form.controls.email.value,
      name: this.form.controls.name.value,
      phone: `55${this.form.controls.phone.value.replace(/\D/g, '')}`,
      profilesIds: [
        ProfileClientEnum.indicacao,
        ProfileClientEnum.proprietario,
      ],
      addressZipCode: this.form.controls.addressZipCode.value.replace(
        /\D/g,
        ''
      ),
    };

    this.userService.register(this.request).subscribe(
      async (success) => {
        this.btnSubmitting = true;
        const toast = await this.toastController.create({
          message: `Usuário cadastrado com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.router.navigate(['auth/insert-tel']);
      },
      async (error) => {
        const toast = await this.toastController.create({
          message: `${error.error.errors}`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        this.btnSubmitting = false;
        console.error(error);
      }
    );
  }

  async openModalTerm() {
    const modal = await this.modalController.create({
      component: TermComponent,
    });
    return await modal.present();
  }
  async openModalPolitic() {
    const modal = await this.modalController.create({
      component: PoliticComponent,
    });
    return await modal.present();
  }
}
