import { ProfileClientEnum } from './../../dtos/enum/profile-client.enum';
import { Component, OnInit } from '@angular/core';
import { UserGetResponseDto } from "../../dtos/user-get-response.dto";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { FormBuilder, FormGroup } from '@angular/forms';
import { SurveyorService } from 'src/app/services/surveyor.service';
import { AcceptSurveyorDto } from 'src/app/dtos/accept-surveyor.dto';

@Component({
  selector: 'app-surveyor',
  templateUrl: './surveyor.component.html',
  styleUrls: ['./surveyor.component.scss'],
})
export class SurveyorComponent implements OnInit {
  user: UserGetResponseDto;

  selected: any;


  selectMenu = 0;
  select = 0;
  workData = false;
  isModalaskquestions = false;

  isModalsproposals: boolean = false;

  formCancel: FormGroup;


  surveyorCancelId: number;
  request: AcceptSurveyorDto
  surveyorList:any [] = [];
  surveyorList2:any [] = [];
  surveyorList3:any [] = [];




  constructor(
    private router: Router,
    private toastController: ToastController,
    private formBuilder: FormBuilder,
    private SurveyorService: SurveyorService
  ) {
    this.formCancel = this.formBuilder.group({
      becauseCancel: ['']
    })
  }

  ngOnInit() {
    this.listSurveyor();
    this.listSurveyorScheduled();
    this.listSurveyorfinished();
    this.user = JSON.parse(localStorage.getItem('userDto'));
    localStorage.removeItem('proposalSelected');
  }
  ionViewWillEnter() {
    // this.listSurveyor();
    // this.listSurveyorScheduled();
    // this.listSurveyorfinished();
    localStorage.removeItem('rooms')
  }

  changeData(value) {
    if (value === 'Novospage') {
      this.selectMenu = 0;

    } else if (value === 'Agendadaspage') {
      this.selectMenu = 1;

    } else if (value === 'finalizdaspage') {
      this.selectMenu = 2;
    }
  }

  listSurveyor() {
    this.SurveyorService.listSurveyor().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'pending'){
            this.surveyorList.push(item)
          }
        }
      },
      error => {if(error.status == 401 && error.statusText == 'Unauthorized' ){
        this.router.navigate(['/']);
      } }
    )
  }

  listSurveyorScheduled() {
    this.SurveyorService.listSurveyor().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'scheduled'){
            this.surveyorList2.push(item)
          }
        }
      },
      error => {if(error.status == 401 && error.statusText == 'Unauthorized' ){
        this.router.navigate(['/']);
      } }
    )
  }

  listSurveyorfinished() {
    this.SurveyorService.listSurveyor().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'finished'){
            this.surveyorList3.push(item)
          }
        }
      },
      error => {console.error(error); if(error.status == 401 && error.statusText == 'Unauthorized' ){
        this.router.navigate(['/']);
      } }
    )
  }



  async acceptVisit(i: number) {
    this.request ={
      status : 'scheduled',
      inspectorId: ProfileClientEnum.vistoriador
    };
    this.SurveyorService.updateStatus(this.surveyorList[i]._id, this.request).subscribe(
      async success =>{
        this.listSurveyorScheduled();
        this.surveyorList.splice(i,1)
        this.surveyorList2.splice(i,1)
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )
  }

  async OpenModalCancel(i: number) {
      this.surveyorCancelId = i
      this.isModalsproposals = true
    }

    async confirmCancel(i: number) {
      this.request ={
        status : 'pending',
        inspectorId: ProfileClientEnum.vistoriador
      };

      this.SurveyorService.updateStatus(this.surveyorList2[this.surveyorCancelId]._id, this.request).subscribe(
        async success =>{
          this.listSurveyor();
          this.surveyorList2.splice(i,1)
          this.surveyorList.splice(i,1)
          const toast = await this.toastController.create({
            message: `Visita agendada!`,
            duration: 1500,
            position: 'top',
            color: 'success',
          });
          toast.present();
          this.isModalsproposals = false
        },
        async error => {
          const toast = await this.toastController.create({
            message: `Erro ao agendar visita`,
            duration: 1500,
            position: 'top',
            color: 'danger',
          });
          toast.present();
          this.isModalsproposals = false
        }

      )
    }




  teste() {
    this.router.navigate(['logged/finished-surveyor']);
  }

  setOpen(isOpen: boolean) {
    this.isModalaskquestions = isOpen;
  }

  goToScheduled(item: any) {
    this.SurveyorService.annoucement = item.announcement
    this.SurveyorService.inspectionId = item._id;
    this.SurveyorService.surveyorid = item._id;

    localStorage.setItem('proposalSelected', JSON.stringify(item))
    this.router.navigate(['logged/photographer-add-rooms']);
  }



/*   async acceptVisit(item) {
    let filter = this.opcoesvistorias.indexOf(item);
    this.opcoesvistorias.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Visita agendada!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
  } */

/*   async refuseVisit() {
    let filter = this.opcoesvistorias.indexOf(this.selected);
    this.opcoesvistorias.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Sua visita foi removida da lista!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();

  } */

/*   confirmCancel(item) {

    this.photographerCancelId = i
    this.isModalsproposals = true;
  } */

/*   async removeVisit() {
    let filter = this.opcoesAgendada.indexOf(this.selected);
    this.opcoesAgendada.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Sua visita foi removida da lista!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    this.closeModal()
  } */

  closeModal() {
    this.isModalsproposals = false;
  }

}
