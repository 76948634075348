import { environment } from "src/environments/environment";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { BrokerAppointmentTimesResponseDto } from "../dtos/broker-appointment-times-response.dto";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { BrokerAppointmentTimesRequestDto } from "../dtos/broker-appointment-times-request.dto";


@Injectable()
export class BrokerAppointmentTimesService extends BaseService {

  url: string = `${environment.apis.imoveistock}app/broker-appointment-times`;

  reload: boolean = false;

  constructor(
      private httpClient: HttpClient
  ) {
      super();
  }

  list(): Observable<BrokerAppointmentTimesResponseDto[]> {
    return this.httpClient
    .get(`${this.url}`, this.authorizedHeader())
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  register(dto:BrokerAppointmentTimesRequestDto): Observable<number> {
    return this.httpClient
    .post(`${this.url}/register`, dto, this.authorizedHeader())
    .pipe(map(this.extractData), catchError(this.serviceError));
  }
}