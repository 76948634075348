import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnnouncementVisitGetResponseDto } from '../../../../../../dtos/announcement-visit/announcement-visit-get-response.dto';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { environment } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnnouncementVisitService } from 'src/app/services/announcement-visit.service';
@Component({
  selector: 'app-property-data',
  templateUrl: './property-data.component.html',
  styleUrls: ['./property-data.component.scss'],
})
export class PropertyDataComponent implements OnInit {
  response: AnnouncementVisitGetResponseDto;
  annoucementVisitListAll: AnnouncementVisitGetResponseDto[];

  form: FormGroup;

  public maskCode: Array<any> = [/\d/, /\d/, /\d/, /\d/,];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private callNumber: CallNumber,
    private socialSharing: SocialSharing,
    private toastController: ToastController,
    private announcementVisitService: AnnouncementVisitService
  ) {
    this.form = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(4)]]
    })
  }

  ngOnInit() {
    let proposal: any = localStorage.getItem('detailVisit');
    this.response = JSON.parse(proposal);
    // this.openWhatsapp()
    console.log(this.response);
  }

  ngOnDestroy() {
    localStorage.removeItem('detailVisit');
  }

  backBroker() {
    this.router.navigate(['logged/broker-registration']);
    localStorage.removeItem('detailVisit');
  }

  call(item) {
    this.callNumber.callNumber(item, true);
  }

  async linkCompanion() {
    navigator.clipboard.writeText(
      environment.vitrine + 'register-companion/id/' + this.response._id
    );
    const toast = await this.toastController.create({
      message: `Link copiado para área de transferência!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }

  goToAnnouncement() {
    window.open(
      `${environment.baseUrl}announcement/detail/${this.response.announcement._id}`,
      '_blank'
    );
  }

  async compartilhar() {
    navigator.clipboard.writeText(
      `${environment.baseUrl}announcement/detail/${this.response.announcement._id}`
    );
    const toast = await this.toastController.create({
      message: `Link copiado para área de transferência!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    
  }

  nagivateToWaze() {
    window.open(
      `https://waze.com/ul?ll=${this.response.announcement.latitude},${this.response.announcement.longitude}&navigate=yes&zoom=10`,
      '_blank'
    );
  }

  async sendMessage(messageTo:string) {
    this.announcementVisitService.sendReminder(this.response._id, {messageTo}).subscribe({
      next: async data => {
        const toast = await this.toastController.create({
          message: `Mensagem enviada!`,
          duration: 3000,
          position: 'top',
          color: 'success',
        });
        toast.present();
      },
      error: async error => {
        console.error(error)
        const toast = await this.toastController.create({
          message: `Mensagem não enviada!`,
          duration: 3000,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    });
    
  }

  openWhatsappToProperty() {
    window.open(
      `https://api.whatsapp.com/send?phone=55${this.response.announcement.ownerPhone}?text=Oi ${this.response.announcement.ownerName}. Sou o ${this.response.user_broker.name} corretor parceiro da imoveistock e preciso entrar em contato referente ao imóvel ${this.response.announcement.publicPlaceAddress}, ok?`,
      '_blank'
    );
  }

  confirmCode() {
    this.announcementVisitService.checkCode(this.response._id, this.form.value).subscribe({
      next: async data => {
        console.log(data);
        if (data.isValid) {
          const toast = await this.toastController.create({
            message: `Informações da visita são válidas!`,
            duration: 3000,
            position: 'top',
            color: 'success',
          });
          toast.present();
        } else {
          const toast = await this.toastController.create({
            message: `Código inválido!`,
            duration: 3000,
            position: 'top',
            color: 'danger',
          });
          toast.present();
        }
      },
      error: error => {
        console.error(error)
      }
    })
  }
}
