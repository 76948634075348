import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AnnouncementGetResponseDto } from "../dtos/announcement/announcement-get-response.dto";
import { AnnouncementRegister10RequestDto } from "../dtos/announcement/announcement-register-10-request.dto";
import { AnnouncementRegister11RequestDto } from "../dtos/announcement/announcement-register-11-request.dto";
import { AnnouncementRegister2RequestDto } from "../dtos/announcement/announcement-register-2-request.dto";
import { AnnouncementRegister3RequestDto } from "../dtos/announcement/announcement-register-3-request.dto";
import { AnnouncementRegister4RequestDto } from "../dtos/announcement/announcement-register-4-request.dto";
import { AnnouncementRegister5RequestDto } from "../dtos/announcement/announcement-register-5-request.dto";
import { AnnouncementRegister6RequestDto } from "../dtos/announcement/announcement-register-6-request.dto";
import { AnnouncementRegister7RequestDto } from "../dtos/announcement/announcement-register-7-request.dto";
import { AnnouncementRegister8RequestDto } from "../dtos/announcement/announcement-register-8-request.dto";
import { AnnouncementRegister9RequestDto } from "../dtos/announcement/announcement-register-9-request.dto";
import { AnnouncementRegisterRequestDto } from "../dtos/announcement/announcement-register-request.dto";
import { AnnouncementRegisterResponseDto } from "../dtos/announcement/announcement-register-response.dto";
import { AnnouncementUpdate1RequestDto } from "../dtos/announcement/announcement-update-1-request.dto";
import { BaseService } from "./base.service";
import { AnnouncementRegisterValueRequestDto } from "../dtos/announcement/announcement-register-value-request.dto";

@Injectable({
    providedIn: 'root'
})
export class AnnouncementService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/announcement`;

    reload: boolean = false;

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    register(dto: AnnouncementRegisterRequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .post(`${this.url}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    deletedWhatWasRenovated(id: string): Observable<boolean> {
        return this.httpClient
            .delete(`${this.url}/${id}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    list(): Observable<AnnouncementGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    listByAdvertizer(): Observable<AnnouncementGetResponseDto[]> {
        return this.httpClient
            .get(`${this.url}/list-by-advertizer`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getById(id: string): Observable<AnnouncementGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/${id}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getByPropertyIndicationId(propertyIndicationId: string): Observable<AnnouncementGetResponseDto> {
      return this.httpClient
            .get(`${this.url}/propertyIndicationId/${propertyIndicationId}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getAnnouncementAndFinancialMovementByPropertyIndicationId(propertyIndicationId: string): Observable<any> {
      return this.httpClient
            .get(`${this.url}/get-announcement-and-financial-movement-by-property-indication-id/${propertyIndicationId}`, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep1(_id: string, dto: AnnouncementUpdate1RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step1/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep2(_id: string, dto: AnnouncementRegister2RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step2/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep3(_id: string, dto: AnnouncementRegister3RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step3/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep4(_id: string, dto: AnnouncementRegister4RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step4/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateValue(_id: string, dto: AnnouncementRegisterValueRequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/updateValue/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep5(_id: string, dto: AnnouncementRegister5RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step5/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep6(_id: string, dto: AnnouncementRegister6RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step6/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep7(_id: string, dto: AnnouncementRegister7RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step7/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep8(_id: string, dto: AnnouncementRegister8RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step8/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep9(_id: string, dto: AnnouncementRegister9RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step9/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep10(_id: string, dto: AnnouncementRegister10RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step10/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateStep11(_id: string, dto: AnnouncementRegister11RequestDto): Observable<AnnouncementRegisterResponseDto> {
        return this.httpClient
            .patch(`${this.url}/step11/${_id}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    delete(_id: string): Observable<boolean> {
        return this.httpClient
            .patch(`${this.url}/delete/${_id}`, null,this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    updateUser(_id: string): Observable<boolean> {
        return this.httpClient
            .patch(`${this.url}/update-user/${_id}`, null,this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }

    getAnnouncementByCode(code: number): Observable<AnnouncementGetResponseDto> {
        return this.httpClient
            .get(`${this.url}/announcement/${code}`, this.anonymousHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }
}
