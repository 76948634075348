import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementService } from '../../../../services/announcement.service';

@Component({
  selector: 'app-edit-properties',
  templateUrl: './edit-properties.component.html',
  styleUrls: ['./edit-properties.component.scss'],
})
export class EditPropertiesComponent implements OnInit {

  response: AnnouncementGetResponseDto;

  constructor(
    private router: Router,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    let edit = localStorage.getItem('editAnnouncement');
    this.response = JSON.parse(edit);
  }

  goHomeProperties(){
    this.router.navigate(['logged/owner-registration']);
  }

  editValueProperty(){
    localStorage.setItem('announcementId', this.response._id);
    this.router.navigate(['logged/advertise-property-stage-four']);
  }
  
  editIPTU(){
    localStorage.setItem('announcementId', this.response._id);
    this.router.navigate(['logged/advertise-property-stage-four']);
  }

  editFeatures(){
    localStorage.setItem('announcementId', this.response._id);
    this.router.navigate(['logged/advertise-property-stage-two']);
  }

  editDescription(){
    localStorage.setItem('announcementId', this.response._id);
    this.router.navigate(['logged/advertise-property-stage-five']);
  }
  editDocument(){
    localStorage.setItem('announcementId', this.response._id);
    this.router.navigate(['logged/advertise-property-stage-eight']);
  }

  deleteAd(){
    this.announcementService.delete(this.response._id).subscribe(
      async success => {
        this.goHomeProperties()
        const toast = await this.toastController.create({
          message: `Anúncio excluído com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
      }
    )
  }
}
