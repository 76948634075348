import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { BrokerAppointmentTimesRequestDto } from 'src/app/dtos/broker-appointment-times-request.dto';
import { BrokerAppointmentTimesService } from 'src/app/services/broker-appointment-times.service';

@Component({
  selector: 'app-schedule-broker',
  templateUrl: './schedule-broker.component.html',
  styleUrls: ['./schedule-broker.component.scss'],
})
export class ScheduleBrokerComponent implements OnInit {
  @ViewChildren('btnEditDay') btnEditDay: QueryList<any>;

  form: FormGroup;

  days = [
    {
      dayEn: 'monday',
      dayBr: 'seg',
    },
    {
      dayEn: 'tuesday',
      dayBr: 'ter',
    },
    {
      dayEn: 'wednesday',
      dayBr: 'qua',
    },
    {
      dayEn: 'thursday',
      dayBr: 'qui',
    },
    {
      dayEn: 'friday',
      dayBr: 'sex',
    },
    {
      dayEn: 'saturday',
      dayBr: 'sab',
    },
    {
      dayEn: 'sunday',
      dayBr: 'dom',
    },
  ];

  timeSlots = [
    { key: 'faixa1', value: '0809' },
    { key: 'faixa2', value: '0910' },
    { key: 'faixa3', value: '1011' },
    { key: 'faixa4', value: '1112' },
    { key: 'faixa5', value: '1213' },
    { key: 'faixa6', value: '1314' },
    { key: 'faixa7', value: '1415' },
    { key: 'faixa8', value: '1516' },
    { key: 'faixa9', value: '1617' },
    { key: 'faixa10', value: '1718' },
  ];

  daySelectedEdit: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private brokerAppointmentTimesService: BrokerAppointmentTimesService,
    private toastController: ToastController,
  ) {
    this.form = this.generateForm();
  }

  generateForm(): FormGroup {
    const formGroup: { [key: string]: any } = {};

    this.days.forEach((day) => {
      this.timeSlots.forEach((time) => {
        const controlName = `${day.dayEn}${time.value}`;
        formGroup[controlName] = [false, [Validators.requiredTrue]];
      });
    });

    return this.formBuilder.group(formGroup);
  }

  ngOnInit() {
    this.brokerAppointmentTimesService.list().subscribe({
      next: (response) => {
        if (response.length === 0) {
          for(const key in this.form.controls) {
            this.form.controls[key].setValue(true);
          }
        } else {
          response.forEach((item) => {
            const controlName = `${item.dayOfTheWeek}${item.rangeHour}`;
            this.form.controls[controlName].setValue(true);
          });
        }
        this.daySelectedEdit = 'monday';

      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getTimeSlotLabel(time: string): string {
    switch (time) {
      case '0809':
        return '08:00 - 09:00';
      case '0910':
        return '09:00 - 10:00';
      case '1011':
        return '10:00 - 11:00';
      case '1112':
        return '11:00 - 12:00';
      case '1213':
        return '12:00 - 13:00';
      case '1314':
        return '13:00 - 14:00';
      case '1415':
        return '14:00 - 15:00';
      case '1516':
        return '15:00 - 16:00';
      case '1617':
        return '16:00 - 17:00';
      case '1718':
        return '17:00 - 18:00';
      default:
        return '';
    }
  }

  confirm() {
    let request: BrokerAppointmentTimesRequestDto = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };
    this.days.forEach((day) => {
      this.timeSlots.forEach((time) => {
        const controlName = `${day.dayEn}${time.value}`;
        let array: string[] = request[day.dayEn] || [];
        if (this.form.value[controlName]) {
          array.push(time.value);
        }
        request[day.dayEn] = array;
      });
    });

    this.brokerAppointmentTimesService.register(request).subscribe({
      next: async (response) => {
        const toast = await this.toastController.create({
          message: `Agenda atualizada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
      },
      error: (error) => {
        console.log(error);
      },
    })
  }
}
