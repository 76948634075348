import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root', 
})

export class AnnouncementFormBuilderService {
  fieldsToCheck = [
    'propertyStatus',
    'usefulArea',
    'rooms',
    'bedrooms',
    'suites',
    'bathrooms',
    'propertyPosition',
    'petsAccepted',
    'yearOfConstruction',
    'parkingSpaces',
    'howManyCovers',
    'typeOfVacancy',
    'vacancyNumber',
    'vacancyLocation',
    'whoLivesInTheProperty' 
  ];

  constructor(private formBuilder: FormBuilder) {}

  buildForm() {
    return this.formBuilder.group({
      propertyStatus: [''],
      tenantLiving: '',
      whoLivesInTheProperty: [null],
      usefulArea: ['', [Validators.required]],
      rooms: [0],
      bedrooms: [0],
      suites: [0],
      bathrooms: [0],
      propertyPosition: [''],
      petsAccepted: [''],
      yearOfConstruction: [''],
      parkingSpaces: [0],
      howManyCovers: [0],
      vacancyNumber: [0],
      typeOfVacancy: [null],
      vacancyLocation: [null],
    });
  }

  hasChanges(object1: any, object2: any): boolean {
    return this.fieldsToCheck.some((field) => object1[field] !== object2[field]);
  }
}
