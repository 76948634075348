import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementRegister9RequestDto } from '../../../dtos/announcement/announcement-register-9-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';

@Component({
  selector: 'app-advertise-property-stage-nine',
  templateUrl: './advertise-property-stage-nine.component.html',
  styleUrls: ['./advertise-property-stage-nine.component.scss'],
})
export class AdvertisePropertyStageNineComponent implements OnInit {

  request: AnnouncementRegister9RequestDto;

  response: AnnouncementGetResponseDto;

  viewSale = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    
  }

  ngOnInit() { }

  ionViewWillEnter() { 
    let id = localStorage.getItem('preanuncioId') || localStorage.getItem('announcementId');

    this.announcementService.getById(id).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success;
    if(success.commissionCheck === true) {
      this.router.navigate(['logged/advertise-property-stage-ten']) 
    }
  }

  view(valueView: string) {
    if(valueView === 'rent') {
      this.viewSale = false
    } else {
      this.viewSale = true
    }
  }


  confirm() {
    this.request = {
      commissionCheck: true,
    }

    let id = localStorage.getItem('preanuncioId')

    if(!id || id === 'undefined') {
      id = localStorage.getItem('announcementId');
    }


    this.announcementService.updateStep9(id, this.request).subscribe(
      success => this.registerSuccess(success),
      error => this.runError(error)
    )
  }

  async registerSuccess(success) {
    localStorage.removeItem('preanuncioId');
    localStorage.setItem('announcementId', success._id);
    this.router.navigate(['logged/advertise-property-stage-ten']);
  }

  async runError(error) {
    console.error(error)
  }

  backAdvertiseProperty() {
    this.router.navigate(['logged/advertise-property-stage-seven']);
  }
}


