/* eslint-disable max-len */
import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicCurrencyMaskModule } from '@thiagoprz/ionic-currency-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { IonMaskLoggedDirective } from '../directives/mask-logged.directive';
import { HomeComponent } from './home/home.component';
import { IndicateComponent } from './indicate/indicate.component';
import { LoggedComponent } from './logged.component';
import { LoggedRouterModule } from './logged.router';
import { AnnouncementComponent } from './owner-registration/components/announcement/announcement.component';
import { ProposalComponent } from './owner-registration/components/proposal/proposal.component';
import { VisitsComponent } from './owner-registration/components/visits/visits.component';
import { OwnerRegistrationComponent } from './owner-registration/owner-registration.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { CalendarModule } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { StatementComponent } from './statement/statement.component';
import { ProfileComponent } from './profile/profile.component';
import { DataPersonalViewComponent } from './profile/components/view/data-personal-view/data-personal-view.component';
import { WorkDataComponent } from './profile/components/view/work-data/work-data.component';
import { WorkDataFormComponent } from './profile/components/forms/work-data-form/work-data-form.component';
import { EditPropertiesComponent } from './owner-registration/components/edit-properties/edit-properties.component';
import { EditDescriptionComponent } from './owner-registration/components/edit-description/edit-description.component';
import { CepService } from '../services/cep.service';
import { PropertyIndicationService } from '../services/property-indication.service';
import { PersonalDataFormComponent } from './profile/components/forms/personal-data-form/personal-data-form.component';
import { AdvertisePropertyStageOneComponent } from './advertise-property/advertise-property-stage-one/advertise-property-stage-one.component';
import { AbouteComponent } from './profile/components/aboute/aboute.component';
import { PolicyAndPrivacyComponent } from './profile/components/policy-and-privacy/policy-and-privacy.component';
import { TermsAndConditionsComponent } from './profile/components/terms-and-conditions/terms-and-conditions.component';
import { AdvertisePropertyStageTwoComponent } from './advertise-property/advertise-property-stage-two/advertise-property-stage-two.component';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { AdvertisePropertyStageThreeComponent } from './advertise-property/advertise-property-stage-three/advertise-property-stage-three.component';
import { AdvertisePropertyStageFourComponent } from './advertise-property/advertise-property-stage-four/advertise-property-stage-four.component';
import { AdvertisePropertyStageFiveComponent } from './advertise-property/advertise-property-stage-five/advertise-property-stage-five.component';
import { AdvertisePropertyStageSixComponent } from './advertise-property/advertise-property-stage-six/advertise-property-stage-six.component';
import { AdvertisePropertyStageSevenComponent } from './advertise-property/advertise-property-stage-seven/advertise-property-stage-seven.component';
import { SurveyorComponent } from './surveyor/surveyor.component';
import { AnnouncementService } from '../services/announcement.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AdvertisePropertyStageEightComponent } from './advertise-property/advertise-property-stage-eight/advertise-property-stage-eight.component';
import { AdvertisePropertyStageNineComponent } from './advertise-property/advertise-property-stage-nine/advertise-property-stage-nine.component';
import { AdvertisePropertyStageElevenComponent } from './advertise-property/advertise-property-stage-eleven/advertise-property-stage-eleven.component';
import { AdvertisePropertyStageTwelveComponent } from './advertise-property/advertise-property-stage-twelve/advertise-property-stage-twelve.component';
import { AdvertisePropertyStageTenComponent } from './advertise-property/advertise-property-stage-ten/advertise-property-stage-ten.component';
import { AdvertisePropertyStageFourteenComponent } from './advertise-property/advertise-property-stage-fourteen/advertise-property-stage-fourteen.component';
import { AdvertisePropertyStageThirteenComponent } from './advertise-property/advertise-property-stage-thirteen/advertise-property-stage-thirteen.component';
import { PropertyProposalComponent } from './owner-registration/components/proposal/components/property-proposal/property-proposal.component';
import { CounterProposalComponent } from './owner-registration/components/proposal/components/counter-proposal/counter-proposal.component';
import { FinishedSurveyorComponent } from './finished-surveyor/finished-surveyor.component';
import { BrokerRegistrationComponent } from './broker-registration/broker-registration.component';
import { NewspageBrokerComponent } from './broker-registration/components/newspage-broker/newspage-broker.component';
import { VisitspageBrokerComponent } from './broker-registration/components/visitspage-broker/visitspage-broker.component';
import { ContractspageBrokerComponent } from './broker-registration/components/contractspage-broker/contractspage-broker.component';
import { FinishVisitBrokerComponent } from './broker-registration/components/visitspage-broker/components/finish-visit-broker/finish-visit-broker.component';
import { S3FilesService } from '../services/s3-files.service';
import { PhotoScheduleService } from '../services/photo-schedule.service';
import { PhotographerComponent } from './photographer/photographer.component';
import { NewsPhotographerComponent } from './photographer/components/news-photographer/news-photographer.component';
import { ScheduledPhotographerComponent } from './photographer/components/scheduled-photographer/scheduled-photographer.component';
import { FinishedPhotographerComponent } from './photographer/components/finished-photographer/finished-photographer.component';
import { AddRoomComponent } from './surveyor/components/add-room/add-room.component';
import { ManageRoomComponent } from './surveyor/components/manage-room/manage-room.component';
import { AddPhotosComponent } from './photographer/components/scheduled-photographer/add-photos/add-photos.component';
import { EnvironmentsSurveyorComponent } from './surveyor/components/add-room/components/environments-surveyor/environments-surveyor.component';
import { GaugesSurveyorComponent } from './surveyor/components/add-room/components/gauges-surveyor/gauges-surveyor.component';
import { DataSurveyorComponent } from './surveyor/components/add-room/components/data-surveyor/data-surveyor.component';
import { RequestProfileComponent } from './request-profile/request-profile.component';
import { AnnouncementVisitService } from '../services/announcement-visit.service';
import { TabsComponent } from './tabs/tabs.component';
import { FinancialMovementService } from '../services/financial-movement.service';
import { AppointmentTimesService } from '../services/appointment-times.service';
import { PropertyDataComponent } from './broker-registration/components/visitspage-broker/components/property-data/property-data.component';
import { PropertyProposalBrokerComponent } from './broker-registration/components/property-proposal-broker/property-proposal-broker.component';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgressBarComponent } from './advertise-property/components/progress-bar/progress-bar.component';
import { ModelsAdvertiseStageTenComponent } from './advertise-property/components/modal-advertise-stage-ten/models-advertise-stage-ten.component';
import { AdvertisePropertyStageValueComponent } from './advertise-property/advertise-property-stage-value/advertise-property-stage-value.component';
import { RegisterVisitMadeComponent } from './broker-registration/components/visitspage-broker/components/register-visit-made/register-visit-made.component';
import { ScheduleBrokerComponent } from './broker-registration/components/schedule-broker/schedule-broker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrokerAppointmentTimesService } from '../services/broker-appointment-times.service';
import { AuthorizationHttpInterceptor } from '../interceptors/http-interceptor';

@NgModule({
    declarations: [
        LoggedComponent,
        HomeComponent,
        OwnerRegistrationComponent,
        AnnouncementComponent,
        ProposalComponent,
        VisitsComponent,
        IndicateComponent,
        IonMaskLoggedDirective,
        PropertyListComponent,
        StatementComponent,
        ProfileComponent,
        DataPersonalViewComponent,
        WorkDataComponent,
        WorkDataFormComponent,
        PersonalDataFormComponent,
        PropertyProposalComponent,
        CounterProposalComponent,
        AbouteComponent,
        PolicyAndPrivacyComponent,
        TermsAndConditionsComponent,
        EditPropertiesComponent,
        EditDescriptionComponent,
        AdvertisePropertyStageOneComponent,
        AdvertisePropertyStageTwoComponent,
        AdvertisePropertyStageThreeComponent,
        AdvertisePropertyStageFourComponent,
        AdvertisePropertyStageFiveComponent,
        AdvertisePropertyStageSixComponent,
        AdvertisePropertyStageSevenComponent,
        SurveyorComponent,
        AdvertisePropertyStageEightComponent,
        AdvertisePropertyStageNineComponent,
        AdvertisePropertyStageTenComponent,
        AdvertisePropertyStageElevenComponent,
        AdvertisePropertyStageTwelveComponent,
        AdvertisePropertyStageThirteenComponent,
        AdvertisePropertyStageFourteenComponent,
        FinishedSurveyorComponent,
        BrokerRegistrationComponent,
        NewspageBrokerComponent,
        VisitspageBrokerComponent,
        ContractspageBrokerComponent,
        FinishVisitBrokerComponent,
        PhotographerComponent,
        NewsPhotographerComponent,
        ScheduledPhotographerComponent,
        FinishedPhotographerComponent,
        AddRoomComponent,
        ManageRoomComponent,
        AddPhotosComponent,
        EnvironmentsSurveyorComponent,
        GaugesSurveyorComponent,
        DataSurveyorComponent,
        RequestProfileComponent,
        TabsComponent,
        PropertyDataComponent,
        PropertyProposalBrokerComponent,
        ProgressBarComponent,
        ModelsAdvertiseStageTenComponent,
        AdvertisePropertyStageValueComponent,
        RegisterVisitMadeComponent,
        ScheduleBrokerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SwiperModule,
        ReactiveFormsModule,
        LoggedRouterModule,
        HttpClientModule,
        NgxPaginationModule,
        CalendarModule,
        SchedulerModule.forRoot({ locale: 'pt-br', headerDateFormat: 'daysRange' }),
        CurrencyMaskModule,
        NgSelectModule,
        NgbModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true
        },
        CepService,
        PropertyIndicationService,
        SocialSharing,
        AnnouncementService,
        S3FilesService,
        PhotoScheduleService,
        FinancialMovementService,
        AnnouncementVisitService,
        AppointmentTimesService,
        CallNumber,
        BrokerAppointmentTimesService,
        DecimalPipe
    ]

})
export class LoggedModule { }
