import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {

  homeicon = true;
  homeicongreen = false;

  handicon = true;
  handicongreen = false;

  staricon = true;
  staricongreen = false;

  tiketicon = true;
  tiketicongreen = false;

  usericon = true;
  usericongreen = false;

  // Broker registration
  newsicon = true;
  newsiconpurple = false;

  visitsicon = true;
  visitsiconpurple = false;

  proposalsicon = true;
  proposalsiconpurple = false;

  scheduleicon = true;
  scheduleiconpurple = false;

  tabOn: boolean = true;
  tabOnBrokerRegistration: boolean = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((data) => {
      if (data.url.includes('/home') || data.url.includes('/profile') || data.url.includes('/indicate') || data.url.includes('/statement') || data.url.includes('/property-list')) {
        this.tabOn = true;
        this.tabOnBrokerRegistration = false;
        this.selectitem(data.url.slice(7))
      } else if (data.url.includes('/broker-registration') || data.url.includes('/schedule-broker') || data.url.includes('/visits-page-broker') || data.url.includes('/contracts-page-broker')){
        this.tabOn = false
        this.tabOnBrokerRegistration = true
        this.selectitemBrokerRegistration(data.url.slice(7))
      } else {
        this.tabOn = false;
        this.tabOnBrokerRegistration = false;
      }
    });
  }

  selectitem(pathName: any) {
    
    if (pathName === '/home') {
      this.homeicon = false;
      this.homeicongreen = true;

      this.handicon = true;
      this.handicongreen = false;

      this.staricon = true;
      this.staricongreen = false;

      this.tiketicon = true;
      this.tiketicongreen = false;

      this.usericon = true;
      this.usericongreen = false;
      this.router.navigate(['/logged/home'])
    }

    if (pathName === '/indicate') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.handicon = false;
      this.handicongreen = true;

      this.staricon = true;
      this.staricongreen = false;

      this.tiketicon = true;
      this.tiketicongreen = false;

      this.usericon = true;
      this.usericongreen = false;
      this.router.navigate(['/logged/indicate'])
    }

    if (pathName === '/property-list') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.handicon = true;
      this.handicongreen = false;

      this.staricon = false;
      this.staricongreen = true;

      this.tiketicon = true;
      this.tiketicongreen = false;

      this.usericon = true;
      this.usericongreen = false;
      this.router.navigate(['/logged/property-list'])
    }

    if (pathName === '/statement') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.handicon = true;
      this.handicongreen = false;

      this.staricon = true;
      this.staricongreen = false;

      this.tiketicon = false;
      this.tiketicongreen = true;

      this.usericon = true;
      this.usericongreen = false;
      this.router.navigate(['/logged/statement'])
    }

    if (pathName === '/profile') {
      this.homeicon = true;
      this.homeicongreen = false;

      this.handicon = true;
      this.handicongreen = false;

      this.staricon = true;
      this.staricongreen = false;

      this.tiketicon = true;
      this.tiketicongreen = false;

      this.usericon = false;
      this.usericongreen = true;
      this.router.navigate(['/logged/profile'])
    }
  }

  selectitemBrokerRegistration(pathName: any) {
    
    if (pathName === '/broker-registration') {
      this.newsicon = false;
      this.newsiconpurple = true;

      this.visitsicon = true;
      this.visitsiconpurple = false;

      this.proposalsicon = true;
      this.proposalsiconpurple = false;

      this.scheduleicon = true;
      this.scheduleiconpurple = false;

      this.router.navigate(['/logged/broker-registration'])
    }

    if (pathName === '/visits-page-broker') {
      this.newsicon = true;
      this.newsiconpurple = false;

      this.visitsicon = false;
      this.visitsiconpurple = true;

      this.proposalsicon = true;
      this.proposalsiconpurple = false;

      this.scheduleicon = true;
      this.scheduleiconpurple = false;

      this.router.navigate(['/logged/visits-page-broker'])
    }

    if (pathName === '/contracts-page-broker') {
      this.newsicon = true;
      this.newsiconpurple = false;

      this.visitsicon = true;
      this.visitsiconpurple = false;

      this.proposalsicon = false;
      this.proposalsiconpurple = true;

      this.scheduleicon = true;
      this.scheduleiconpurple = false;

      this.router.navigate(['/logged/contracts-page-broker'])
    }

    if (pathName === '/schedule-broker') {
      this.newsicon = true;
      this.newsiconpurple = false;

      this.visitsicon = true;
      this.visitsiconpurple = false;

      this.proposalsicon = true;
      this.proposalsiconpurple = false;
      
      this.scheduleicon = false;
      this.scheduleiconpurple = true;

      this.router.navigate(['/logged/schedule-broker'])
    }
  }

}
