import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserGetResponseDto } from 'src/app/dtos/user-get-response.dto';

@Component({
  selector: 'app-request-profile',
  templateUrl: './request-profile.component.html',
  styleUrls: ['./request-profile.component.scss'],
})
export class RequestProfileComponent implements OnInit {

  user: UserGetResponseDto;

  form: FormGroup;

  rgDoc: any = [];
  uploadrg = true;
  spinnerrg = true;
  checkmarkrg = false;

  selectedDocumentRg: any;

  rgDocumentSend: any;

  constructor(
    private formBuilder: FormBuilder
  ) { 
      this.form = this.formBuilder.group({
        rg: ['']
      })
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
  }

  onSelectRg(event) {
    this.rgDoc.push(event.target.files[0]);
    this.selectedDocumentRg = <File>event.target.files[0];
    this.rgDocumentSend = new FormData;
    this.rgDocumentSend.append('file', this.selectedDocumentRg, this.selectedDocumentRg.name)
    setTimeout(() => {
      this.spinnerrg = false;
      this.checkmarkrg = true;
      this.uploadrg = false;
    }, 1000);
  }

}
