import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister11RequestDto } from '../../../dtos/announcement/announcement-register-11-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-eleven',
  templateUrl: './advertise-property-stage-eleven.component.html',
  styleUrls: ['./advertise-property-stage-eleven.component.scss'],
})
export class AdvertisePropertyStageElevenComponent implements OnInit {

  form: FormGroup;

  request: AnnouncementRegister11RequestDto;

  response: AnnouncementGetResponseDto;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController,
  ) {
    this.form = this.formBuilder.group({
      keysForPhotographer: ['', [Validators.required]],
      appointment: ['', [Validators.required]],
    });
  }

  ngOnInit() { }


  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => {
        this.response = success
        this.form.patchValue({
          keysForPhotographer: success.keysForPhotographer,
          appointment: success.appointment
        })
      },
      error => console.error(error)
    )
  }


  async confirm() {
    this.request = Object.assign({}, this.form.value);

    if (this.response.typeOfKeys === 'key') {
      if (this.request.keysForPhotographer !== '' && this.request.keysForPhotographer !== null &&
        this.request.appointment !== '' && this.request.keysForPhotographer !== null &&
        this.form.controls['appointment'].value == 'com_hora_marcada') {

        localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
        this.router.navigate(['logged/advertise-property-stage-twelve']);

      } else if (this.request.keysForPhotographer !== '' && this.request.keysForPhotographer !== null &&
        this.request.appointment !== '' && this.request.keysForPhotographer !== null &&
        this.form.controls['appointment'].value == 'sem_hora_marcada') {
        const date = new Date();
        const datePlus4 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4);
        const dayOfTheWeek = datePlus4.getDay()
        if (dayOfTheWeek == 0 || dayOfTheWeek == 1 || dayOfTheWeek == 2 || dayOfTheWeek == 3 || dayOfTheWeek == 4) {
          datePlus4.setDate(datePlus4.getDate() + 1)
        }


        let data = {
          scheduleHour: '12:00',
          scheduleDate: datePlus4,
          keysForPhotographer: this.request.keysForPhotographer
        }

        this.announcementService.updateStep11(this.response._id, data).subscribe(
          async success => {
            localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
            this.router.navigate(['logged/advertise-property-stage-fourteen']);
            return
          },
          async error => {
            console.error(error);
            localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
            this.router.navigate(['logged/advertise-property-stage-fourteen']);
            return
          }
        )
        return
      } else {
        const toast = await this.toastController.create({
          message: `Informe se poderá deixar as chaves e agende sua sessão de fotos`,
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }
    }

    if (this.response.typeOfKeys === 'password' || this.response.typeOfKeys === 'biometry') {
      this.request.keysForPhotographer = 'nao_posso_deixar_as_chaves'
      if (this.request.appointment === 'com_hora_marcada') {
        localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
        this.router.navigate(['logged/advertise-property-stage-twelve']);
      } else if (this.request.appointment === 'sem_hora_marcada') {
        const date = new Date();
        const datePlus4 = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4);
        const dayOfTheWeek = datePlus4.getDay()
        if (dayOfTheWeek == 0 || dayOfTheWeek == 1 || dayOfTheWeek == 2 || dayOfTheWeek == 3 || dayOfTheWeek == 4) {
          datePlus4.setDate(datePlus4.getDate() + 1)
        }


        let data = {
          scheduleHour: '12:00',
          scheduleDate: datePlus4,
          keysForPhotographer: this.request.keysForPhotographer
        }

        this.announcementService.updateStep11(this.response._id, data).subscribe(
          async success => {
            localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
            this.router.navigate(['logged/advertise-property-stage-fourteen']);
            return
          },
          async error => {
            console.error(error);
            localStorage.setItem('keysForPhotographer', JSON.stringify(this.request));
            this.router.navigate(['logged/advertise-property-stage-fourteen']);
            return
          }
        )
        return
      } else {
        const toast = await this.toastController.create({
          message: `Escolha a forma de agendamento das fotos`,
          duration: 2000,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    }

  }

  async registerSuccess(success) {
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-twelve']);
  }

  async runError(error) {
    console.error(error)
  }

  backAdvertiseProperty() {
    this.router.navigate(['logged/advertise-property-stage-ten']);
  }
}
