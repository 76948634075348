import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyIndicationService } from 'src/app/services/property-indication.service';
import { ListPropertyIndicationResponseDto } from '../../dtos/list-property-indication-response.dto';
import { PaginateQuerryRequestDto } from '../../dtos/paginate-querry-request.dto';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';
import { FinancialMovementService } from '../../services/financial-movement.service';
import { IonPopover } from '@ionic/angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('myPopover', { static: false }) myPopover: IonPopover;

  user: UserGetResponseDto;

  myBalance: string;

  requestDate: Date = new Date();

  propertyListAll: ListPropertyIndicationResponseDto[];

  paginateQuerryRequest: PaginateQuerryRequestDto;

  loadedList: boolean = false;
  loadedBalance: boolean = false;

  constructor(
    private router: Router,
    private propertyIndicationService: PropertyIndicationService,
    private financialMovementService: FinancialMovementService,
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {

    setTimeout(() => {
      this.user = JSON.parse(localStorage.getItem('userDto'));
    }, 100);

    this.financialMovementService.getBalance().subscribe({
      next: (data) => {
        this.myBalance = data.balance;
        this.loadedBalance = true;
      },
      error: (error) => {
        console.error(error);
        this.loadedBalance = false;
      },
    });

    this.paginateQuerryRequest = {
      skip: 0,
      take: 0,
    };

    this.propertyIndicationService.list(this.paginateQuerryRequest).subscribe(
      (success) => {
        this.propertyListAll = success;
        this.loadedList = true;
      },
      (error) => {
        console.error(error);
        this.loadedList = true;
      }
    );
  }
}
