/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementVisitGetResponseDto } from '../../../../../../dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementVisitService } from '../../../../../../services/announcement-visit.service';

@Component({
  selector: 'app-finish-visit-broker',
  templateUrl: './finish-visit-broker.component.html',
  styleUrls: ['./finish-visit-broker.component.scss'],
})
export class FinishVisitBrokerComponent implements OnInit {
  form: FormGroup;

  response: AnnouncementVisitGetResponseDto;

  btnSubmitting: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementVisitService: AnnouncementVisitService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      broker: ['', [Validators.required]],
      client1: ['', [Validators.required]],
      client2: ['', [Validators.required]],
      propertyPaidOff: ['', [Validators.required]],
      emphasisPaidOff: ['', [Validators.required]],
      emphasisPaidOf: ['', [Validators.required]],
      exclusivepropertyPaidOff: ['', [Validators.required]],
      propertyAdvertisedForSale: ['', [Validators.required]],
      propertyRentedForShortTerm: ['', [Validators.required]],
      conservationBathroom: ['', [Validators.required]],
      conservationKitchen: ['', [Validators.required]],
      conservationFloor: ['', [Validators.required]],
      conservationPainting: ['', [Validators.required]],
      conservationHydraulic: ['', [Validators.required]],
      cleaningPaidOff: ['', [Validators.required]],
      impeccableCleanliness: ['', [Validators.required]],
      pricepertyPaidOff: ['', [Validators.required]],
      announcementpertyPaidOff: ['', [Validators.required]],
      comments: ['', [Validators.required]],
    });
  }
  ngOnInit() {

  }

  ionViewWillEnter() {
    let visit: any = localStorage.getItem('finishVisit');
    this.response = JSON.parse(visit);
  }

  backPage() {
    this.router.navigate(['logged/broker-registration']);
    localStorage.removeItem('finishVisit');
    this.form.reset();
  }

  confirm() {

    let request = {
      status: 'finished'
    }

    this.announcementVisitService.updateStatus(this.response._id, request).subscribe({
      next: async data => {
        this.announcementVisitService.finishVisit.next(true)
        this.backPage();
        const toast = await this.toastController.create({
          message: `Visita finalizada com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        this.btnSubmitting = false;
        toast.present();
      },
      error: async error => {
        const toast = await this.toastController.create({
          message: `Erro ao finalizar visita!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        this.btnSubmitting = true;
        toast.present();
      }
    })
  }

  goPropertyData(item) {
    localStorage.setItem('dataProposal', JSON.stringify(item));
    this.router.navigate(['logged/property-data']);
  }

  changeOtherFeatures(event, value) {
    if (value === 'emphasisPaidOff') {
      this.form.patchValue({
        emphasisPaidOf: false,
      })
    }
    if(value === 'emphasisPaidOf') {
      this.form.patchValue({
        emphasisPaidOff: false,
      })
    }
  }

}
