import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister7RequestDto } from '../../../dtos/announcement/announcement-register-7-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-seven',
  templateUrl: './advertise-property-stage-seven.component.html',
  styleUrls: ['./advertise-property-stage-seven.component.scss'],
})
export class AdvertisePropertyStageSevenComponent implements OnInit {
  form: FormGroup;

  request: AnnouncementRegister7RequestDto = {
    paymentMethods: [
      {
        type: '',
        value: 0,
      }
    ]
  };

  checkProperty: boolean = false;
  checkVehicle: boolean = false;
  directInstallment: boolean = false;
  financing: boolean = false;
  nda: boolean = false;

  response: AnnouncementGetResponseDto;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      checkProperty: [false],
      checkVehicle: [false],
      valueProperty: [''],
      valueVehicle: [''],
      directInstallment: [false],
      financing: [false],
      financingValue: [''],
      directInstallmentValue: [''],
      nda: [false]
    });
  }

  ngOnInit() {

  }


  ndaOption($event) {
    if (this.form.controls['nda'].value === true) {
      this.form.patchValue({
        checkProperty: false,
        checkVehicle: false,
        directInstallment: false,
        financing: false,
      });

      this.checkProperty = false;
      this.checkVehicle = false;
      this.directInstallment = false;
      this.financing = false;
    }
  }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success
    if (success.typeOfAd === 'rent') {
      this.router.navigate(['logged/advertise-property-stage-nine'])
    } else {
      if (success.paymentMethods.length !== 0) {
        for (let i = 0; i < success.paymentMethods.length; i++) {
          if (success.paymentMethods[i].type === 'property') {
            this.form.patchValue({
              checkProperty: success.paymentMethods[i].type,
              valueProperty: success.paymentMethods[i].value
            })
          }

          if (success.paymentMethods[i].type === 'vehicle') {
            this.form.patchValue({
              checkVehicle: success.paymentMethods[i].type,
              valueVehicle: success.paymentMethods[i].value
            })
          }

          if (success.paymentMethods[i].type === 'financing') {
            this.form.patchValue({
              financing: success.paymentMethods[i].type,
              financingValue: success.paymentMethods[i].value
            })
          }

          if (success.paymentMethods[i].type === 'installment') {
            this.form.patchValue({
              directInstallment: success.paymentMethods[i].type,
              directInstallmentValue: success.paymentMethods[i].value
            })
          }
        }
      }
    }
  }


  async confirm() {
    if (this.form.controls['checkProperty'].value === false && this.form.controls['checkVehicle'].value === false && this.form.controls['financing'].value === false && this.form.controls['directInstallment'].value === false) {
      this.router.navigate(['logged/advertise-property-stage-nine']);
    } else {

      let content: any = []

      if (this.form.controls['checkProperty'].value === true) {
        let property = {
          type: 'property',
          value: this.form.controls['valueProperty'].value
        }
        content.push(property);
      }
      if (this.form.controls['checkVehicle'].value === true) {
        let vehicle = {
          type: 'vehicle',
          value: this.form.controls['valueVehicle'].value
        }
        content.push(vehicle);
      }
      if (this.form.controls['financing'].value === true) {

        let financing = {
          type: 'financing',
          value: 0
        }

        content.push(financing);
      }
      if (this.form.controls['directInstallment'].value === true) {

        let installment = {
          type: 'installment',
          value: this.form.controls['directInstallmentValue'].value
        }
        content.push(installment);
      }
      this.request.paymentMethods = content;

      this.announcementService.updateStep7(localStorage.getItem('announcementId'), this.request).subscribe(
        success => this.registerSuccess(success),
        error => this.runError(error)
      )
    }
  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Formas de pagamento do anúncio cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-nine']);
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar formas de pagamento do anúncio!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }

  // backAdvertisePropertyStageOne() {
  //   this.router.navigate(['logged/advertise-property-stage-six']);
  // }

}
