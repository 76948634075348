import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import ptBr from '@angular/common/locales/pt';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { LoggedModule } from './logged/logged.module';
import { WelcomeModule } from './welcome/welcome.module';
import { AuthenticationGuard } from './guards/authentication.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationHttpInterceptor } from './interceptors/http-interceptor';

registerLocaleData(ptBr);
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, LoggedModule, WelcomeModule, AuthModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHttpInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthenticationGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
