import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service'
@Component({
  selector: 'app-splash1',
  templateUrl: './splash1.component.html',
  styleUrls: ['./splash1.component.scss'],
})
export class Splash1Component implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {

    if (localStorage.getItem('userDto') !== null && localStorage.getItem('user') === null) {
      setTimeout(() => {
        this.router.navigate(['auth/insert-tel'])
      }, 5000);
    } else if (localStorage.getItem('userDto') !== null && localStorage.getItem('user') !== null) {

      const isValid = this.authenticationService.isJwtValid();
      if (isValid === true) {
        setTimeout(() => {
          this.router.navigate(['logged/home'])
        }, 5000);
      } else {
        setTimeout(() => {
          this.router.navigate(['auth/insert-tel'])
        }, 5000);
      }
    } else {
      setTimeout(() => {
        this.router.navigate(['welcome'])
      }, 5000);
    }

  }

}
