import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {PhotographerService} from "../../../../../services/photographer.service";
import {ToastController} from "@ionic/angular";
import { AcceptPhotographerDto } from 'src/app/dtos/accept-photographer.dto';
import { ProfileClientEnum } from 'src/app/dtos/enum/profile-client.enum';

@Component({
  selector: 'app-add-photos',
  templateUrl: './add-photos.component.html',
  styleUrls: ['./add-photos.component.scss'],
})
export class AddPhotosComponent implements OnInit {

  files: any = [];
  requestPhoto: any = [];
  urls: any = [];

  selectedFiles?: FileList;
  previews: string[] = [];

  request: AcceptPhotographerDto

  btnSubmitting: boolean = false;


  idteste: string

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
   private photographerService: PhotographerService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {

  }


  ionViewWillEnter(){

    localStorage.removeItem('rooms')

  }
  backHomePhotographer() {
    this.router.navigate(['logged/photographer'])
  }

  onFileSelected(event) {

    // this.selectedFiles = event.target.files;
    //
    // this.previews = [];
    // const test: File = this.selectedFiles[0];
    // if (this.selectedFiles && this.selectedFiles[0]) {
    //   const numberOfFiles = this.selectedFiles.length;
    //   for (let i = 0; i < numberOfFiles; i++) {
    //     const reader = new FileReader();
    //     reader.onload = (e: any) => {
    //       this.previews.push(e.target.result);
    //     };
    //     reader.readAsDataURL(this.selectedFiles[i]);
    //   }
    // }


      if (event.target.files.length > 0) {
        if (event.target.files.length > 0) {
          for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            this.files.push({ file, url });
            var reader = new FileReader();

            reader.onload = (event: any) => {
              this.urls = [];
              this.urls.push(event.target.result);

              this.requestPhoto.push({
                photo: event.target.result
              });
            }
            reader.readAsDataURL(event.target.files[i]);
          }

        }
      }
  }

  requestPermission(_id = this.photographerService.announcement_Id){
    this.btnSubmitting = true;

    const formData = new FormData();

    for (let i = 0; i < this.files.length; i++) {

      formData.append('photo',this.requestPhoto[i]);

    }

    this.photographerService.announcementPhoto(_id ,this.requestPhoto).subscribe(
      async success => {
        this.acceptVisit();
        this.btnSubmitting = false
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao cadastrar foto `,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        this.btnSubmitting = false
        toast.present();
      }
    );


  }
  async acceptVisit(_id= this.photographerService.photo_id) {
    this.request ={
      status : 'finishedPhotos',
      photographerId: ProfileClientEnum.fotografo
    };

    this.photographerService.updateStatus(_id, this.request).subscribe(
      async success =>{
        const toast = await this.toastController.create({
          message: `Foto envida com sucesso`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();

        this.router.navigate(['/logged/photographer'])
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao enviar foto`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )

  }

  deleteImage(file) {
    let filter = this.files.indexOf(file);
    this.files.splice(filter, 1);
  }





}
