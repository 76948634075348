import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementVisitGetResponseDto } from 'src/app/dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementVisitService } from 'src/app/services/announcement-visit.service';

@Component({
  selector: 'app-visitspage-broker',
  templateUrl: './visitspage-broker.component.html',
  styleUrls: ['./visitspage-broker.component.scss'],
})
export class VisitspageBrokerComponent implements OnInit {

  schedulesAccepteds: any = [];

  schedulesSelected: any;

  isModalsproposals = false;

  formCancel: FormGroup

  visit_id: string

  annoucementVisitListAll: AnnouncementVisitGetResponseDto[];

  statusPendentecorretor = true;
  constructor(
    private router: Router,
    private toastController: ToastController,
    private announcementVisitService: AnnouncementVisitService,
    private formBuilder: FormBuilder
  ) {
    this.formCancel = this.formBuilder.group({
      becauseCancel: ['']
    })
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.announcementVisitService.finishVisit.subscribe({
      next: value => {
        if (value === true) {
          this.listVisits()
        }
      },
    })

    this.listVisits()
  }

  listVisits() {
    this.announcementVisitService.list().subscribe(
      success => {
        this.annoucementVisitListAll = success;
      },
      error => {
        console.error(error)
      }
    )
  }

/*   goFinishVisit(item) {
    this.router.navigate(['logged/finish-visit-broker']);
    localStorage.setItem('finishVisit', JSON.stringify(item));
  } */

  goFinishVisit(item) {
    this.router.navigate(['logged/finish-visit-broker']);
    localStorage.setItem('finishVisit', JSON.stringify(item));
  }


  goDetailVisit(item) {
    this.router.navigate(['logged/property-data']);
    localStorage.setItem('detailVisit', JSON.stringify(item));
  }

  goRegisterVisitMade() {
    this.router.navigate(['logged/register-visit-made']);
  }

  openCancelarVisita(_id: string) {
    this.visit_id = _id
    this.isModalsproposals = true
  }

  aceitarVisita(_id: string) {
    //this.visit_id = _id
    this.statusPendentecorretor = false

    this.announcementVisitService.acceptVisit(_id).subscribe(
      async success => {
       success

      },
    )
  }

  async confirmCancel() {
    let cancelationReason = {
      cancelationReason: this.formCancel.controls.becauseCancel.value,
    }
    this.announcementVisitService.cancelVisit(this.visit_id, cancelationReason).subscribe(
      async success => {
        let filter = this.schedulesAccepteds.indexOf(this.schedulesSelected);
        this.schedulesAccepteds.splice(filter, 1);
        this.announcementVisitService.list().subscribe(
          success => {
            this.annoucementVisitListAll = success
          },
          error => {
            console.error(error)
          }
        )
        const toast = await this.toastController.create({
          message: `Sua visita foi removida da lista!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();

      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao cancelar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }
    )
    this.isModalsproposals = false
  }


  async acceptVisit(item) {

    let request = {
      status: 'scheduled'
    }

    this.announcementVisitService.updateStatus(item._id, request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        this.announcementVisitService.list().subscribe(
          success => {
            this.annoucementVisitListAll = success
          },
          error => {
            console.error(error)
          }
        )
        toast.present();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )

  }

  closeModal() {
    this.isModalsproposals = false;
  }

}
