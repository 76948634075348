import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";


@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
})
export class TermComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private modalCtrl: ModalController
  ) {
  }

  exit(){

  }
  ngOnInit(): void {

  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
