import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementVisitGetResponseDto } from 'src/app/dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementVisitService } from 'src/app/services/announcement-visit.service';

@Component({
  selector: 'app-newspage-broker',
  templateUrl: './newspage-broker.component.html',
  styleUrls: ['./newspage-broker.component.scss'],
})
export class NewspageBrokerComponent implements OnInit {

  schedulesNews: any = [];

  schedulesSelected: any;

  isModalsproposals = false;

  annoucementVisitListAll: AnnouncementVisitGetResponseDto[];

  today: Date =  new Date();
  
  constructor(
    private router: Router,
    private toastController: ToastController,
    private announcementVisitService: AnnouncementVisitService,
  ) { }

  ngOnInit() {

    this.announcementVisitService.listScheduled().subscribe(
      success => {
        this.annoucementVisitListAll = success;
      },
      error => {
        console.error(error)
      }
    )
  }

  async acceptVisit(item) {
    let filter = this.schedulesNews.indexOf(item);
    //const result = this.announcementVisitService.updateVisitBroker(item);

    this.announcementVisitService.updateVisitBroker(item).subscribe(
      async success =>{
        this.schedulesNews.splice(filter, 1);
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        this.announcementVisitService.listScheduled().subscribe(
          success => {
            this.annoucementVisitListAll = success
          },
          error => {
            console.error(error)
          }
        )
        toast.present();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )
  
  }

  async refuseVisit() {
    let filter = this.schedulesNews.indexOf(this.schedulesSelected);
    this.schedulesNews.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Sua visita foi removida da lista!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();


    this.isModalsproposals = false
  }

  closeAnnouncment() {
    this.isModalsproposals = false;
  }

  openRefuseVisit(item) {
    this.schedulesSelected = item;
    this.isModalsproposals = true
  }

}
