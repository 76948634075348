/* eslint-disable new-parens */
/* eslint-disable prefer-const */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DatamokService } from 'src/app/services/datamok.service';
import { AnnouncementService } from '../../services/announcement.service';

@Component({
  selector: 'app-owner-registration',
  templateUrl: './owner-registration.component.html',
  styleUrls: ['./owner-registration.component.scss'],
})
export class OwnerRegistrationComponent implements OnInit {
  changeSubscription: Subscription;
  changeSubscriptionHeader: Subscription;
  changeSubscriptionShowHeader: Subscription;


  isModalscheduling = false;
  isModalsproposals = false;
  headeredittime = false;
  headertophome = true;
  announcementpage = true;
  proposalpage = false;
  visitspage = false;
  home = true;
  owner: any;
  user = {
    name: '',
  };

  constructor(
    private datamockservice: DatamokService,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.changeSubscription = this.datamockservice.getcloseAnnouncment().subscribe(() => {
      this.isModalsproposals = true;
    });
    this.changeSubscriptionHeader = this.datamockservice.getchangeHeader().subscribe(() => {
      this.headeredittime = true;
      this.headertophome = false;
    });
    this.changeSubscriptionShowHeader = this.datamockservice.getshowHeaderTop().subscribe(() => {
      this.headeredittime = false;
      this.headertophome = true;
    });
  }

  ngOnInit() {
    this.announcementpage = true;
  }
  
  ionViewWillEnter() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
    if(this.announcementService.reload === true) {
      this.segmentchangepage('visistspage');
      setTimeout(() => {
        this.segmentchangepage('announcementpage');
        this.announcementService.reload = false
      }, 100);
    }
   if(localStorage.getItem('announcementId') !== null) {
    localStorage.removeItem('announcementId')
   }
   if(localStorage.getItem('continueAnnouncement') !== null) {
    localStorage.removeItem('continueAnnouncement')
   }
  }

  segmentchangepage(value) {
    if (value === 'announcementpage') {
      this.announcementpage = true;
      this.visitspage = false;
      this.proposalpage = false;
    } else if (value === 'visistspage') {
      this.announcementpage = false;
      this.visitspage = true;
      this.proposalpage = false;
    } else if (value === 'proposalpage') {
      this.announcementpage = false;
      this.visitspage = false;
      this.proposalpage = true;
    }
  }
  setOpen(isOpen: boolean) {
    this.isModalscheduling = isOpen;
  }
  closeEditTime() {
    this.datamockservice.closeEditTimeTop();
    this.headeredittime = false;
    this.headertophome = true;
  }

}
