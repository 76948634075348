import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegisterRequestDto } from '../../../dtos/announcement/announcement-register-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { CepService } from '../../../services/cep.service';
import { Cep } from '../../../utils/cep';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-advertise-property-stage-one',
  templateUrl: './advertise-property-stage-one.component.html',
  styleUrls: ['./advertise-property-stage-one.component.scss'],
})
export class AdvertisePropertyStageOneComponent implements OnInit {
  form: FormGroup;
  selectionResidential = false;
  selectionCommercial = false;
  selectionRural = false;
  public maskCep: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  request: AnnouncementRegisterRequestDto;

  response: AnnouncementGetResponseDto;

  completeAddress: string;
  mapImgLink: string;

  btnSubmit: boolean = true;

  notFoundText = 'Nenhum item encontrado';

  propertyTypesResidencial = [
    { value: 'apartamento', name: 'Apartamento' },
    { value: 'casadecondominio', name: 'Casa de condomínio' },
    { value: 'casadevila', name: 'Casa de vila' },
    { value: 'studio', name: 'Studio' },
    { value: 'kitnet', name: 'Kitnet' },
    { value: 'cobertura', name: 'Cobertura' },
    { value: 'flat', name: 'Flat' },
    { value: 'loft', name: 'Loft' },
    { value: 'ground', name: 'Terreno' },
    { value: 'comercial', name: 'Comercial' },
    { value: 'farm', name: 'Chácara' },
    { value: 'casa', name: 'Casa' },
    { value: 'sobrado', name: 'Sobrado' },
    { value: 'sobradoEmCondominio', name: 'Sobrado em Condomínio' },
  ]

  propertyTypesComercial = [
    { value: 'casacomercial', name: 'Casa comercial' },
    // { value: 'comercial', name: 'Comercial' },
    { value: 'garagem', name: 'Garagem' },
    { value: 'pontocomercial', name: 'Ponto comercial' },
    { value: 'conjuntocomercial', name: 'Conjunto comercial' },
    { value: 'loja', name: 'Loja' },
    { value: 'salao', name: 'Salão' },
    { value: 'galpao', name: 'Galpão' },
    { value: 'deposito', name: 'Depósito' },
    { value: 'armazem', name: 'Armazém' },
    { value: 'hotel', name: 'Hotel' },
    { value: 'motel', name: 'Motel' },
    { value: 'pousada', name: 'Pousada' },
    { value: 'lajecorporativa', name: 'Laje corporativa' },
    { value: 'prediointeiro', name: 'Prédio inteiro' },
    { value: 'casa', name: 'Casa' },
    { value: 'sobrado', name: 'Sobrado' },
    { value: 'sobradoEmCondominio', name: 'Sobrado em Condomínio' },
  ];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController,
    private cepService: CepService,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = this.notFoundText;

    this.form = this.formBuilder.group({
      typeOfAdSale: ['', [Validators.required]],
      typeOfAdRent: ['', [Validators.required]],
      goal: ['', [Validators.required]],
      propertyType: [''],
      cepAddress: ['', [Validators.required]],
      districtAddress: [''],
      cityAddress: [''],
      publicPlaceAddress: [''],
      ufAddress: [''],
      numberAddress: ['', [Validators.required]],
      complementAddress: ['']
    });
  }

  ngOnInit() {
    this.form.controls['cepAddress'].valueChanges.subscribe(value => {
      if (value.length === 9) {
        this.buscarCep();
      }
    });
  }

  ionViewWillEnter() {
    if (localStorage.getItem('continueAnnouncement') !== null) {
      this.announcementService.getById(localStorage.getItem('continueAnnouncement')).subscribe(
        success => this.organizeData(success),
        error => console.error(error)
      )
    }
  }

  buscarCep() {

    this.completeAddress = null;
    this.mapImgLink = null;

    if (this.form.controls.cepAddress.value.length === 9) {
      const cep = this.form.controls.cepAddress.value;

      if (cep) {
        this.cepService.buscarCep(cep).then((cep: Cep) => {

          if (cep.logradouro) {
            this.completeAddress = `${cep.logradouro},${cep.bairro},${cep.cidade},${cep.uf}`;
            this.updateMap();
          }

          this.form.patchValue({
            districtAddress: cep.bairro,
            cityAddress: cep.cidade,
            publicPlaceAddress: cep.logradouro,
            ufAddress: cep.uf,
          });
        });
      }
    }
  }

  organizeData(success) {
    this.form.patchValue({
      goal: success.goal,
      propertyType: success.propertyType,
      cepAddress: success.cepAddress,
      cityAddress: success.cityAddress,
      ufAddress: success.ufAddress,
      publicPlaceAddress: success.publicPlaceAddress,
      districtAddress: success.districtAddress,
      numberAddress: success.numberAddress,
      complementAddress: success.complementAddress
    })

    if (success.typeOfAd === 'sale') {
      this.form.patchValue({
        typeOfAdSale: true,
        typeOfAdRent: false,
      })
    } else {
      this.form.patchValue({
        typeOfAdSale: false,
        typeOfAdRent: true,
      })
    }
    setTimeout(() => {
      this.buscarCep();
    }, 1000);

    this.response = success;
  }


  async confirm() {

    // this.request = Object.assign({}, this.form.value);

    let typeOfAd: string = '';

    if (this.form.controls['typeOfAdSale'].value && !this.form.controls['typeOfAdRent'].value) {
      typeOfAd = 'sale'
    }
    if (!this.form.controls['typeOfAdSale'].value && this.form.controls['typeOfAdRent'].value) {
      typeOfAd = 'rent'
    }
    if (this.form.controls['typeOfAdSale'].value && this.form.controls['typeOfAdRent'].value) {
      typeOfAd = 'both'
    }

    this.request = {
      typeOfAd: typeOfAd,
      ...this.form.value,
    }

    this.btnSubmit = false;
    let propertyIndicationId = localStorage.getItem('propertyIndication');

    if (propertyIndicationId && propertyIndicationId !== 'undefined') {
      Object.assign(this.request, { propertyIndicationId });
    }

    if (localStorage.getItem('continueAnnouncement') !== null) {

      if (this.request.typeOfAd !== this.response.typeOfAd || this.request.goal !== this.response.goal || this.request.propertyType !== this.response.propertyType || this.request.cepAddress.replace(/\D/g, '') !== this.response.cepAddress || this.request.numberAddress !== this.response.numberAddress || this.request.complementAddress !== this.response.complementAddress || this.request.cityAddress !== this.response.cityAddress || this.request.ufAddress !== this.response.ufAddress || this.request.publicPlaceAddress !== this.response.publicPlaceAddress || this.request.districtAddress !== this.response.districtAddress) {
        this.announcementService.updateStep1(localStorage.getItem('continueAnnouncement'), this.request).subscribe(
          success => this.editSuccess(success),
          error => this.registerFailed(error)
        )
      } else {
        localStorage.setItem('announcementId', this.response._id);
        this.router.navigate(['logged/advertise-property-stage-two']);
      }
    } else {
      this.announcementService.register(this.request).subscribe(
        success => this.registerSuccess(success),
        error => this.registerFailed(error)
      )
    }

  }

  async editSuccess(success) {
    const toast = await this.toastController.create({
      message: `Operação, finalidade e localização do anuncio cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-two']);
  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Operação, finalidade e localização do anúncio cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    localStorage.setItem('typeSale', this.request.typeOfAd);
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-two']);
  }

  async registerFailed(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar anúncio!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    this.btnSubmit = true;
  }

  numberAddressFocusOut(event: any) {

    const number = event.target.value;

    if (number && this.completeAddress) {
      this.completeAddress = `${number},${this.completeAddress}`;
    } else {
      this.completeAddress = `${number},
      ${this.form.controls.publicPlaceAddress.value},
      ${this.form.controls.districtAddress.value},
      ${this.form.controls.cityAddress.value},
      ${this.form.controls.ufAddress.value}`
    }

    this.updateMap();
  }

  updateMap() {
    this.mapImgLink = `https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=400x400&markers=color:red|${this.completeAddress}&key=${environment.google.apiKey}`;
  }
}
