import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-advertise-property-stage-twelve',
  templateUrl: './advertise-property-stage-twelve.component.html',
  styleUrls: ['./advertise-property-stage-twelve.component.scss'],
})
export class AdvertisePropertyStageTwelveComponent implements OnInit {

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() { }


  confirm() {
    this.router.navigate(['logged/advertise-property-stage-thirteen']);
  }
  backAdvertiseProperty() {
    this.router.navigate(['logged/advertise-property-stage-eleven']);
  }
}
