import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BaseService } from "./base.service";
import { Profile } from "../dtos/user-get-response.dto";

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/profile-client`

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    list(): Observable<Profile[]> {
        return this.httpClient
            .get(`${this.url}`, this.anonymousHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));

    }
}
