import {Injectable} from "@angular/core";
import {BaseService} from "./base.service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PhotoScheduleRegisterRequestDto} from "../dtos/announcement/photo-schedule-register-request.dto";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PhotographerService extends BaseService {

  url: string = `${environment.apis.imoveistock}app/`;

  userfoto: any
  announcement_Id: string
  photo_id: string

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  listPhotographer(): Observable<any> {
    return this.httpClient
      .get(`${this.url}photo-schedule/list/pending/location`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateStatus(_id: string, dto): Observable<any> {
    return this.httpClient
      .put(`${this.url}photo-schedule/update-status/${_id}`,dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  announcementPhoto(_id: string, photo: any,): Observable<any> {
    return this.httpClient
      .post(`${this.url}announcement-photo/upload/announcement/${_id}`, photo, this.authorizedHeaderPostPhoto())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
