import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {SurveyorService} from "../../../../services/surveyor.service";

@Component({
  selector: 'app-add-room',
  templateUrl: './add-room.component.html',
  styleUrls: ['./add-room.component.scss'],
})
export class AddRoomComponent implements OnInit {

  response:any;
  ambientes: boolean = true;
  dados: boolean = false;
  medidores: boolean = false;

  constructor(
    private router: Router,
    route:ActivatedRoute,
    private SurveyorService: SurveyorService
  ) {
    route.params.subscribe(val => {
      
    });
  }

  ngOnInit() {
    let teste = localStorage.getItem('proposalSelected');
    this.response = JSON.parse(teste);
  }

  backScheduledSurveyor() {
    this.router.navigate(['logged/surveyor'])
  }

  changeData(value) {
    if (value === 'Ambientes') {
      this.ambientes = true;
      this.dados = false;
      this.medidores = false;
    }

    if (value === 'Dados') {
      this.ambientes = false;
      this.dados = true;
      this.medidores = false;
    }

    if (value === 'Medidores') {
      this.ambientes = false;
      this.dados = false;
      this.medidores = true;
    }
  }

}
