import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WelcomeComponent } from './welcome.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Splash1Component } from './splash1/splash1.component';
import { WelcomeRouterModule } from './welcome.router';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { AuthorizationHttpInterceptor } from '../interceptors/http-interceptor';

@NgModule({
    declarations: [
        WelcomeComponent,
        Splash1Component,
        WelcomePageComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        WelcomeRouterModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true
        },
    ]

})
export class WelcomeModule { }
