import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { SurveyorRegisterRequestDto } from 'src/app/dtos/surveyor/surveyor-register-request.dto';
import { S3FilesService } from 'src/app/services/s3-files.service';
import { SurveyorService } from 'src/app/services/surveyor.service';

@Component({
  selector: 'app-manage-room',
  templateUrl: './manage-room.component.html',
  styleUrls: ['./manage-room.component.scss'],
})
export class ManageRoomComponent implements OnInit {

  room: any;

  form: FormGroup;

  inspection: SurveyorRegisterRequestDto;

  galeria: boolean = true;

  files: any = [];

  spaceSegment: string = 'Galeria';

  items: any = [];

  photos: any = [];

  requestPhoto: any;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private SurveyorService: SurveyorService,
    private s3FilesService: S3FilesService,
    route: ActivatedRoute,
  ) {
    route.params.subscribe(val => {
      this.listSurveyorManageRoom()
    });
    this.form = this.formBuilder.group({
      item: ['parede'],
      descriptionParede: [''],
      descriptionTeto: [''],
      descriptionPortas: [''],
      descriptionJanela: [''],
      descriptionPiso: [''],
    })
  }

  ngOnInit() {
    let room = localStorage.getItem('room')
    this.room = JSON.parse(room)
  }

  listSurveyorManageRoom() {
    if (this.SurveyorService.inspectionId) {
      this.SurveyorService.listInspectionById(this.SurveyorService.inspectionId).subscribe(
        success => {
          for (const item of success.environments) {
            if (item.name === 'parede') {
              if (item.description) {
                this.form.controls['descriptionParede'].setValue(item.description)
                this.items.push(item)
              }
            }
            if (item.name === 'teto') {
              if (item.description) {
                this.form.controls['descriptionTeto'].setValue(item.description)
                this.items.push(item)
              }
            }

            if (item.name === 'portas') {
              if (item.description) {
                this.form.controls['descriptionPortas'].setValue(item.description)
                this.items.push(item)
              }
            }

            if (item.name === 'janela') {
              if (item.description) {
                this.form.controls['descriptionJanela'].setValue(item.description)
                this.items.push(item)
              }
            }

            if (item.name === 'piso') {
              if (item.description) {
                this.form.controls['descriptionPiso'].setValue(item.description)
                this.items.push(item)
              }
            }

          }

        },
        error => {
          if (error.status == 401 && error.statusText == 'Unauthorized') {
            this.router.navigate(['/']);
          }
        }
      )
      this.SurveyorService.listPhotoByInspection(this.SurveyorService.inspectionId).subscribe(
        success => {
          for (const item of success) {
            this.s3FilesService.downloadTgtBucket(item.key).subscribe(
              success => {
                this.photos.push('data:image/jpg;base64,' + success.base64String);
              })
          }
        })
    }
  }

  backAddRoom() {
    this.router.navigate(['logged/photographer-add-rooms'])
  }

  changeStep(value) {
    this.galeria = value;
    if (value === true) {
      this.spaceSegment = 'Galeria'
    } else {

      //this.spaceSegment = 'Item'
    }
  }

  onFileSelected(event) {

    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
        this.files.push({ file, url });
      }
    }

  }

  deleteImage(file) {
    let filter = this.files.indexOf(file);
    this.files.splice(filter, 1);
  }

  async addItems() {
    if (this.items.length > 0) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].name === this.form.controls['item'].value) {
          let filter = this.items.indexOf(this.items[i]);
          this.items.splice(filter, 1);
        }
      }
    }

    let item = {
      name: this.form.controls['item'].value,
      description: ''
    }

    if (this.form.controls['item'].value === 'parede') {
      if (this.form.controls['descriptionParede'].value) {
        item.description = this.form.controls['descriptionParede'].value
        this.items.push(item)
      }
    }

    if (this.form.controls['item'].value === 'teto') {
      if (this.form.controls['descriptionTeto'].value) {
        item.description = this.form.controls['descriptionTeto'].value
        this.items.push(item)
      }
    }

    if (this.form.controls['item'].value === 'portas') {
      if (this.form.controls['descriptionPortas'].value) {
        item.description = this.form.controls['descriptionPortas'].value
        this.items.push(item)
      }
    }

    if (this.form.controls['item'].value === 'janela') {
      if (this.form.controls['descriptionJanela'].value) {
        item.description = this.form.controls['descriptionJanela'].value
        this.items.push(item)
      }
    }

    if (this.form.controls['item'].value === 'piso') {
      if (this.form.controls['descriptionPiso'].value) {
        item.description = this.form.controls['descriptionPiso'].value
        this.items.push(item)
      }
    }

  }

  deltedItem(item) {
    let filter = this.items.indexOf(item);
    this.items.splice(filter, 1);
  }

  async confirm() {
    this.addItems();
    this.inspection = {
      _id: this.SurveyorService.inspectionId,
      inspectionScheduleId: this.SurveyorService.surveyorid,
      environmentId: this.items,
      name: this.room.comodo,
    }

    this.SurveyorService.register(this.inspection).subscribe(
      async success => {
        this.SurveyorService.inspectionId = success._id
        this.requestPhoto = {
          files: this.files
        };
        const formData = new FormData();

        for (let i = 0; i < this.files.length; i++) {
          formData.append('files', this.files[i].file);
        }
        this.SurveyorService.inspectionPhoto(formData, this.SurveyorService.inspectionId).subscribe(
          async success => {
            this.spaceSegment = 'Item'
          },
          async error => {
            const toast = await this.toastController.create({
              message: `Erro ao cadastrar foto `,
              duration: 1500,
              position: 'top',
              color: 'danger',
            });
            toast.present();
          }
        );
        const toast = await this.toastController.create({
          message: `Fotos salvas com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.backAddRoom();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao enviar foto`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )
  }

}
