import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AnnouncementGetResponseDto } from '../dtos/announcement/announcement-get-response.dto';

@Injectable({
  providedIn: 'root'
})
export class DatamokService {

  announcement: AnnouncementGetResponseDto;

  private subject = new Subject<any>();
  private subjectheader = new Subject<any>();
  private subjecedittime = new Subject<any>();
  private subjecedheadertop = new Subject<any>();


  closeAnnouncment(item) {
    this.announcement = item;
    this.subject.next('');
  }

  getcloseAnnouncment(): Observable<any> {
    return this.subject.asObservable();
  }

  changeHeader() {
    this.subjectheader.next('');
  }

  getchangeHeader(): Observable<any> {
    return this.subjectheader.asObservable();
  }

  closeEditTimeTop() {
    this.subjecedittime.next('');
  }

  getcloseEditTimeTop(): Observable<any> {
    return this.subjecedittime.asObservable();
  }

  showHeaderTop() {
    this.subjecedheadertop.next('');
  }

  getshowHeaderTop(): Observable<any> {
    return this.subjecedheadertop.asObservable();
  }
}

