import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { DatamokService } from 'src/app/services/datamok.service';
import { AnnouncementGetResponseDto, RatingType } from '../../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementService } from '../../../../services/announcement.service';
import { CepService } from '../../../../services/cep.service';
import { Cep } from '../../../../utils/cep';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
  isModalaskquestions = false;

  isModalsproposals = false;

  isModalsReviews = false;

  annoucementSelected: any;

  response: AnnouncementGetResponseDto[];

  loadedList: boolean = false;

  selectAnnouncement: AnnouncementGetResponseDto;

  constructor(
    private datamockservice: DatamokService,
    private announcementService: AnnouncementService,
    private router: Router,
    private toastController: ToastController,
    private cepService: CepService,
  ) { }

  ngOnInit() {
    this.listAnnouncement()
    if (localStorage.getItem('announcementId') !== null) {
      localStorage.removeItem('announcementId')
    }
    if (localStorage.getItem('continueAnnouncement') !== null) {
      localStorage.removeItem('continueAnnouncement')
    }
  }

  ionViewWillEnter() {
    this.listAnnouncement()
  }

  listAnnouncement() {
    this.announcementService.listByAdvertizer().subscribe(
      success => {
        this.response = success;
        this.loadedList = true;
      },
      error => {
        console.error(error)
        this.loadedList = true;
      }
    )
  }

  openAnnouncment(item) {
    this.datamockservice.closeAnnouncment(item);
  }


  handleRefresh(event) {
    setTimeout(() => {
      this.listAnnouncement()
      event.target.complete();
    }, 2000);
  };

  setOpen(isOpen: boolean) {
    this.isModalaskquestions = isOpen;
  }

  continue(item) {
    localStorage.setItem('continueAnnouncement', item._id);
    localStorage.setItem('announcementId', item._id);

    /* step 1 concluido joga pro 2 */
    if (item.propertyStatus === null) {
      this.router.navigate(['logged/advertise-property-stage-two'])
    } else if (item.propertyStatus !== 'new' && item.propertyCharacteristics !== 'ground' && item.appearanceOfTheProperty === null) {
      this.router.navigate(['logged/advertise-property-stage-three'])
    } else if (item.typeOfAd === 'sale' && item.saleValue === null) {
      this.router.navigate(['logged/advertise-property-stage-four'])
    } else if (item.typeOfAd === 'rent' && item.leaseValue === null) {
      this.router.navigate(['logged/advertise-property-stage-four'])
    } else if (item.description === null) {
      this.router.navigate(['logged/advertise-property-stage-five']);
    } else if (item.typeOfKeys === null) {
      this.router.navigate(['logged/advertise-property-stage-six']);
    } else if (item.paymentMethods.length === 0) {
      this.router.navigate(['logged/advertise-property-stage-seven']);
    } else if (item.documentsOwner.length === 0) {
      this.router.navigate(['logged/advertise-property-stage-eight']);
    } else if (item.exclusivity === null) {
      this.router.navigate(['logged/advertise-property-stage-ten']);
    } else if (item.keysForPhotographer === null) {
      this.router.navigate(['logged/advertise-property-stage-eleven']);
    }

  }

  edit(item) {
    localStorage.setItem('editAnnouncement', JSON.stringify(item))
    this.router.navigate(['logged/edit-properties'])
  }

  openRemoveAnnouncement(item) {
    this.isModalsproposals = true;
    this.annoucementSelected = item
  }

  openSeeReviews(_id: string, isOpen: boolean) {
    this.isModalsReviews = isOpen;
    if(_id) {
      this.selectAnnouncement = this.response.find(a => a._id == _id);
    }
  }

  removerAnuncioModal(value) {

    if (value === true) {
      this.announcementService.delete(this.annoucementSelected._id).subscribe(
        async success => {
          this.listAnnouncement()
          const toast = await this.toastController.create({
            message: `Anúncio excluído com sucesso!`,
            duration: 1500,
            position: 'top',
            color: 'success',
          });
          toast.present();
        }
      )
      this.isModalsproposals = false;
    } else {
      this.isModalsproposals = false;
    }




    // if(value === true) {
    //   this.announcementService.delete(this.datamockservice.announcement._id).subscribe(
    //     async success => {
    //       if(success === true) {
    //         const toast = await this.toastController.create({
    //           message: `Anúncio excluído com sucesso!`,
    //           duration: 1500,
    //           position: 'top',
    //           color: 'success',
    //         });
    //         toast.present();
    //       } else {
    //         const toast = await this.toastController.create({
    //           message: `Erro ao excluir o anúncio!`,
    //           duration: 1500,
    //           position: 'top',
    //           color: 'danger',
    //         });
    //         toast.present();
    //       }
    //       window.location.reload()
    //     },
    //     error => {
    //     }
    //   )
    // }
    // this.isModalsproposals = false;
  }

  resolveRate(rate: RatingType[]) {
    if(rate.length == 0) return;
    const sum = rate.reduce((acc, item) => acc + item.ratingScore, 0);
    return sum/rate.length;
  }

  checkRating(rate: RatingType[]): boolean {
    return !!rate.find(a => a.status == 'accepted')
  }

}
