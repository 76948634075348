/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable radix */
/* eslint-disable no-var */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthetincatedUserDto } from '../../dtos/authenticated-user.dto';
import { AuthenticateCodeConfirmationRequestDto } from '../../dtos/authentication-code-confirmation-request.dto';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { AuthenticateRequestDto } from '../../dtos/authenticate-request.dto';
import { AnnouncementService } from 'src/app/services/announcement.service';

@Component({
  selector: 'app-insert-code',
  templateUrl: './insert-code.component.html',
  styleUrls: ['./insert-code.component.scss'],
})
export class InsertCodeComponent implements OnInit {
  form: FormGroup;

  phone: string = '';
  PressBackspace: any

  codigo1;
  codigo2;
  codigo3;
  codigo4;

  timeM = 1;
  timeS = 59;

  notsendcodemsg = false;
  btnSubmitting: boolean = false;

  interval: any;
  timeLeft: any;

  resendButton: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private router: Router,
    private authenticationService: AuthenticationService,
    private announcementService: AnnouncementService,
    private userService: UserService
  ) {
    this.form = this.formBuilder.group({
      code1: ['', [Validators.required]],
      code2: ['', [Validators.required]],
      code3: ['', [Validators.required]],
      code4: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.phone = localStorage.getItem('phone');
    if (this.phone === null) {
      this.router.navigate(['auth/insert-tel']);
    }
    this.startTimer();
  }

  move(e: any, p: any, c: any, n: any) {
    var length = c.value.length;
    var maxlength = c.getAttribute('maxlength');
  
    if (length == maxlength && n != "") {
      n.focus();
      return;
    }
      if (e.key === 'Backspace') {
      if (c.value === "") {
        if (p != "") {
          if (this.PressBackspace) {
            p.focus();
            p.value = '';
          }
        }
      } 
      this.PressBackspace = true;
    } else {
      this.PressBackspace = false;
    }
  }

  nextCode(item, value) {
    if (item === 'code1') {
      this.codigo1 = value.target.value;
      var nextInput = document.getElementById('code2');
      nextInput.focus();
    } else if (item === 'code2') {
      this.codigo2 = value.target.value;
      var nextInput = document.getElementById('code3');
      nextInput.focus();
    } else if (item === 'code3') {
      this.codigo3 = value.target.value;
      var nextInput = document.getElementById('code4');
      nextInput.focus();
    } else if (item === 'code4') {
      this.codigo4 = value.target.value;
      var nextInput = document.getElementById('btn-send-code');
      nextInput.focus();
    }
  }
  notSendCode(value: string) {
    if (value === 'open') {
      this.notsendcodemsg = true;
    } else if (value === 'close') {
      this.notsendcodemsg = false;
    }
  }

  async confirm() {
    this.btnSubmitting = true;
    let request: AuthenticateCodeConfirmationRequestDto = {
      code: parseInt(
        `${this.form.controls.code1.value}${this.form.controls.code2.value}${this.form.controls.code3.value}${this.form.controls.code4.value}`
      ),
      phone: this.phone,
    };

    this.authenticationService.authenticateCodeConfirmation(request).subscribe(
      (success) => this.runAutheticateCodeConfirmation(success),
      async (error) => await this.runError(error)
    );
  }

  runAutheticateCodeConfirmation(success: AuthetincatedUserDto): void {
    localStorage.removeItem('phone');

    this.authenticationService.setAuthenticatedUser(
      new AuthetincatedUserDto(
        success.userId,
        success.phone,
        success.token,
        success.profileId,
        success.apiFunctionsId
      )
    );

    this.userService.getUser().subscribe(
      (success) => this.runGetUserSuccess(success),
      async (error) => this.runError(error)
    );
  }
  runGetUserSuccess(success: UserGetResponseDto): void {
    let user = JSON.stringify(success);
    localStorage.setItem('userDto', user);

    const id = localStorage.getItem('preanuncioId');

    if (!!id && id !== 'undefined') {
      this.announcementService
        .updateUser(id)
        .subscribe({
          next: (data) => {
            localStorage.removeItem('email');
            this.router.navigate(['logged/advertise-property-stage-nine']);
            return;
          },
          error: (error) => {
            console.error(error);
            setTimeout(() => {
              this.router.navigate(['logged/home']);
            }, 1000);
          },
        });
    } else {
      setTimeout(() => {
        this.router.navigate(['logged/home']);
      }, 1000);
    }
  }

  async runError(error: any) {
    const toast = await this.toastController.create({
      message: `Código Inválido!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    this.btnSubmitting = false;
    toast.present();
    console.error(error);
    this.form.setValue({ code1: '', code2: '', code3: '', code4: '' });
  }

  startTimer() {
    this.resendButton = false;
    this.interval = setInterval(() => {
      if (this.timeM == 0 && this.timeS == 0) {
        clearInterval(this.interval);
        this.resendButton = true;
      } else {
        if (this.timeS > 0) {
          this.timeS--;
        } else if (this.timeS == 0) {
          this.timeS = 59;
          this.timeM--;
        }
      }
    }, 1000);

    // this.resendButton = false;
    // this.interval = setInterval(() => {
    //   if (this.timeLeft > 0) {
    //     this.timeLeft--;
    //     if (this.timeLeft <= 0) {
    //       clearInterval(this.interval);
    //       this.resendButton = true;
    //     }
    //   } else {
    //     this.timeLeft = 120;
    //   }
    // }, 1000);
  }

  async reenviarcod(value) {
    if (value === 'sms') {
      this.authenticationService
        .authenticate({ phone: localStorage.getItem('phone') })
        .subscribe(
          async (success) => {
            const toast = await this.toastController.create({
              message: `Sms reenviado com sucesso!`,
              duration: 1500,
              position: 'top',
              color: 'success',
            });
            toast.present();
            this.timeM = 1;
            this.timeS = 59;
            this.startTimer();
          },
          async (error) => {
            const toast = await this.toastController.create({
              message: `Não foi possível enviar SMS!`,
              duration: 1500,
              position: 'top',
              color: 'danger',
            });
            toast.present();
            console.error(error);
          }
        );
    } else if (value === 'email') {
      this.authenticationService
        .authenticateByEmail({ phone: localStorage.getItem('phone') })
        .subscribe(
          async (success) => {
            const toast = await this.toastController.create({
              message: `Email enviado com sucesso!`,
              duration: 1500,
              position: 'top',
              color: 'success',
            });
            toast.present();
            this.timeM = 1;
            this.timeS = 59;
            this.startTimer();
          },
          async (error) => {
            const toast = await this.toastController.create({
              message: `Não foi possível enviar Email!`,
              duration: 1500,
              position: 'top',
              color: 'danger',
            });
            toast.present();
            console.error(error);
          }
        );
    }
  }
}
