import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { VisitRegisterByBrokerRequestDto } from 'src/app/dtos/announcement-visit/visit-register-by-broker.request.dto';
import { AnnouncementGetResponseDto } from 'src/app/dtos/announcement/announcement-get-response.dto';
import { UserGetResponseDto } from 'src/app/dtos/user-get-response.dto';
import { AnnouncementVisitService } from 'src/app/services/announcement-visit.service';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register-visit-made',
  templateUrl: './register-visit-made.component.html',
  styleUrls: ['./register-visit-made.component.scss'],
})
export class RegisterVisitMadeComponent implements OnInit {

  public maskDate: Array<any> = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public maskHour: Array<any> = [/\d/, /\d/, ':', /\d/, /\d/];
  public maskCpf: Array<any> = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  form: FormGroup;

  userClient: UserGetResponseDto;

  announcement: AnnouncementGetResponseDto;

  validDate: boolean = true;
  validHour: boolean = true;

  saleSelect: boolean = true;

  dateRequest: string = '';
  hourRequest: string = '';

  visitTypeRequest: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastController: ToastController,
    private announcementService: AnnouncementService,
    private announcementVisitService: AnnouncementVisitService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      cpfSearch: ['', [Validators.required]],
      code: ['', [Validators.required]],
      date: ['', [Validators.required]],
      hour: ['', [Validators.required]],
      comments: ['', [Validators.required]],
      visitType: ['']
    })
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.form.reset();
  }

  async searchClient(event) {

    let request = {
      cpf: event.target.value.replace(/\D/g, '')
    }
    if (request.cpf.length === 11) {
      this.userService.getUserClientByCpf(request.cpf).subscribe({
        next: data => {
          this.userClient = data;
        },
        error: async error => {
          const toast = await this.toastController.create({
            message: `${error.error.errors}`,
            duration: 1500,
            position: 'top',
            color: 'danger',
          });
          toast.present();
          this.form.patchValue({
            cpfSearch: ''
          });
        }
      })
    }
  }

  searchAnnouncement() {
    this.announcementService.getAnnouncementByCode(this.form.controls['code'].value).subscribe({
      next: data => {
        this.announcement = data;
      },
      error: async error => {
        const toast = await this.toastController.create({
          message: `${error.error.errors}`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    })
  }

  verifyDate(event) {
    const inputValue = event.target.value;
    if (inputValue.length === 10) {
      const dateParts = inputValue.split('/');
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);

      const isValidDate = !isNaN(day) && !isNaN(month) && !isNaN(year) && this.isValidToday(day, month, year);
      if (isValidDate) {
        this.validDate = true;
        this.dateRequest = inputValue;
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = true;
    }
  }

  isValidToday(day: number, month: number, year: number): boolean {
    const today = new Date();
    const inputDate = new Date(year, month - 1, day);
    return (
      inputDate.getFullYear() === today.getFullYear() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getDate() === today.getDate()
    );
  }

  verifyHour(event: any) {
    const inputValue = event.target.value;
    const timeParts = inputValue.split(':');
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);

    const isValidTime = this.isValidHour(hour) && this.isValidMinute(minute);

    if (isValidTime) {
      this.validHour = true;
      this.hourRequest = inputValue;
    } else {
      this.validHour = false;
    }
  }

  isValidHour(hour: number): boolean {
    return hour >= 0 && hour <= 23;
  }

  isValidMinute(minute: number): boolean {
    return minute >= 0 && minute <= 59;
  }

  confirm() {

    if (this.announcement.typeOfAd === 'both') {
      if (this.saleSelect) {
        this.visitTypeRequest = 'sale';
      } else {
        this.visitTypeRequest = 'rent';
      }
    } else if (this.announcement.typeOfAd === 'sale') {
      this.visitTypeRequest = 'sale';
    } else {
      this.visitTypeRequest = 'rent';

    }

    const dateTimeParts = `${this.dateRequest} ${this.hourRequest}`.split(' ');
    const dateParts = dateTimeParts[0].split('/');
    const timeParts = dateTimeParts[1].split(':');

    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[0], 10);
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);

    const visitDate = new Date(year, month, day, hour, minute);


    let request: VisitRegisterByBrokerRequestDto = {
      announcementId: this.announcement._id,
      clientId: this.userClient._id,
      visitDate: visitDate,
      visitTypeOfAd: this.visitTypeRequest,
      reasonItWasHeld: this.form.controls['comments'].value
    }

    let brokerId: any = localStorage.getItem('userDto');

    brokerId = JSON.parse(brokerId);

    this.announcementVisitService.registerByBroker(request, brokerId._id).subscribe({
      next: async data => {
        const toast = await this.toastController.create({
          message: `Visita cadastrada com sucesso`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.router.navigate(['logged/visits-page-broker'])
      },
      error: async error => {
        const toast = await this.toastController.create({
          message: `Erro ao cadastrar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    })

  }

}
