import { Component, OnInit } from '@angular/core';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';

@Component({
  selector: 'app-photographer',
  templateUrl: './photographer.component.html',
  styleUrls: ['./photographer.component.scss'],
})
export class PhotographerComponent implements OnInit {

  user: UserGetResponseDto;

  news = true;
  scheduled = false;
  finished = false;

  today: Date;

  constructor() { }

  ngOnInit() {
    this.today = new Date()
   }

  ionViewWillEnter() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
  }

  segmentchangepage(value) {
    if (value === 'news') {
      this.news = true;
      this.scheduled = false;
      this.finished = false;
    } else if (value === 'scheduled') {
      this.news = false;
      this.scheduled = true;
      this.finished = false;
    } else if (value === 'finished') {
      this.news = false;
      this.scheduled = false;
      this.finished = true;
    }
  }

}
