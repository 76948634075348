  /* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvertisePropertyStageEightComponent } from './advertise-property/advertise-property-stage-eight/advertise-property-stage-eight.component';
import { AdvertisePropertyStageElevenComponent } from './advertise-property/advertise-property-stage-eleven/advertise-property-stage-eleven.component';
import { AdvertisePropertyStageFiveComponent } from './advertise-property/advertise-property-stage-five/advertise-property-stage-five.component';
import { AdvertisePropertyStageFourComponent } from './advertise-property/advertise-property-stage-four/advertise-property-stage-four.component';
import { AdvertisePropertyStageFourteenComponent } from './advertise-property/advertise-property-stage-fourteen/advertise-property-stage-fourteen.component';
import { AdvertisePropertyStageNineComponent } from './advertise-property/advertise-property-stage-nine/advertise-property-stage-nine.component';
import { AdvertisePropertyStageOneComponent } from './advertise-property/advertise-property-stage-one/advertise-property-stage-one.component';
import { AdvertisePropertyStageSevenComponent } from './advertise-property/advertise-property-stage-seven/advertise-property-stage-seven.component';
import { AdvertisePropertyStageSixComponent } from './advertise-property/advertise-property-stage-six/advertise-property-stage-six.component';
import { AdvertisePropertyStageTenComponent } from './advertise-property/advertise-property-stage-ten/advertise-property-stage-ten.component';
import { AdvertisePropertyStageThirteenComponent } from './advertise-property/advertise-property-stage-thirteen/advertise-property-stage-thirteen.component';
import { AdvertisePropertyStageThreeComponent } from './advertise-property/advertise-property-stage-three/advertise-property-stage-three.component';
import { AdvertisePropertyStageTwelveComponent } from './advertise-property/advertise-property-stage-twelve/advertise-property-stage-twelve.component';
import { AdvertisePropertyStageTwoComponent } from './advertise-property/advertise-property-stage-two/advertise-property-stage-two.component';
import { BrokerRegistrationComponent } from './broker-registration/broker-registration.component';
import { FinishVisitBrokerComponent } from './broker-registration/components/visitspage-broker/components/finish-visit-broker/finish-visit-broker.component';
import { HomeComponent } from './home/home.component';
import { IndicateComponent } from './indicate/indicate.component';
import { LoggedComponent } from './logged.component';
import { EditDescriptionComponent } from './owner-registration/components/edit-description/edit-description.component';
import { EditPropertiesComponent } from './owner-registration/components/edit-properties/edit-properties.component';
import { CounterProposalComponent } from './owner-registration/components/proposal/components/counter-proposal/counter-proposal.component';
import { PropertyProposalComponent } from './owner-registration/components/proposal/components/property-proposal/property-proposal.component';
import { OwnerRegistrationComponent } from './owner-registration/owner-registration.component';
import { AbouteComponent } from './profile/components/aboute/aboute.component';
import { PolicyAndPrivacyComponent } from './profile/components/policy-and-privacy/policy-and-privacy.component';
import { TermsAndConditionsComponent } from './profile/components/terms-and-conditions/terms-and-conditions.component';
import { ProfileComponent } from './profile/profile.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { StatementComponent } from './statement/statement.component';
import {SurveyorComponent} from "./surveyor/surveyor.component";
import {FinishedSurveyorComponent} from "./finished-surveyor/finished-surveyor.component";
import { PhotographerComponent } from './photographer/photographer.component';
import { AddRoomComponent } from './surveyor/components/add-room/add-room.component';
import { ManageRoomComponent } from './surveyor/components/manage-room/manage-room.component';
import { AddPhotosComponent } from './photographer/components/scheduled-photographer/add-photos/add-photos.component';
import { RequestProfileComponent } from './request-profile/request-profile.component';
import { AuthenticationGuard } from '../guards/authentication.guard';
import { PropertyProposalBrokerComponent } from './broker-registration/components/property-proposal-broker/property-proposal-broker.component';
import { PropertyDataComponent } from './broker-registration/components/visitspage-broker/components/property-data/property-data.component';
import { AdvertisePropertyStageValueComponent } from './advertise-property/advertise-property-stage-value/advertise-property-stage-value.component';
import { NewspageBrokerComponent } from './broker-registration/components/newspage-broker/newspage-broker.component';
import { VisitspageBrokerComponent } from './broker-registration/components/visitspage-broker/visitspage-broker.component';
import { ContractspageBrokerComponent } from './broker-registration/components/contractspage-broker/contractspage-broker.component';
import { RegisterVisitMadeComponent } from './broker-registration/components/visitspage-broker/components/register-visit-made/register-visit-made.component';
import { ScheduleBrokerComponent } from './broker-registration/components/schedule-broker/schedule-broker.component';


const routes: Routes = [
    {
        path: '',
        component: LoggedComponent,
        children: [
            {
                path: 'home',
                component: HomeComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'owner-registration',
                component: OwnerRegistrationComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'indicate',
                component: IndicateComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'property-list',
                component: PropertyListComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'property-proposal',
                component: PropertyProposalComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'counter-proposal',
                component: CounterProposalComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'statement',
                component: StatementComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'profile',
                component: ProfileComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'aboute',
                component: AbouteComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'policy-and-privacy',
                component: PolicyAndPrivacyComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'edit-properties',
                component: EditPropertiesComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'edit-description',
                component: EditDescriptionComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-one',
                component: AdvertisePropertyStageOneComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-two',
                component: AdvertisePropertyStageTwoComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-three',
                component: AdvertisePropertyStageThreeComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-four',
                component: AdvertisePropertyStageFourComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-five',
                component: AdvertisePropertyStageFiveComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-six',
                component: AdvertisePropertyStageSixComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-seven',
                component: AdvertisePropertyStageSevenComponent, canActivate: [AuthenticationGuard]
            },

            {
              path: 'surveyor',
              component: SurveyorComponent, canActivate: [AuthenticationGuard]
            },

            {
              path: 'finished-surveyor',
              component: FinishedSurveyorComponent, canActivate: [AuthenticationGuard]
            },

            {
                path: 'advertise-property-stage-eight',
                component: AdvertisePropertyStageEightComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-nine',
                component: AdvertisePropertyStageNineComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-ten',
                component: AdvertisePropertyStageTenComponent, canActivate: [AuthenticationGuard]
            }, 
            {
                path: 'advertise-property-stage-eleven',
                component: AdvertisePropertyStageElevenComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-twelve',
                component: AdvertisePropertyStageTwelveComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-thirteen',
                component: AdvertisePropertyStageThirteenComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-fourteen',
                component: AdvertisePropertyStageFourteenComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'broker-registration',
                component: BrokerRegistrationComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'proposal-broker',
                component: PropertyProposalBrokerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'finish-visit-broker',
                component: FinishVisitBrokerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'register-visit-made',
                component: RegisterVisitMadeComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'schedule-broker',
                component: ScheduleBrokerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'photographer',
                component: PhotographerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'photographer-add-rooms',
                component: AddRoomComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'manage-room',
                component: ManageRoomComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'photographer-add-photos',
                component: AddPhotosComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'request-profile',
                component: RequestProfileComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'property-data',
                component: PropertyDataComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'advertise-property-stage-value',
                component: AdvertisePropertyStageValueComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'news-page-broker',
                component: NewspageBrokerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'visits-page-broker',
                component: VisitspageBrokerComponent, canActivate: [AuthenticationGuard]
            },
            {
                path: 'contracts-page-broker',
                component: ContractspageBrokerComponent, canActivate: [AuthenticationGuard]
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoggedRouterModule { }
