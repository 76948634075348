import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { PhotoScheduleRegisterRequestDto } from "../dtos/announcement/photo-schedule-register-request.dto";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class PhotoScheduleService extends BaseService {

    url: string = `${environment.apis.imoveistock}photo-schedule`;

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    register(dto: PhotoScheduleRegisterRequestDto): Observable<any> {
        return this.httpClient
            .post(`${this.url}`, dto, this.authorizedHeader())
            .pipe(map(this.extractData), catchError(this.serviceError));
    }
}