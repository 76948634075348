import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PhotoScheduleRegisterRequestDto } from "../dtos/announcement/photo-schedule-register-request.dto";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CounterProposalRequestDto } from "../dtos/counter-proposal-request.dto";

@Injectable({
  providedIn: 'root'
})
export class ProposalService extends BaseService {

  url: string = `${environment.apis.imoveistock}app/proposal`;

  userfoto: any

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  listProposalAdvertiser(advertiserId: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/proposal/advertiser/${advertiserId}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listProposalUser(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/proposal/user/`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listProposalBroker(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/proposal/broker`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getById(id: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/id/${id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  getByAnnouncement(announcementId: string): Observable<any> {
    return this.httpClient
      .get(`${this.url}/annoucement/${announcementId}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  counterProposal(dto: CounterProposalRequestDto): Observable<any> {
    return this.httpClient
      .post(`${this.url}/counter-proposal`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateProposal(_id: string, dto): Observable<any> {
    return this.httpClient
      .patch(`${this.url}/update-status/${_id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }


}
