import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { ProfileClientEnum } from "../../../../../../dtos/enum/profile-client.enum";
import { SurveyorService } from "../../../../../../services/surveyor.service";
import { AcceptSurveyorDto } from "../../../../../../dtos/accept-surveyor.dto";
import { SurveyorRegisterRequestDto } from 'src/app/dtos/surveyor/surveyor-register-request.dto';


@Component({
  selector: 'app-gauges-surveyor',
  templateUrl: './gauges-surveyor.component.html',
  styleUrls: ['./gauges-surveyor.component.scss'],
})
export class GaugesSurveyorComponent implements OnInit {

  form: FormGroup;
  request: AcceptSurveyorDto;
  inspection: SurveyorRegisterRequestDto;
  surveyorList: any[] = [];
  inpectionId: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastController: ToastController,
    private SurveyorService: SurveyorService
  ) {
    this.form = this.formBuilder.group({
      gaugesWater: [''],
      waterReading: [''],
      lightMeter: [''],
      Readinglight: ['']
    })
  }

  ngOnInit() {
    let teste: any = localStorage.getItem('proposalSelected');
    teste = JSON.parse(teste)
    this.SurveyorService.listInspectionBySchedule(teste._id).subscribe(
      success => {
        if (success.length > 0) {
          this.inpectionId = success[0]._id,
            this.form.controls['gaugesWater'].setValue(success[0].watermeter);
          this.form.controls['waterReading'].setValue(success[0].waterReading);
          this.form.controls['lightMeter'].setValue(success[0].lightMeter);
          this.form.controls['Readinglight'].setValue(success[0].lightReading);
        }
      },

      error => {
        if (error.status == 401 && error.statusText == 'Unauthorized') {
          this.router.navigate(['/']);
        }
      }
    )
  }

  listSurveyorfinished() {

    this.SurveyorService.listSurveyor().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'finished') {
            this.surveyorList.push(item)
          }
        }
      },
      error => {
        if (error.status == 401 && error.statusText == 'Unauthorized') {
          this.router.navigate(['/']);
        }
      }
    )
  }
  async confirmGauges() {
    this.request = {
      status: 'finished',
      inspectorId: ProfileClientEnum.vistoriador,
    };
    this.inspection = {
      _id: this.inpectionId,
      watermeter: this.form.controls['gaugesWater'].value,
      waterReading: this.form.controls['waterReading'].value,
      lightMeter: this.form.controls['lightMeter'].value,
      lightReading: this.form.controls['Readinglight'].value,
    }

    this.SurveyorService.updateStatus(this.SurveyorService.surveyorid, this.request).subscribe(
      async success => {
        

      },
    )
    this.SurveyorService.register(this.inspection).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Salvo com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();

      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao enviar`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )

    this.router.navigate(['logged/surveyor']);
  }

}
