import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyorService } from 'src/app/services/surveyor.service';

@Component({
  selector: 'app-environments-surveyor',
  templateUrl: './environments-surveyor.component.html',
  styleUrls: ['./environments-surveyor.component.scss'],
})
export class EnvironmentsSurveyorComponent implements OnInit {

  form: FormGroup;

  rooms: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private SurveyorService: SurveyorService,
    private router: Router,
    route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
  ) {
    route.params.subscribe(val => {
      this.listSurveyorManageRoom()

    });
    this.form = this.formBuilder.group({
      addRoom: ['']
    })

  }

  ngOnInit() {
    //let proposal: any = localStorage.getItem('proposalSelected');
    //proposal = JSON.parse(proposal)
    //this.SurveyorService.listInspectionBySchedule(localStorage.getItem(proposal._id)).subscribe(
    //  {
    //    next: value => {
    //      if (value === true) {
    //        this.listSurveyorManageRoom()
    //      }
    //    }
    //  },
    //)
    //this.listSurveyorManageRoom()
  };

  ionViewWillEnter() {
    localStorage.removeItem('room')
  }

  listSurveyorManageRoom() {
    let proposal: any = localStorage.getItem('proposalSelected');
    this.rooms = [];
    proposal = JSON.parse(proposal)
    this.SurveyorService.listInspectionBySchedule(proposal._id).subscribe(
      success => {
        for (const item of success) {
          this.rooms.push({ comodo: item.name, _id: item._id })
        }
      },
      error => {
        if (error.status == 401 && error.statusText == 'Unauthorized') {
          this.router.navigate(['/']);
        }
      }
    )
  }

  addMoreRoom() {
    let room = {
      comodo: this.form.controls['addRoom'].value,
      addedItems: false
    };

    this.rooms.push(room);

    this.form.reset()

    localStorage.setItem('rooms', JSON.stringify(this.rooms))
  }

  goToAddedItems(item) {
    this.SurveyorService.inspectionId = item._id;
    localStorage.setItem('room', JSON.stringify(item));
    this.router.navigate(['logged/manage-room'])
  }

  deleteRoom(item) {
    let filter = this.rooms.indexOf(item);
    this.rooms.splice(filter, 1);
    localStorage.setItem('rooms', JSON.stringify(this.rooms))
  }
  testt() {
    this.router.navigate(['logged/manage-room'])
  }
}
