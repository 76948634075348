import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-fourteen',
  templateUrl: './advertise-property-stage-fourteen.component.html',
  styleUrls: ['./advertise-property-stage-fourteen.component.scss'],
})
export class AdvertisePropertyStageFourteenComponent implements OnInit {
  date: Date;
  horas: string = '';
  datePlus4: Date;

  getLocalDate: boolean = false;

  constructor(
    private router: Router,
    private announcementService: AnnouncementService
  ) {
  }

  ngOnInit() {
    
    this.horas = localStorage.getItem('hora');
    const localDate = localStorage.getItem('date');
    if (localDate) {
      this.date = new Date(localDate)
      this.getLocalDate = true;
    } else {
      this.date = new Date();
      this.datePlus4 = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() +4);
      const dayOfTheWeek = this.datePlus4.getDay()
      if(dayOfTheWeek == 0 || dayOfTheWeek == 1 || dayOfTheWeek == 2 || dayOfTheWeek == 3 || dayOfTheWeek == 4){
        this.datePlus4.setDate(this.datePlus4.getDate() +1)
      }
      this.getLocalDate = false;
    }
  }

  confirm() {
    this.announcementService.reload = true;
    this.router.navigate(['logged/owner-registration']);
    localStorage.removeItem('date')
    localStorage.removeItem('hora')

  }

  backAdvertiseProperty() {
    this.router.navigate(['logged/advertise-property-stage-thirteen']);
  }
}
