import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-or-register',
  templateUrl: './login-or-register.component.html',
  styleUrls: ['./login-or-register.component.scss'],
})
export class LoginOrRegisterComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    let propertyIndication: string;
    let preanuncioId: string;
    let email: string;

    this.activatedRoute.params.subscribe(params => propertyIndication = params['id']);
    this.activatedRoute.params.subscribe(params => preanuncioId = params['anuncioId']);
    this.activatedRoute.params.subscribe(params => email = params['email']);

    console.log(preanuncioId,email);

    if(propertyIndication && propertyIndication != 'undefined')
      localStorage.setItem('propertyIndication', propertyIndication);
    if(preanuncioId && preanuncioId != 'undefined')
      localStorage.setItem('preanuncioId', preanuncioId);
    if(email && email != 'undefined')
      localStorage.setItem('email', email);

  }

  goToInsertTel() {
    this.router.navigate(['auth/insert-tel'])
  }

  goToSignUp() {
    this.router.navigate(['auth/sign-up'])
  }

}
