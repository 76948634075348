

import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppointmentTimeRemoveRequestDto } from 'src/app/dtos/appointment-time-remove-appointment-time-request.dto';
import { AppointmentTimesListResponseDto } from 'src/app/dtos/appointment-times-list-response.dto';
import { AppointmentTimesService } from 'src/app/services/appointment-times.service';
import { DatamokService } from 'src/app/services/datamok.service';
import { AnnouncementVisitGetPerDayRequestDto } from '../../../../dtos/announcement-visit/announcement-visit-get-per-day-request.dto';
import { AnnouncementVisitGetResponseDto } from '../../../../dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementVisitService } from '../../../../services/announcement-visit.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  @ViewChildren('btnDay') btnDays: QueryList<any>;
  @ViewChildren('btnEditDay') btnEditDay: QueryList<any>;
  @ViewChild('contentDays') contentDays: ElementRef;


  formtime: FormGroup;
  changeSubscriptionEdit: Subscription;

  today: number = Date.now();
  therearenovisits = true;
  availabletimes = true;
  checkboxstimes = false;
  rentcard = false;
  editedtimes = true;
  editbtn = true;
  downloadbtn = true;

  arrayDeDatas: any = [];

  response: AnnouncementVisitGetResponseDto[] = [];

  appointmentTimes: AppointmentTimesListResponseDto;

  days: any = [
    {
      dayEn: 'monday',
      dayBr: 'seg'
    },
    {
      dayEn: 'tuesday',
      dayBr: 'ter'
    },
    {
      dayEn: 'wednesday',
      dayBr: 'qua'
    },
    {
      dayEn: 'thursday',
      dayBr: 'qui'
    },
    {
      dayEn: 'friday',
      dayBr: 'sex'
    },
    {
      dayEn: 'saturday',
      dayBr: 'sab'
    },
    {
      dayEn: 'sunday',
      dayBr: 'dom'
    },
  ];

  daySelectedEdit: string = '';


  rangeHoursResponse: any = [];

  monday0809: boolean = false;
  monday0910: boolean = false;
  monday1011: boolean = false;
  monday1112: boolean = false;
  monday1213: boolean = false;
  monday1314: boolean = false;
  monday1415: boolean = false;
  monday1516: boolean = false;
  monday1617: boolean = false;
  monday1718: boolean = false;
  tuesday0809: boolean = false;
  tuesday0910: boolean = false;
  tuesday1011: boolean = false;
  tuesday1112: boolean = false;
  tuesday1213: boolean = false;
  tuesday1314: boolean = false;
  tuesday1415: boolean = false;
  tuesday1516: boolean = false;
  tuesday1617: boolean = false;
  tuesday1718: boolean = false;
  wednesday0809: boolean = false;
  wednesday0910: boolean = false;
  wednesday1011: boolean = false;
  wednesday1112: boolean = false;
  wednesday1213: boolean = false;
  wednesday1314: boolean = false;
  wednesday1415: boolean = false;
  wednesday1516: boolean = false;
  wednesday1617: boolean = false;
  wednesday1718: boolean = false;
  thursday0809: boolean = false;
  thursday0910: boolean = false;
  thursday1011: boolean = false;
  thursday1112: boolean = false;
  thursday1213: boolean = false;
  thursday1314: boolean = false;
  thursday1415: boolean = false;
  thursday1516: boolean = false;
  thursday1617: boolean = false;
  thursday1718: boolean = false;
  friday0809: boolean = false;
  friday0910: boolean = false;
  friday1011: boolean = false;
  friday1112: boolean = false;
  friday1213: boolean = false;
  friday1314: boolean = false;
  friday1415: boolean = false;
  friday1516: boolean = false;
  friday1617: boolean = false;
  friday1718: boolean = false;
  saturday0809: boolean = false;
  saturday0910: boolean = false;
  saturday1011: boolean = false;
  saturday1112: boolean = false;
  saturday1213: boolean = false;
  saturday1314: boolean = false;
  saturday1415: boolean = false;
  saturday1516: boolean = false;
  saturday1617: boolean = false;
  saturday1718: boolean = false;
  sunday0809: boolean = false;
  sunday0910: boolean = false;
  sunday1011: boolean = false;
  sunday1112: boolean = false;
  sunday1213: boolean = false;
  sunday1314: boolean = false;
  sunday1415: boolean = false;
  sunday1516: boolean = false;
  sunday1617: boolean = false;
  sunday1718: boolean = false;


  constructor(
    private datamockservice: DatamokService,
    private formBuilder: FormBuilder,
    private announcementVisitService: AnnouncementVisitService,
    private appointmentTimesService: AppointmentTimesService
  ) {
    this.formtime = this.formBuilder.group({
      monday0809: [false, [Validators.requiredTrue]],
      monday0910: [false, [Validators.requiredTrue]],
      monday1011: [false, [Validators.requiredTrue]],
      monday1112: [false, [Validators.requiredTrue]],
      monday1213: [false, [Validators.requiredTrue]],
      monday1314: [false, [Validators.requiredTrue]],
      monday1415: [false, [Validators.requiredTrue]],
      monday1516: [false, [Validators.requiredTrue]],
      monday1617: [false, [Validators.requiredTrue]],
      monday1718: [false, [Validators.requiredTrue]],
      tuesday0809: [false, [Validators.requiredTrue]],
      tuesday0910: [false, [Validators.requiredTrue]],
      tuesday1011: [false, [Validators.requiredTrue]],
      tuesday1112: [false, [Validators.requiredTrue]],
      tuesday1213: [false, [Validators.requiredTrue]],
      tuesday1314: [false, [Validators.requiredTrue]],
      tuesday1415: [false, [Validators.requiredTrue]],
      tuesday1516: [false, [Validators.requiredTrue]],
      tuesday1617: [false, [Validators.requiredTrue]],
      tuesday1718: [false, [Validators.requiredTrue]],
      wednesday0809: [false, [Validators.requiredTrue]],
      wednesday0910: [false, [Validators.requiredTrue]],
      wednesday1011: [false, [Validators.requiredTrue]],
      wednesday1112: [false, [Validators.requiredTrue]],
      wednesday1213: [false, [Validators.requiredTrue]],
      wednesday1314: [false, [Validators.requiredTrue]],
      wednesday1415: [false, [Validators.requiredTrue]],
      wednesday1516: [false, [Validators.requiredTrue]],
      wednesday1617: [false, [Validators.requiredTrue]],
      wednesday1718: [false, [Validators.requiredTrue]],
      thursday0809: [false, [Validators.requiredTrue]],
      thursday0910: [false, [Validators.requiredTrue]],
      thursday1011: [false, [Validators.requiredTrue]],
      thursday1112: [false, [Validators.requiredTrue]],
      thursday1213: [false, [Validators.requiredTrue]],
      thursday1314: [false, [Validators.requiredTrue]],
      thursday1415: [false, [Validators.requiredTrue]],
      thursday1516: [false, [Validators.requiredTrue]],
      thursday1617: [false, [Validators.requiredTrue]],
      thursday1718: [false, [Validators.requiredTrue]],
      friday0809: [false, [Validators.requiredTrue]],
      friday0910: [false, [Validators.requiredTrue]],
      friday1011: [false, [Validators.requiredTrue]],
      friday1112: [false, [Validators.requiredTrue]],
      friday1213: [false, [Validators.requiredTrue]],
      friday1314: [false, [Validators.requiredTrue]],
      friday1415: [false, [Validators.requiredTrue]],
      friday1516: [false, [Validators.requiredTrue]],
      friday1617: [false, [Validators.requiredTrue]],
      friday1718: [false, [Validators.requiredTrue]],
      saturday0809: [false, [Validators.requiredTrue]],
      saturday0910: [false, [Validators.requiredTrue]],
      saturday1011: [false, [Validators.requiredTrue]],
      saturday1112: [false, [Validators.requiredTrue]],
      saturday1213: [false, [Validators.requiredTrue]],
      saturday1314: [false, [Validators.requiredTrue]],
      saturday1415: [false, [Validators.requiredTrue]],
      saturday1516: [false, [Validators.requiredTrue]],
      saturday1617: [false, [Validators.requiredTrue]],
      saturday1718: [false, [Validators.requiredTrue]],
      sunday0809: [false, [Validators.requiredTrue]],
      sunday0910: [false, [Validators.requiredTrue]],
      sunday1011: [false, [Validators.requiredTrue]],
      sunday1112: [false, [Validators.requiredTrue]],
      sunday1213: [false, [Validators.requiredTrue]],
      sunday1314: [false, [Validators.requiredTrue]],
      sunday1415: [false, [Validators.requiredTrue]],
      sunday1516: [false, [Validators.requiredTrue]],
      sunday1617: [false, [Validators.requiredTrue]],
      sunday1718: [false, [Validators.requiredTrue]],

    });
    this.changeSubscriptionEdit = this.datamockservice.getcloseEditTimeTop().subscribe(() => {
      this.checkboxstimes = false;
      this.editedtimes = true;
      this.availabletimes = false;
      this.downloadbtn = true;
      this.editbtn = true;
    });
  }

  ngOnInit() {
    this.arrayDeDatas = []
    for (let i = 1; i < 6; i++) {
      let hoje = new Date();
      hoje.setDate(hoje.getDate() + i);
      this.arrayDeDatas.push(hoje)
    }

    setTimeout(() => {
      this.checar(this.arrayDeDatas[0]);
    }, 100);
  }


  checar(el) {
    if (el) {
      this.availabletimes = false;
      this.editedtimes = false;
      this.rentcard = true;
      this.editbtn = false;

      this.btnDays.map(results => {
        if (results.nativeElement?.id === el.toString()) {
          results.nativeElement.className = 'color-purple d-grid w-51 h-51 border-bottom-purple';
          const dateToString = new Date(el).toISOString()
          let request: AnnouncementVisitGetPerDayRequestDto = {
            day: dateToString
          }
          this.announcementVisitService.listPerDay(request).subscribe({
            next: data => {
              this.response = data;
            },
            error: erro => {
              console.error(erro)
            }
          })
        } else {
          results.nativeElement.className = 'color-black d-grid h-51 w-51';
        }
      });
    }

  }

  checarEditDay(item) {
    this.btnEditDay.map(results => {
      if (results.nativeElement?.id === item.toString()) {
        results.nativeElement.className = 'color-purple d-grid w-51 border-bottom-purple text-center text-capitalize';
        this.daySelectedEdit = item;
      } else {
        results.nativeElement.className = 'color-black d-grid w-51 text-center text-capitalize';
      }
    });
  }

  getValuesForEditVisit() {
    for (let i = 0; i < this.days.length; i++) {
      switch (this.days[i].dayEn) {
        case 'monday':

          for (let i = 0; i < this.appointmentTimes.monday.length; i++) {
            if (this.appointmentTimes.monday[i] === '08-09') {
              this.monday0809 = true;
            }

            if (this.appointmentTimes.monday[i] === '09-10') {
              this.monday0910 = true;
            }

            if (this.appointmentTimes.monday[i] === '10-11') {
              this.monday1011 = true;
            }

            if (this.appointmentTimes.monday[i] === '11-12') {
              this.monday1112 = true;
            }

            if (this.appointmentTimes.monday[i] === '12-13') {
              this.monday1213 = true;
            }

            if (this.appointmentTimes.monday[i] === '13-14') {
              this.monday1314 = true;
            }

            if (this.appointmentTimes.monday[i] === '14-15') {
              this.monday1415 = true;
            }

            if (this.appointmentTimes.monday[i] === '15-16') {
              this.monday1516 = true;
            }

            if (this.appointmentTimes.monday[i] === '16-17') {
              this.monday1617 = true;
            }

            if (this.appointmentTimes.monday[i] === '17-18') {
              this.monday1718 = true;
            }

          }
          this.formtime.patchValue({
            monday0809: this.monday0809,
            monday0910: this.monday0910,
            monday1011: this.monday1011,
            monday1112: this.monday1112,
            monday1213: this.monday1213,
            monday1314: this.monday1314,
            monday1415: this.monday1415,
            monday1516: this.monday1516,
            monday1617: this.monday1617,
            monday1718: this.monday1718
          })

          break;
        case 'tuesday':
          for (let i = 0; i < this.appointmentTimes.tuesday.length; i++) {
            if (this.appointmentTimes.tuesday[i] === '08-09') {
              this.tuesday0809 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '09-10') {
              this.tuesday0910 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '10-11') {
              this.tuesday1011 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '11-12') {
              this.tuesday1112 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '12-13') {
              this.tuesday1213 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '13-14') {
              this.tuesday1314 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '14-15') {
              this.tuesday1415 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '15-16') {
              this.tuesday1516 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '16-17') {
              this.tuesday1617 = true;
            }

            if (this.appointmentTimes.tuesday[i] === '17-18') {
              this.tuesday1718 = true;
            }

          }
          this.formtime.patchValue({
            tuesday0809: this.tuesday0809,
            tuesday0910: this.tuesday0910,
            tuesday1011: this.tuesday1011,
            tuesday1112: this.tuesday1112,
            tuesday1213: this.tuesday1213,
            tuesday1314: this.tuesday1314,
            tuesday1415: this.tuesday1415,
            tuesday1516: this.tuesday1516,
            tuesday1617: this.tuesday1617,
            tuesday1718: this.tuesday1718
          })

          break;
        case 'wednesday':
          for (let i = 0; i < this.appointmentTimes.wednesday.length; i++) {
            if (this.appointmentTimes.wednesday[i] === '08-09') {
              this.wednesday0809 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '09-10') {
              this.wednesday0910 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '10-11') {
              this.wednesday1011 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '11-12') {
              this.wednesday1112 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '12-13') {
              this.wednesday1213 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '13-14') {
              this.wednesday1314 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '14-15') {
              this.wednesday1415 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '15-16') {
              this.wednesday1516 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '16-17') {
              this.wednesday1617 = true;
            }

            if (this.appointmentTimes.wednesday[i] === '17-18') {
              this.wednesday1718 = true;
            }

          }
          this.formtime.patchValue({
            wednesday0809: this.wednesday0809,
            wednesday0910: this.wednesday0910,
            wednesday1011: this.wednesday1011,
            wednesday1112: this.wednesday1112,
            wednesday1213: this.wednesday1213,
            wednesday1314: this.wednesday1314,
            wednesday1415: this.wednesday1415,
            wednesday1516: this.wednesday1516,
            wednesday1617: this.wednesday1617,
            wednesday1718: this.wednesday1718
          })

          break;
        case 'thursday':
          for (let i = 0; i < this.appointmentTimes.thursday.length; i++) {
            if (this.appointmentTimes.thursday[i] === '08-09') {
              this.thursday0809 = true;
            }

            if (this.appointmentTimes.thursday[i] === '09-10') {
              this.thursday0910 = true;
            }

            if (this.appointmentTimes.thursday[i] === '10-11') {
              this.thursday1011 = true;
            }

            if (this.appointmentTimes.thursday[i] === '11-12') {
              this.thursday1112 = true;
            }

            if (this.appointmentTimes.thursday[i] === '12-13') {
              this.thursday1213 = true;
            }

            if (this.appointmentTimes.thursday[i] === '13-14') {
              this.thursday1314 = true;
            }

            if (this.appointmentTimes.thursday[i] === '14-15') {
              this.thursday1415 = true;
            }

            if (this.appointmentTimes.thursday[i] === '15-16') {
              this.thursday1516 = true;
            }

            if (this.appointmentTimes.thursday[i] === '16-17') {
              this.thursday1617 = true;
            }

            if (this.appointmentTimes.thursday[i] === '17-18') {
              this.thursday1718 = true;
            }

          }
          this.formtime.patchValue({
            thursday0809: this.thursday0809,
            thursday0910: this.thursday0910,
            thursday1011: this.thursday1011,
            thursday1112: this.thursday1112,
            thursday1213: this.thursday1213,
            thursday1314: this.thursday1314,
            thursday1415: this.thursday1415,
            thursday1516: this.thursday1516,
            thursday1617: this.thursday1617,
            thursday1718: this.thursday1718
          })

          break;
        case 'friday':
          for (let i = 0; i < this.appointmentTimes.thursday.length; i++) {
            if (this.appointmentTimes.thursday[i] === '08-09') {
              this.friday0809 = true;
            }

            if (this.appointmentTimes.thursday[i] === '09-10') {
              this.friday0910 = true;
            }

            if (this.appointmentTimes.thursday[i] === '10-11') {
              this.friday1011 = true;
            }

            if (this.appointmentTimes.thursday[i] === '11-12') {
              this.friday1112 = true;
            }

            if (this.appointmentTimes.thursday[i] === '12-13') {
              this.friday1213 = true;
            }

            if (this.appointmentTimes.thursday[i] === '13-14') {
              this.friday1314 = true;
            }

            if (this.appointmentTimes.thursday[i] === '14-15') {
              this.friday1415 = true;
            }

            if (this.appointmentTimes.thursday[i] === '15-16') {
              this.friday1516 = true;
            }

            if (this.appointmentTimes.thursday[i] === '16-17') {
              this.friday1617 = true;
            }

            if (this.appointmentTimes.thursday[i] === '17-18') {
              this.friday1718 = true;
            }

          }
          this.formtime.patchValue({
            friday0809: this.friday0809,
            friday0910: this.friday0910,
            friday1011: this.friday1011,
            friday1112: this.friday1112,
            friday1213: this.friday1213,
            friday1314: this.friday1314,
            friday1415: this.friday1415,
            friday1516: this.friday1516,
            friday1617: this.friday1617,
            friday1718: this.friday1718
          })

          break;
        case 'saturday':
          for (let i = 0; i < this.appointmentTimes.saturday.length; i++) {
            if (this.appointmentTimes.saturday[i] === '08-09') {
              this.saturday0809 = true;
            }

            if (this.appointmentTimes.saturday[i] === '09-10') {
              this.saturday0910 = true;
            }

            if (this.appointmentTimes.saturday[i] === '10-11') {
              this.saturday1011 = true;
            }

            if (this.appointmentTimes.saturday[i] === '11-12') {
              this.saturday1112 = true;
            }

            if (this.appointmentTimes.saturday[i] === '12-13') {
              this.saturday1213 = true;
            }

            if (this.appointmentTimes.saturday[i] === '13-14') {
              this.saturday1314 = true;
            }

            if (this.appointmentTimes.saturday[i] === '14-15') {
              this.saturday1415 = true;
            }

            if (this.appointmentTimes.saturday[i] === '15-16') {
              this.saturday1516 = true;
            }

            if (this.appointmentTimes.saturday[i] === '16-17') {
              this.saturday1617 = true;
            }

            if (this.appointmentTimes.saturday[i] === '17-18') {
              this.saturday1718 = true;
            }

          }
          this.formtime.patchValue({
            saturday0809: this.saturday0809,
            saturday0910: this.saturday0910,
            saturday1011: this.saturday1011,
            saturday1112: this.saturday1112,
            saturday1213: this.saturday1213,
            saturday1314: this.saturday1314,
            saturday1415: this.saturday1415,
            saturday1516: this.saturday1516,
            saturday1617: this.saturday1617,
            saturday1718: this.saturday1718
          })

          break;
        case 'sunday':
          for (let i = 0; i < this.appointmentTimes.sunday.length; i++) {
            if (this.appointmentTimes.sunday[i] === '08-09') {
              this.sunday0809 = true;
            }

            if (this.appointmentTimes.sunday[i] === '09-10') {
              this.sunday0910 = true;
            }

            if (this.appointmentTimes.sunday[i] === '10-11') {
              this.sunday1011 = true;
            }

            if (this.appointmentTimes.sunday[i] === '11-12') {
              this.sunday1112 = true;
            }

            if (this.appointmentTimes.sunday[i] === '12-13') {
              this.sunday1213 = true;
            }

            if (this.appointmentTimes.sunday[i] === '13-14') {
              this.sunday1314 = true;
            }

            if (this.appointmentTimes.sunday[i] === '14-15') {
              this.sunday1415 = true;
            }

            if (this.appointmentTimes.sunday[i] === '15-16') {
              this.sunday1516 = true;
            }

            if (this.appointmentTimes.sunday[i] === '16-17') {
              this.sunday1617 = true;
            }

            if (this.appointmentTimes.sunday[i] === '17-18') {
              this.sunday1718 = true;
            }

          }
          this.formtime.patchValue({
            sunday0809: this.sunday0809,
            sunday0910: this.sunday0910,
            sunday1011: this.sunday1011,
            sunday1112: this.sunday1112,
            sunday1213: this.sunday1213,
            sunday1314: this.sunday1314,
            sunday1415: this.sunday1415,
            sunday1516: this.sunday1516,
            sunday1617: this.sunday1617,
            sunday1718: this.sunday1718
          })

          break;
      }
    }

  }

  listAppointmentTimes() {
    this.appointmentTimesService.list().subscribe({
      next: data => {
        this.appointmentTimes = data;
        this.checarEditDay('monday');
        this.getValuesForEditVisit();
      },
      error: error => {
        console.error(error)
      }
    })
  }

  editSchedule(value) {
    if (value === 'checktime') {
      this.datamockservice.changeHeader();
      this.checkboxstimes = true;
      this.editedtimes = false;
      this.availabletimes = false;
      this.downloadbtn = false;
      this.editbtn = false;
      this.listAppointmentTimes();
    } else if (value === 'confirmchecktime') {
      this.datamockservice.showHeaderTop();
      this.checkboxstimes = false;
      this.editedtimes = false;
      this.availabletimes = true;
      this.downloadbtn = true;
      this.editbtn = true;
      this.confirmEdit();
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
    } else if (value === 'cancelchecktime') {
      this.datamockservice.showHeaderTop();
      this.checkboxstimes = false;
      this.editedtimes = true;
      this.availabletimes = false;
      this.downloadbtn = true;
      this.editbtn = true;
      setTimeout(() => {
        this.checar(this.arrayDeDatas[0]);
      }, 100);
    }
  }

  removerentcard() {
    this.availabletimes = true;
    this.editedtimes = true;
    this.rentcard = false;
    this.editbtn = true;
  }

  confirmEdit() {

    for (let i = 0; i < this.days.length; i++) {
      switch (this.days[i].dayEn) {
        case 'monday':

          if (this.formtime.controls['monday0809'].value === false && this.monday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday0809'].value === true && this.monday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday0910'].value === false && this.monday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday0910'].value === true && this.monday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1011'].value === false && this.monday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1011'].value === true && this.monday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1112'].value === false && this.monday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1112'].value === true && this.monday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1213'].value === false && this.monday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1213'].value === true && this.monday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1314'].value === false && this.monday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1314'].value === true && this.monday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1415'].value === false && this.monday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1415'].value === true && this.monday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1516'].value === false && this.monday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1516'].value === true && this.monday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1617'].value === false && this.monday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1617'].value === true && this.monday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['monday1718'].value === false && this.monday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['monday1718'].value === true && this.monday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'monday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }
          break

        case 'tuesday':

          if (this.formtime.controls['tuesday0809'].value === false && this.tuesday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday0809'].value === true && this.tuesday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday0910'].value === false && this.tuesday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday0910'].value === true && this.tuesday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1011'].value === false && this.tuesday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1011'].value === true && this.tuesday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1112'].value === false && this.tuesday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1112'].value === true && this.tuesday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1213'].value === false && this.tuesday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1213'].value === true && this.tuesday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1314'].value === false && this.tuesday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1314'].value === true && this.tuesday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1415'].value === false && this.tuesday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1415'].value === true && this.tuesday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1516'].value === false && this.tuesday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1516'].value === true && this.tuesday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1617'].value === false && this.tuesday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1617'].value === true && this.tuesday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['tuesday1718'].value === false && this.tuesday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['tuesday1718'].value === true && this.tuesday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'tuesday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          break

        case 'wednesday':

          if (this.formtime.controls['wednesday0809'].value === false && this.wednesday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday0809'].value === true && this.wednesday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday0910'].value === false && this.wednesday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday0910'].value === true && this.wednesday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1011'].value === false && this.wednesday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1011'].value === true && this.wednesday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1112'].value === false && this.wednesday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1112'].value === true && this.wednesday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1213'].value === false && this.wednesday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1213'].value === true && this.wednesday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1314'].value === false && this.wednesday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1314'].value === true && this.wednesday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1415'].value === false && this.wednesday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1415'].value === true && this.wednesday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1516'].value === false && this.wednesday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1516'].value === true && this.wednesday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1617'].value === false && this.wednesday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1617'].value === true && this.wednesday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['wednesday1718'].value === false && this.wednesday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['wednesday1718'].value === true && this.wednesday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'wednesday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          break

        case 'thursday':

          if (this.formtime.controls['thursday0809'].value === false && this.thursday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday0809'].value === true && this.thursday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday0910'].value === false && this.thursday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday0910'].value === true && this.thursday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1011'].value === false && this.thursday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1011'].value === true && this.thursday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1112'].value === false && this.thursday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1112'].value === true && this.thursday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1213'].value === false && this.thursday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1213'].value === true && this.thursday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1314'].value === false && this.thursday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1314'].value === true && this.thursday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1415'].value === false && this.thursday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1415'].value === true && this.thursday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1516'].value === false && this.thursday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1516'].value === true && this.thursday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1617'].value === false && this.thursday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1617'].value === true && this.thursday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['thursday1718'].value === false && this.thursday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['thursday1718'].value === true && this.thursday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'thursday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          break

        case 'friday':

          if (this.formtime.controls['friday0809'].value === false && this.friday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday0809'].value === true && this.friday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday0910'].value === false && this.friday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday0910'].value === true && this.friday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1011'].value === false && this.friday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1011'].value === true && this.friday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1112'].value === false && this.friday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1112'].value === true && this.friday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1213'].value === false && this.friday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1213'].value === true && this.friday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1314'].value === false && this.friday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1314'].value === true && this.friday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1415'].value === false && this.friday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1415'].value === true && this.friday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1516'].value === false && this.friday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1516'].value === true && this.friday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1617'].value === false && this.friday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1617'].value === true && this.friday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['friday1718'].value === false && this.friday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['friday1718'].value === true && this.friday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'friday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          break

        case 'saturday':

          if (this.formtime.controls['saturday0809'].value === false && this.saturday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday0809'].value === true && this.saturday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday0910'].value === false && this.saturday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday0910'].value === true && this.saturday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1011'].value === false && this.saturday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1011'].value === true && this.saturday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1112'].value === false && this.saturday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1112'].value === true && this.saturday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1213'].value === false && this.saturday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1213'].value === true && this.saturday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1314'].value === false && this.saturday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1314'].value === true && this.saturday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1415'].value === false && this.saturday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1415'].value === true && this.saturday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1516'].value === false && this.saturday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1516'].value === true && this.saturday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1617'].value === false && this.saturday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1617'].value === true && this.saturday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['saturday1718'].value === false && this.saturday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['saturday1718'].value === true && this.saturday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'saturday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          break

        case 'sunday':

          if (this.formtime.controls['sunday0809'].value === false && this.sunday0809 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday0809'].value === true && this.sunday0809 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '08-09'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday0910'].value === false && this.sunday0910 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday0910'].value === true && this.sunday0910 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '09-10'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1011'].value === false && this.sunday1011 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1011'].value === true && this.sunday1011 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '10-11'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1112'].value === false && this.sunday1112 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1112'].value === true && this.sunday1112 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '11-12'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1213'].value === false && this.sunday1213 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1213'].value === true && this.sunday1213 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '12-13'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1314'].value === false && this.sunday1314 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1314'].value === true && this.sunday1314 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '13-14'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1415'].value === false && this.sunday1415 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1415'].value === true && this.sunday1415 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '14-15'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1516'].value === false && this.sunday1516 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1516'].value === true && this.sunday1516 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '15-16'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1617'].value === false && this.sunday1617 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1617'].value === true && this.sunday1617 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '16-17'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {

              },
              error: error => {
                console.error(error)
              }
            })
          }

          if (this.formtime.controls['sunday1718'].value === false && this.sunday1718 === true) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.removeHourInDay(request).subscribe({
              next: data => {
              },
              error: error => {
                console.error(error)
              }
            })
          } else if (this.formtime.controls['sunday1718'].value === true && this.sunday1718 === false) {
            let request: AppointmentTimeRemoveRequestDto = {
              dayOfTheWeek: 'sunday',
              rangeHour: '17-18'
            }
            this.appointmentTimesService.addHourInDay(request).subscribe({
              next: data => {
              },
              error: error => {
                console.error(error)
              }
            })
          }

          break
      }
    }

  }


}
