import { Component, OnInit } from '@angular/core';
import {UserGetResponseDto} from "../../dtos/user-get-response.dto";

@Component({
  selector: 'app-finished-surveyor',
  templateUrl: './finished-surveyor.component.html',
  styleUrls: ['./finished-surveyor.component.scss'],
})
export class FinishedSurveyorComponent implements OnInit {
  user: UserGetResponseDto;
  isModalaskquestions = false;
  selectMenu= 0;

  opcoesvistorias= [
    {
      status:`Fotos pendentes`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`11:15`,
    },
    {
      status:`Fotos pendentes`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`12:15`,
    },
  ]
  opcoesAgendada= [
    {
      status:`Fotos Agendadas`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`11:15`,
      chave:'Entregues pelo síndico à Portaria.'
    },
    {
      status:`Fotos pendentes`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`12:15`,
      chave:'Entregues pelo síndico à Portaria.'
    },
  ]
  opcoesfinalizada= [
    {
      status:`Fotos Agendadas`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`11:15`,
      chave:'Entregues pelo síndico à Portaria.'
    },
    {
      status:`Fotografias Finalizadas`,
      desc:`Cobertura com uma vista incrível`,
      endereco:`Rua Estados Unidos 1987, Curitiba - PR`,
      date:`12:15`,

    },
  ]

  constructor() { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
  }

  changeData(value) {
    if (value === 'Novospage') {
      this.selectMenu = 0;

    } else if (value === 'Agendadaspage') {
      this.selectMenu = 1;

    } else if (value === 'finalizdaspage') {
      this.selectMenu = 2;

    }
  }

  setOpen(isOpen: boolean){
    this.isModalaskquestions = isOpen;
  }

}
