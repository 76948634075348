import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AnnouncementVisitGetPerDayRequestDto } from '../dtos/announcement-visit/announcement-visit-get-per-day-request.dto';
import { AnnouncementVisitGetResponseDto } from '../dtos/announcement-visit/announcement-visit-get-response.dto';
import { VisitUpdateStatusRequestDto } from '../dtos/announcement-visit/visit-update-status-request.dto';
import { BaseService } from './base.service';
import { VisitRegisterByBrokerRequestDto } from '../dtos/announcement-visit/visit-register-by-broker.request.dto';
import { AnnouncementVisitCheckCodeRequestDto } from '../dtos/announcement-visit/announcement-visit-check-code-request.dto';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementVisitService extends BaseService {
  url: string = `${environment.apis.imoveistock}app/announcement-visit`;

  reload: boolean = false;

  finishVisit = new Subject();

  constructor(private httpClient: HttpClient) {
    super();
  }

  list(): Observable<AnnouncementVisitGetResponseDto[]> {
    return this.httpClient
      .get(`${this.url}/broker_list`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  Visitlist(): Observable<AnnouncementVisitGetResponseDto[]> {
    return this.httpClient
      .get(`${this.url}/list`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listScheduled(): Observable<AnnouncementVisitGetResponseDto[]> {
    return this.httpClient
      .get(`${this.url}/list_scheduled`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listPerDay(
    dto: AnnouncementVisitGetPerDayRequestDto
  ): Observable<AnnouncementVisitGetResponseDto[]> {
    return this.httpClient
      .get(
        `${this.url}/list-for-owner-per-day?day=${dto.day}`,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateVisitBroker(_id: string): Observable<any> {
    return this.httpClient
      .patch(
        `${this.url}/schedule-visit-broker/id/${_id}`,
        null,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  cancelVisit(
    _id: string,
    cancelationReason: any
  ): Observable<AnnouncementVisitGetResponseDto> {
    return this.httpClient
      .patch(
        `${this.url}/cancel-visit/${_id}`,
        cancelationReason,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  acceptVisit(
    _id: string,
  ): Observable<AnnouncementVisitGetResponseDto> {
    return this.httpClient
      .patch(
        `${this.url}/accept-visit/${_id}`,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }


  getById(id: string): Observable<AnnouncementVisitGetResponseDto> {
    return this.httpClient
      .get(`${this.url}/id/${id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateStatus(_id: string, dto: VisitUpdateStatusRequestDto): Observable<any> {
    return this.httpClient
      .patch(
        `${this.url}/visit-update-status/${_id}`,
        dto,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  registerByBroker(
    dto: VisitRegisterByBrokerRequestDto,
    brokerId: string
  ): Observable<any> {
    return this.httpClient
      .post(
        `${this.url}/register-by-broker/${brokerId}`,
        dto,
        this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  checkCode(
    _id: string,
    dto: AnnouncementVisitCheckCodeRequestDto
  ): Observable<{ isValid: boolean }> {
    return this.httpClient
      .post(`${this.url}/check-code/${_id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  sendReminder(_id: string, dto: { messageTo: string }): Observable<void> {
    return this.httpClient
      .post(`${this.url}/send-reminder/${_id}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
}
