import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister2RequestDto } from '../../../dtos/announcement/announcement-register-2-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { AnnouncementFormBuilderService } from './announcement-form-builder.service';

@Component({
  selector: 'app-advertise-property-stage-two',
  templateUrl: './advertise-property-stage-two.component.html',
  styleUrls: ['./advertise-property-stage-two.component.scss'],
})
export class AdvertisePropertyStageTwoComponent implements OnInit {
  form: FormGroup;
  public maskConstruction: Array<any> = [/\d/, /\d/, /\d/, /\d/];
  ndormitories = 0;
  nsuites = 0;
  nbathroom = 0;
  nrooms = 0;
  ngarage = 0;
  ncovered = 0;

  request: AnnouncementRegister2RequestDto;

  response: AnnouncementGetResponseDto;

  showPropertyStatus: boolean = true;
  showUsefulArea: boolean = true;
  showWhoLivesInTheProperty: boolean = true;
  showrooms: boolean = true;
  showBedrooms: boolean = true;
  showSuites: boolean = true;
  showBathrooms: boolean = true;
  showPetsAccepted: boolean = true;
  showPropertyPosition: boolean = true;
  showYearOfConstruction: boolean = true;
  showParkingSpaces: boolean = true;
  showHowManyCovers: boolean = true;
  showTypeOfVacancy: boolean = true;
  showVacancyNumber: boolean = true;
  showVacancyLocation: boolean = true;

  verificationType: string = '';

  propertyWhoLives = [
    { value: 'vacant', name: 'Está vago' },
    { value: 'live', name: 'Estou morando' },
    { value: 'tenant', name: 'Inquilino morando' },
  ]

  propertyTypesType = [
    { value: 'deed', name: 'Escritura' },
    { value: 'byConvention', name: 'Por convenção' },
    { value: 'rotary', name: 'Rotativa' },
  ]

  propertyTypesLocalizacao = [
    { value: 'groundfloor', name: 'Térreo' },
    { value: 'S1', name: 'S1' },
    { value: 'S2', name: 'S2' },
    { value: 'S3', name: 'S3' },
    { value: 'S4', name: 'S4' },
    { value: 'S5', name: 'S5' },
    { value: 'S6', name: 'S6' },
  ]

  constructor(
    private router: Router,
    private announcementService: AnnouncementService,
    private toastController: ToastController,
    private announcementFormBuilderService: AnnouncementFormBuilderService
  ) {
  }

  ngOnInit() {
    this.form = this.announcementFormBuilderService.buildForm();
  }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
    if (localStorage.getItem('continueAnnouncement') !== null) {
      localStorage.removeItem('continueAnnouncement')
    }
  }

  organizeData(success) {
    this.response = success;
    this.determineVerificationType();
    this.patchFormValues();
    this.setOtherVariables();
  }

  determineVerificationType() {
    switch (this.response.goal + '-' + this.response.propertyCharacteristics) {
      case 'comercial-edificio':
        this.showWhoLivesInTheProperty = false;
        this.showBedrooms = false;
        this.showSuites = false;
        this.verificationType = 'commercialBuilding';

      case 'residencial-edificio':
        this.showrooms = false;
        this.showVacancyLocation = false;
        this.showTypeOfVacancy = false;
        this.showVacancyNumber = false;
        this.verificationType = 'residentialBuilding';
        break;

      case 'comercial-casa':
        this.showBedrooms = false;
        this.showSuites = false;
        this.showVacancyLocation = false;
        this.showTypeOfVacancy = false;
        this.showVacancyNumber = false;
        this.verificationType = 'commercialHouse';
        break;

      case 'residencial-casa':
        this.showrooms = false;
        this.showVacancyLocation = false;
        this.showTypeOfVacancy = false;
        this.showVacancyNumber = false;
        this.verificationType = 'residentialHouse';
        break;

      case 'rural-casa':
        this.showrooms = false;
        this.showVacancyLocation = false;
        this.showTypeOfVacancy = false;
        this.showVacancyNumber = false;
        this.verificationType = 'ruralHouse';
        break;

      default:
        if (this.response.propertyCharacteristics === 'terreno') {
          this.showWhoLivesInTheProperty = false;
          this.showrooms = false;
          this.showBedrooms = false;
          this.showSuites = false;
          this.showBathrooms = false;
          this.showPetsAccepted = false;
          this.showPropertyPosition = false;
          this.showYearOfConstruction = false;
          this.showParkingSpaces = false;
          this.showHowManyCovers = false;
          this.showTypeOfVacancy = false;
          this.showVacancyNumber = false;
          this.showVacancyLocation = false;
          this.verificationType = 'ground';
        }
    }
  }

  patchFormValues() {
    if (this.response.whoLivesInTheProperty !== null) {
      this.form.patchValue({
        propertyStatus: this.response.propertyStatus,
        usefulArea: this.response.usefulArea,
        whoLivesInTheProperty: this.response.whoLivesInTheProperty,
        bathrooms: this.response.bathrooms,
        propertyPosition: this.response.propertyPosition,
        petsAccepted: this.response.petsAccepted,
        yearOfConstruction: this.response.yearOfConstruction,
        parkingSpaces: this.response.parkingSpaces,
        howManyCovers: this.response.howManyCovers,
        rooms: this.response.rooms,
        suites: this.response.suites,
        bedrooms: this.response.bedrooms,
        vacancyLocation: null,
        typeOfVacancy: null,
        vacancyNumber: null,
      });
    }
  }

  setOtherVariables() {
    this.ndormitories = this.response.bedrooms !== null ? this.response.bedrooms : this.ndormitories;
    this.nsuites = this.response.suites !== null ? this.response.suites : this.nsuites;
    this.nrooms = this.response.rooms !== null ? this.response.rooms : this.nrooms;
    this.nbathroom = this.response.bathrooms !== null ? this.response.bathrooms : this.nbathroom;
    this.ngarage = this.response.parkingSpaces !== null ? this.response.parkingSpaces : this.ngarage;
    this.ncovered = this.response.howManyCovers !== null ? this.response.howManyCovers : this.ncovered;
  }

  confirm() {
    this.request = Object.assign({}, this.form.value);
    const conditionMet = this.announcementFormBuilderService.hasChanges(
      this.request,
      this.response
    );

   this.parseAcceptPetField();

    if (conditionMet) {
      this.announcementService
        .updateStep2(localStorage.getItem('announcementId'), this.request)
        .subscribe(
          (success) => this.registerSuccess(success),
          (error) => this.runError(error)
        );
    } else {
      this.router.navigate(['logged/advertise-property-stage-three']);
    }
  }

  parseAcceptPetField(){
    if(this.request.petsAccepted === ''){
      this.request.petsAccepted = 'uninformed'
    }
    // @ts-ignore
    if(this.request.petsAccepted === false){
      this.request.petsAccepted = 'rejected'
    }
    if(this.request.petsAccepted){
      this.request.petsAccepted = 'accepted'
    }
  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Informações sobre area interna e garagem cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-three'])
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar informações sobre area interna e garagem do anúncio!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }

  addDormitories() {
    if (this.ndormitories <= 10) {
      this.ndormitories++;
    }
  }
  removeDormitories() {
    if (this.ndormitories >= 1) {
      this.ndormitories--;
    }
  }
  addSuites() {
    if (this.nsuites <= 10 && this.nsuites < this.ndormitories) {
      this.nsuites++;
    }
  }
  removeSuites() {
    if (this.nsuites >= 1) {
      this.nsuites--;
    }
  }
  addRooms() {
    if (this.nrooms <= 10) {
      this.nrooms++;
    }
  }
  removeRooms() {
    if (this.nrooms >= 1) {
      this.nrooms--;
    }
  }
  addBathroom() {
    if (this.nbathroom <= 10) {
      this.nbathroom++;
    }
  }
  removeBathroom() {
    if (this.nbathroom >= 1) {
      this.nbathroom--;
    }
  }
  addGarage() {
    if (this.ngarage <= 15) {
      this.ngarage++;
    }
  }
  removeGarage() {
    if (this.ngarage >= 1) {
      this.ngarage--;
    }
  }
  addCovered() {
    if (this.ncovered < this.ngarage) {
      this.ncovered++;
    }
  }
  removeCovered() {
    if (this.ncovered >= 1) {
      this.ncovered--;
    }
  }

  backStageOne() {
    localStorage.setItem('continueAnnouncement', this.response._id);
    this.router.navigate(['/logged/advertise-property-stage-one'])
  }

}