import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { PhotoScheduleService } from '../../../services/photo-schedule.service';

@Component({
  selector: 'app-advertise-property-stage-thirteen',
  templateUrl: './advertise-property-stage-thirteen.component.html',
  styleUrls: ['./advertise-property-stage-thirteen.component.scss'],
})
export class AdvertisePropertyStageThirteenComponent implements OnInit {
  formpropertyThirteen: FormGroup;
  valueCurrency: 0;

  response: AnnouncementGetResponseDto;


  arrayDeDatas: any = [];

  dataSelecionada: any;
  horasSelecionada: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private announcementService: AnnouncementService,
    private photoScheduleService: PhotoScheduleService
  ) {
    this.formpropertyThirteen = this.formBuilder.group({
      hour: ['', [Validators.required]],
      day: ['', [Validators.required]],
    });
  }



  ngOnInit() {
  }

  ionViewWillEnter() {
    for (let i = 1; i < 6; i++) {
      let hoje = new Date()
      hoje.setDate(hoje.getDate() + i);
      this.arrayDeDatas.push(hoje)
    }

    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success;
  }

  selectDate(value) {
    this.dataSelecionada = value
    localStorage.setItem('date', this.dataSelecionada)
  }

  selectHour(value) {
    this.horasSelecionada = value
    localStorage.setItem('hora',this.horasSelecionada)
  }

  async confirm() {
    // this.router.navigate(['logged/advertise-property-stage-fourteen']);
    if (this.dataSelecionada === undefined) {
      const toast = await this.toastController.create({
        message: `Selecione uma data!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
      return
    }
    if (this.horasSelecionada === undefined) {
      const toast = await this.toastController.create({
        message: `Selecione uma hora!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
      return
    }

    const keysForPhotographer = JSON.parse(localStorage.getItem('keysForPhotographer'));

    let request = {
      scheduleHour: this.horasSelecionada,
      scheduleDate: this.dataSelecionada.toISOString(),
      keysForPhotographer: keysForPhotographer.keysForPhotographer
    }


    if(!this.response._id && localStorage.getItem('announcementId') !== null) {
      this.response._id = localStorage.getItem('announcementId')
    }

    this.announcementService.updateStep11(this.response._id, request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Agendamento cadastrado com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.router.navigate(['logged/advertise-property-stage-fourteen']);

      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    )

  }
  backAdvertisePropertyStageOne() {
    this.router.navigate(['logged/advertise-property-stage-twelve']);
  }

}
