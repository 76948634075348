import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { SurveyorRegisterRequestDto } from '../dtos/surveyor/surveyor-register-request.dto';
@Injectable({
  providedIn: 'root',
})
export class SurveyorService extends BaseService {
  url: string = `${environment.apis.imoveistock}app/inspection-schedule/`;
  urlInsp: string = `${environment.apis.imoveistock}app/inspection/`;

  annoucement: any;
  surveyorid: string;
  inspectionId: string;

  constructor(private httpClient: HttpClient) {
    super();
  }

  listSurveyor(): Observable<any> {
    return this.httpClient
      .get(`${this.url}list/pending/location`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }
  

  listInspectionById(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.urlInsp}getById/${_id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listPhotoByInspection(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.urlInsp}getPhotoByInspection/${_id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateStatus(_id: string, dto): Observable<any> {
    return this.httpClient
      .put(
        `${this.url}update-status/${_id}`, dto, this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listInspection(): Observable<any> {
    return this.httpClient
      .get(`${this.urlInsp}list/`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  listInspectionBySchedule(_id: string): Observable<any> {
    return this.httpClient
      .get(`${this.urlInsp}getByInspectionSchedule/${_id}`, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  register(dto: SurveyorRegisterRequestDto): Observable<any> {
    return this.httpClient
      .post(`${this.urlInsp}`, dto, this.authorizedHeader())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  updateInspection(_id: string, dto): Observable<any> {
    return this.httpClient
      .put(
        `${this.urlInsp}update/${_id}`, dto, this.authorizedHeader()
      )
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  inspectionPhoto(file: any, _id: string): Observable<any> {
    return this.httpClient
      .post(`${this.urlInsp}upload/${_id}`, file, this.authorizedHeaderPostPhoto())
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

}
