import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister8RequestDto } from '../../../dtos/announcement/announcement-register-8-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { S3FilesService } from '../../../services/s3-files.service';

@Component({
  selector: 'app-advertise-property-stage-eight',
  templateUrl: './advertise-property-stage-eight.component.html',
  styleUrls: ['./advertise-property-stage-eight.component.scss'],
})
export class AdvertisePropertyStageEightComponent implements OnInit {

  formpropertyEight: FormGroup;

  condominiumDoc: any = [];
  iptuDoc: any = [];
  rgDoc: any = [];
  cpfDoc: any = [];
  letterAttorneyDoc: any = [];
  propertyDoc: any = [];

  uploadproperty = true;
  spinnerProperty = true;
  checkmarkProperty = false;

  uploadcondominium = true;
  spinnercondominium = true;
  checkmarkcondominium = false;

  uploadiptu = true;
  spinneriptu = true;
  checkmarkiptu = false;

  uploadrg = true;
  spinnerrg = true;
  checkmarkrg = false;

  uploadcpf = true;
  spinnercpf = true;
  checkmarkcpf = false;

  uploadletterAttorney = true;
  spinnerletterAttorney = true;
  checkmarkletterAttorney = false;

  documentOwner: any = [];

  response: AnnouncementGetResponseDto;


  selectedDocumentProperty: any;
  selectedDocumentCondominium: any;
  selectedDocumentIptu: any;
  selectedDocumentRg: any;
  selectedDocumentCpf: any;
  selectedDocumentLetterAttorney: any;

  propertyDocumentSend: any;
  condominiumDocumentSend: any;
  iptuDocumentSend: any;
  rgDocumentSend: any;
  cpfDocumentSend: any;
  letterAttorneyDocumentSend: any;

  request: AnnouncementRegister8RequestDto;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private s3FilesService: S3FilesService,
    private toastController: ToastController,

  ) {
    this.formpropertyEight = this.formBuilder.group({
      property: [''],
      condominium: [''],
      iptu: [''],
      rg: [''],
      cpf: [''],
      letterAttorney: [''],
    });
  }



  ngOnInit() { }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => {
        this.response = success
        for (let i = 0; i < this.response.documentsOwner.length; i++) {
          if (this.response.documentsOwner[i].documentName === 'imovel') {
            setTimeout(() => {
              this.propertyDoc.push({ name: 'imovel-doc' });
              this.spinnerProperty = false;
              this.uploadproperty = false;
            }, 1000);
          }
          if (this.response.documentsOwner[i].documentName === 'condominio') {
            setTimeout(() => {
              this.condominiumDoc.push({ name: 'condominio-doc' });
              this.spinnercondominium = false;
              this.uploadcondominium = false;
            }, 1000);
          }
          if (this.response.documentsOwner[i].documentName === 'IPTU') {
            setTimeout(() => {
              this.iptuDoc.push({ name: 'IPTU-doc' });
              this.spinneriptu = false;
              this.uploadiptu = false;
            }, 1000);
          }
          if (this.response.documentsOwner[i].documentName === 'CPF') {
            setTimeout(() => {
              this.cpfDoc.push({ name: 'CPF-doc' });
              this.spinnercpf = false;
              this.uploadcpf = false;
            }, 1000);
          }
          if (this.response.documentsOwner[i].documentName === 'RG') {
            setTimeout(() => {
              this.rgDoc.push({ name: 'RG-doc' });
              this.spinnerrg = false;
              this.uploadrg = false;
            }, 1000);
          }
          if (this.response.documentsOwner[i].documentName === 'procuracao') {
            setTimeout(() => {
              this.letterAttorneyDoc.push({ name: 'procuracao-doc' });
              this.spinnerletterAttorney = false;
              this.uploadletterAttorney = false;
            }, 1000);
          }
        }

      },
      error => console.error(error)
    )

  }


  async confirm() {

    this.documentOwner = [];

    if (this.checkmarkProperty) {
      const result = await this.upload(this.propertyDocumentSend);
      let item = {
        documentName: 'imovel',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }

    if (this.checkmarkcondominium) {
      const result = await this.upload(this.condominiumDocumentSend);
      let item = {
        documentName: 'condominio',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }

    if (this.checkmarkiptu) {
      const result = await this.upload(this.iptuDocumentSend);
      let item = {
        documentName: 'IPTU',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }

    if (this.checkmarkcpf) {
      const result = await this.upload(this.cpfDocumentSend);
      let item = {
        documentName: 'CPF',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }

    if (this.checkmarkrg) {
      const result = await this.upload(this.rgDocumentSend);
      let item = {
        documentName: 'RG',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }

    if (this.checkmarkletterAttorney) {
      const result = await this.upload(this.letterAttorneyDocumentSend);
      let item = {
        documentName: 'procuracao',
        documentKey: result.key
      }
      this.documentOwner.push(item);
    }


    if (this.documentOwner.length > 0) {
      this.savedocs();
    } else {
      this.router.navigate(['logged/advertise-property-stage-nine']);
    }

  }

  upload(documentSend) {
    return this.s3FilesService.upload(documentSend).toPromise();
  }

  savedocs() {
    this.request = {
      documentOwner: this.documentOwner
    }

    this.announcementService.updateStep8(this.response._id, this.request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Documentos enviados com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        localStorage.setItem('announcementId', success._id);
        this.router.navigate(['logged/advertise-property-stage-nine']);
      },
      async error => {
        console.error(error);
        const toast = await this.toastController.create({
          message: `Erro ao enviar documentos!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    )


  }

  backAdvertisePropertyStageOne() {
    if (this.response.typeOfAd === 'rent') {
      this.router.navigate(['logged/advertise-property-stage-six'])
    } else {
      this.router.navigate(['logged/advertise-property-stage-seven']);
    }
  }

  // imóveis
  onSelectProperty(event) {

    if (event.target.value) {
      this.propertyDoc.push(event.target.files[0]);
      this.selectedDocumentProperty = <File>event.target.files[0];
      this.propertyDocumentSend = new FormData;
      this.propertyDocumentSend.append('file', this.selectedDocumentProperty, this.selectedDocumentProperty.name)
      setTimeout(() => {
        this.spinnerProperty = false;
        this.checkmarkProperty = true;
        this.uploadproperty = false;
      }, 1000);
    }
  }


  // Boleto condomínio
  onSelectCondominium(event) {
    this.condominiumDoc.push(event.target.files[0]);
    this.selectedDocumentCondominium = <File>event.target.files[0];
    this.condominiumDocumentSend = new FormData;
    this.condominiumDocumentSend.append('file', this.selectedDocumentCondominium, this.selectedDocumentCondominium.name)
    setTimeout(() => {
      this.spinnercondominium = false;
      this.checkmarkcondominium = true;
      this.uploadcondominium = false;
    }, 1000);
  }
  // IPTU
  onSelectIptu(event) {
    this.iptuDoc.push(event.target.files[0]);
    this.selectedDocumentIptu = <File>event.target.files[0];
    this.iptuDocumentSend = new FormData;
    this.iptuDocumentSend.append('file', this.selectedDocumentIptu, this.selectedDocumentIptu.name)
    setTimeout(() => {
      this.spinneriptu = false;
      this.checkmarkiptu = true;
      this.uploadiptu = false;
    }, 1000);
  }
  // rg
  onSelectRg(event) {
    this.rgDoc.push(event.target.files[0]);
    this.selectedDocumentRg = <File>event.target.files[0];
    this.rgDocumentSend = new FormData;
    this.rgDocumentSend.append('file', this.selectedDocumentRg, this.selectedDocumentRg.name)
    setTimeout(() => {
      this.spinnerrg = false;
      this.checkmarkrg = true;
      this.uploadrg = false;
    }, 1000);
  }
  // cpf
  onSelectCpf(event) {
    this.cpfDoc.push(event.target.files[0]);
    this.selectedDocumentCpf = <File>event.target.files[0];
    this.cpfDocumentSend = new FormData;
    this.cpfDocumentSend.append('file', this.selectedDocumentCpf, this.selectedDocumentCpf.name)
    setTimeout(() => {
      this.spinnercpf = false;
      this.checkmarkcpf = true;
      this.uploadcpf = false;
    }, 1000);
  }
  // procuração
  onSelecLetterofAttorney(event) {
    this.letterAttorneyDoc.push(event.target.files[0]);
    this.selectedDocumentLetterAttorney = <File>event.target.files[0];
    this.letterAttorneyDocumentSend = new FormData;
    this.letterAttorneyDocumentSend.append('file', this.selectedDocumentLetterAttorney, this.selectedDocumentLetterAttorney.name)
    setTimeout(() => {
      this.spinnerletterAttorney = false;
      this.checkmarkletterAttorney = true;
      this.uploadletterAttorney = false;
    }, 1000);
  }

}
