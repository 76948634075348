/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {ModalController, ToastController} from '@ionic/angular';
import { AuthenticateRequestDto } from '../../dtos/authenticate-request.dto';
import { AuthenticationService } from '../../services/authentication.service';
import {UserService} from "../../services/user.service";
// import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TermComponent} from "../../shared/term/term.component";
import {PoliticComponent} from "../../shared/politic/politic.component";

@Component({
  selector: 'app-insert-tel',
  templateUrl: './insert-tel.component.html',
  styleUrls: ['./insert-tel.component.scss'],
})
export class InsertTelComponent implements OnInit {

  form: FormGroup;
  isModalterm = false;
  isModalpolitic = false;

  public maskTel: Array<any> = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  request: AuthenticateRequestDto;

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalController: ModalController
  ) {
    this.form = this.formBuilder.group({
      phone: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.form.reset()
  }

  async confirm() {
    this.request = {
      phone: `55${this.form.controls['phone'].value}`.replace(/\D/g, '')
    }
    this.authenticationService.authenticate(this.request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Sms enviado com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        localStorage.setItem('phone', this.request.phone);
        this.router.navigate(['auth/insert-code'])
      },
      async error => {
        const codeError = error.status;
        let messageError;

        if (codeError === 400) {
          messageError = 'Usuário não cadastrado';
        }

        const toast = await this.toastController.create({
          message: messageError ||
          `Não foi possível enviar SMS!`,
          duration: 5000,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    )
  }
  closeModalTerm() {
    this.isModalterm = false;
  }
  closeModalPolitic() {
    this.isModalpolitic = false;
  }

  openTermsModal(isOpen: boolean){
    this.isModalterm = isOpen;
  }
  openTermsPolitic(isOpen: boolean){
    this.isModalpolitic = isOpen;
  }


  async openModalTerm() {
    const modal = await this.modalController.create({
      component: TermComponent
    });
    return await modal.present();
  }
  async openModalPolitic() {
    const modal = await this.modalController.create({
      component: PoliticComponent
    });
    return await modal.present();
  }
}
