import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthenticateRequestDto } from "../dtos/authenticate-request.dto";
import { AuthenticateResponseDto } from "../dtos/authenticate-response.dto";
import { AuthetincatedUserDto } from "../dtos/authenticated-user.dto";
import { AuthenticateCodeConfirmationRequestDto } from "../dtos/authentication-code-confirmation-request.dto";
import { JwtPayload } from "../interfaces/jwt-payload.interface";
import LocalStorageUtil, { LocalStorageKeys } from "../utils/localStorage.util";
import { BaseService } from "./base.service";
import jwtDecode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseService {

    url: string = `${environment.apis.imoveistock}app/authentication`;

    token: any;

    jwtPayload: JwtPayload;

    constructor(
        private httpClient: HttpClient
    ) {
        super();
    }

    authenticate(dto: AuthenticateRequestDto): Observable<AuthenticateResponseDto> {
        return this.httpClient
            .post(`${this.url}/authenticate`, dto, this.anonymousHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    authenticateByEmail(dto: AuthenticateRequestDto): Observable<AuthenticateResponseDto> {
        return this.httpClient
            .post(`${this.url}/authenticate-email`, dto, this.anonymousHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    authenticateCodeConfirmation(dto: AuthenticateCodeConfirmationRequestDto): Observable<AuthetincatedUserDto> {
        return this.httpClient
            .post(`${this.url}/authenticate-code-confirmation`, dto, this.anonymousHeader())
            .pipe(
                map(this.extractData),
                catchError(this.serviceError)
            );
    }

    getPayLoadFromLocalStorage(): JwtPayload {
        const token = this.getAuthenticatedUser();
        return jwtDecode(token.token);
    }

    setAuthenticatedUser(dto: AuthetincatedUserDto) {
        LocalStorageUtil.set(LocalStorageKeys.user, dto);
        this.getPayLoadFromJWT();
    }

    getAuthenticatedUser(): AuthetincatedUserDto {
        
        const user = LocalStorageUtil.get(LocalStorageKeys.user);
        console.log(user)
            const headAut = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                })
            }
        
        const isAuth = this.httpClient
            .get(`${environment.apis.imoveistock}app/user-client/authenticated`,headAut)            
            .pipe(                
                map(this.extractData),
                catchError(this.serviceError)
            ).subscribe(result => {
                localStorage.setItem('userDto', JSON.stringify(result))
                
            });            
        return LocalStorageUtil.get(LocalStorageKeys.user);
    }

    getPayLoadFromJWT() {
        this.token = this.getAuthenticatedUser();
        return this.jwtPayload = jwtDecode(this.token.token);
    }

    removeAuthenticatedUser(){
        localStorage.removeItem('user');
    }

    isJwtValid() {
        const user = this.getAuthenticatedUser();
    
        if (!user) {
          return false;
        }
    
        const payload = this.getPayLoadFromJWT();
    
        if (payload.exp < new Date().getTime()/1_000) {
          return false;
        }
    
        return true;
      }

}
