/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { UserEditPhotoRequestDto } from '../../dtos/user-edit-photo-request.dto';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  user: UserGetResponseDto;
  dataPersonal: boolean = true;
  workData: boolean = false;
  viewData: boolean = true;

  urls: any = [];

  requestPhoto: UserEditPhotoRequestDto;

  constructor(
    private userService: UserService,
    private toastController: ToastController,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.getUser().subscribe(
      success => {
        this.user = success;
        if (this.user?.photo?.location) {
          this.urls.push(this.user.photo.location);
        }
      },
      error => {
        console.error(error);
      }
    );
    // this.user = JSON.parse(localStorage.getItem('userDto'));
  }

  changeData() {
    if (this.dataPersonal === true) {
      this.dataPersonal = false;
      this.workData = true;
    } else if (this.workData === true) {
      this.dataPersonal = true;
      this.workData = false;
    }
  }

  openEdit() {
    if (this.dataPersonal === true) {

    } else if (this.workData === true) {

    }
    this.viewData = false;
  }

  closeEdit(event) {
    this.viewData = true;
    console.log(event);
    this.userService.getUser().subscribe(
      success => {
        this.user = success;
      },
      error => {
        console.error(error);
      }
    );
  }

  checkOut() {
    localStorage.removeItem('user');
    this.router.navigate(['auth/insert-tel']);
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {

        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.urls = [];
          this.urls.push(event.target.result);

          this.requestPhoto = {
            photo: event.target.result
          };
          this.userService.editPhoto(this.requestPhoto).subscribe(
            async success => {
              this.userService.getUser().subscribe(
                success => {
                  let user = JSON.stringify(success);
                  localStorage.setItem('userDto', user);
                },
                error => {
                  console.error(error);
                }
              );
              const toast = await this.toastController.create({
                message: `Foto do perfil alterada com sucesso!`,
                duration: 1500,
                position: 'top',
                color: 'success',
              });
              toast.present();
            },
            async error => {
              const toast = await this.toastController.create({
                message: `Erro ao alterar foto do perfil!`,
                duration: 1500,
                position: 'top',
                color: 'danger',
              });
              toast.present();
            }
          );
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
}
