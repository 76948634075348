import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-models-advertise-stage-ten',
  templateUrl: './models-advertise-stage-ten.component.html',
  styleUrls: ['./models-advertise-stage-ten.component.scss'],
})
export class ModelsAdvertiseStageTenComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    ) { }

  ngOnInit() {}

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
