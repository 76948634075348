/* eslint-disable no-var */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister5RequestDto } from '../../../dtos/announcement/announcement-register-5-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';

@Component({
  selector: 'app-advertise-property-stage-five',
  templateUrl: './advertise-property-stage-five.component.html',
  styleUrls: ['./advertise-property-stage-five.component.scss'],
})
export class AdvertisePropertyStageFiveComponent implements OnInit {
  form: FormGroup;
  public maskCpf: Array<any> = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  valueCurrency: 0;

  request: AnnouncementRegister5RequestDto;

  response: AnnouncementGetResponseDto;

  propertyName = [
    { value: 'owner', name: 'Proprietário' },
    { value: 'administrator', name: 'Administrador' },
  ];

  imovelQuitadoValue: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      propertyPaidOff: ['', [Validators.required]],
      debitBalance: ['', [Validators.required]],
      relationshipWithTheProperty: [null, [Validators.required]],
      ownerName: ['', [Validators.required]],
      ownerCpf: ['', [Validators.required]],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success;
    let propertyPaidOff: string;
    if (this.response.propertyPaidOff === true) {
      propertyPaidOff = 'yes'
    } else {
      propertyPaidOff = 'no'
    }
    if (this.response.description !== null) {
      if (this.response.typeOfAd === 'sale') {
        this.form.patchValue({
          description: this.response.description,
          propertyPaidOff: propertyPaidOff,
          debitBalance: this.response.debitBalance,
          relationshipWithTheProperty: this.response.relationshipWithTheProperty,
          ownerName: this.response.ownerName,
          ownerCpf: this.response.ownerCpf,
          title: this.response.title,
        })
      } else if (this.response.typeOfAd === 'rent') {
        this.form.patchValue({
          description: this.response.description,
          propertyPaidOff: '',
          debitBalance: null,
          relationshipWithTheProperty: this.response.relationshipWithTheProperty,
          ownerName: this.response.ownerName,
          ownerCpf: this.response.ownerCpf,
          title: this.response.title,
        })
      } else if (this.response.typeOfAd === 'both') {
        this.form.patchValue({
          description: this.response.description,
          propertyPaidOff: propertyPaidOff,
          debitBalance: this.response.debitBalance,
          relationshipWithTheProperty: this.response.relationshipWithTheProperty,
          ownerName: this.response.ownerName,
          ownerCpf: this.response.ownerCpf,
          title: this.response.title,
        })
      }
    }
  }


  confirm() {
    this.request = Object.assign({}, this.form.value);

    if (this.response.typeOfAd === 'sale') {
      if (this.request.description !== this.request.description
        || this.request.debitBalance !== this.response.debitBalance
        || this.imovelQuitadoValue !== this.response.propertyPaidOff
        || this.request.relationshipWithTheProperty !== this.response.relationshipWithTheProperty
        || this.request.ownerName !== this.response.ownerName
        || this.request.ownerCpf !== this.response.ownerCpf
        || this.request.title !== this.response.title) {
        this.announcementService.updateStep5(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-six']);
      }
    } else if (this.response.typeOfAd === 'rent') {
      if (this.request.description !== this.response.description
        || this.request.relationshipWithTheProperty !== this.response.relationshipWithTheProperty
        || this.request.ownerName !== this.response.ownerName
        || this.request.ownerCpf !== this.response.ownerCpf
        || this.request.title !== this.response.title) {
        this.announcementService.updateStep5(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-six']);
      }
    } else if (this.response.typeOfAd === 'both') {
      if (this.request.description !== this.request.description
        || this.request.debitBalance !== this.response.debitBalance
        || this.imovelQuitadoValue !== this.response.propertyPaidOff
        || this.request.relationshipWithTheProperty !== this.response.relationshipWithTheProperty
        || this.request.ownerName !== this.response.ownerName
        || this.request.ownerCpf !== this.response.ownerCpf
        || this.request.title !== this.response.title) {
        this.announcementService.updateStep5(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-six']);
      }
    }

  }

  async registerSuccess(success) {
    const toast = await this.toastController.create({
      message: `Descrição, tipo do imóvel e dados do proprietário cadastrada com sucesso!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-six']);
  }

  async runError(error) {
    const toast = await this.toastController.create({
      message: `Erro ao cadastrar descrição, tipo do imóvel e dados do proprietário!`,
      duration: 1500,
      position: 'top',
      color: 'danger',
    });
    toast.present();
    console.error(error)
  }

  imovelQuitado(value) {
    if (value === 'yes') {
      this.imovelQuitadoValue = true;
    } else {
      this.imovelQuitadoValue = false;
    }
  }

}
