import { Component, Input, OnInit } from '@angular/core';
import { UserGetResponseDto } from '../../../../../dtos/user-get-response.dto';

@Component({
  selector: 'app-data-personal-view',
  templateUrl: './data-personal-view.component.html',
  styleUrls: ['./data-personal-view.component.scss'],
})
export class DataPersonalViewComponent implements OnInit {
  @Input() user: UserGetResponseDto;

  constructor() { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
  }

}
