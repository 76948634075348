import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { PropertyIndicationService } from 'src/app/services/property-indication.service';
import { environment } from 'src/environments/environment';
import { ListPropertyIndicationResponseDto } from '../../dtos/list-property-indication-response.dto';
import { PaginateQuerryRequestDto } from '../../dtos/paginate-querry-request.dto';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent implements OnInit {

  arrayArrowOpenModules: boolean[] = [];

  linkPropertyIndication = environment.linkPropertyIndication;

  isAccordionExpanded = false;

  modalFilter: boolean = false;
  form: FormGroup;
  pagination: number = 1;
  paginateQuerryRequest: PaginateQuerryRequestDto;
  propertyListAll: ListPropertyIndicationResponseDto[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private propertyIndicationService: PropertyIndicationService,
    private toastController: ToastController,
    private announcementService: AnnouncementService
  ) {
    this.form = this.formBuilder.group({
      all: [true],
      underAnalysis: [true],
      inProgress: [true],
      disapproved: [true],
      approved: [true]
    })
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.paginateQuerryRequest = {
      skip: 0,
      take: 0
    }

    this.propertyIndicationService.list(this.paginateQuerryRequest).subscribe(
      success => {
        this.propertyListAll = success
      },
      error => {
        console.error(error)
      }
    )
  }


  openFilter() {
    this.modalFilter = true;
  }

  closeFilter() {
    this.modalFilter = false;
  }

  changeAllFilter() {
    if (this.form.controls.all.value) {
      this.form.patchValue({
        underAnalysis: true,
        inProgress: true,
        disapproved: true,
        approved: true
      })

    } else {
      this.form.patchValue({ underAnalysis: false, inProgress: false, disapproved: false, approved: false })
    }
  }

  changeFilter() {
    if (this.form.controls.underAnalysis.value && this.form.controls.inProgress.value && this.form.controls.disapproved.value && this.form.controls.approved.value) {
      this.form.patchValue({ all: true })
    } else {
      this.form.patchValue({ all: false })
    }
  }
  disablechangeFilter(value: string) {
    if (value === 'underAnalysis')
      this.form.patchValue({ underAnalysis: false })
    if (value === 'inProgress')
      this.form.patchValue({ inProgress: false })
    if (value === 'disapproved')
      this.form.patchValue({ disapproved: false })
    if (value === 'approved')
      this.form.patchValue({ approved: false, })
  }

  async handlerFillter() {

    let array = [];
    let string = "";
    if (this.form.controls.underAnalysis.value) {
      array.push(`underAnalysis`);
    } if (this.form.controls.inProgress.value) {
      array.push(`inProgress`);
    } if (this.form.controls.disapproved.value) {
      array.push(`disapproved`);
    } if (this.form.controls.approved.value) {
      array.push(`approved`);
    }
    for (let i = 0; i < array.length; i++) {
      if (i == 0) {
        string += `${array[i]}`
      } else {
        string += `&processStatus=${array[i]}`
      }
    }
    this.paginateQuerryRequest = {
      skip: 0,
      take: 0,
      processStatus: string
    }
    this.propertyIndicationService.listByStatus(this.paginateQuerryRequest).subscribe(
      success => {
        this.propertyListAll = success;
        this.modalFilter = false;
      },
      error => {
        console.error(error)
      }
    )

    // this.modalFilter = false;
    //
    //
    // this.propertyListAll = [];
    //
    // if (this.form.controls.filter.value === 'all') {
    //
    //   this.paginateQuerryRequest = {
    //     skip: 0,
    //     take: 0
    //   }
    //
    //   this.propertyIndicationService.list(this.paginateQuerryRequest).subscribe(
    //     success => {
    //       this.propertyListAll = success;
    //       this.modalFilter = false;
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   )
    // } else if (this.form.controls.filter.value === 'underAnalysis') {
    //
    //   this.paginateQuerryRequest = {
    //     skip: 0,
    //     take: 0,
    //     processStatus: 'underAnalysis'
    //   }
    //
    //   this.propertyIndicationService.listByStatus(this.paginateQuerryRequest).subscribe(
    //     success => {
    //       this.propertyListAll = success;
    //       this.modalFilter = false;
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   )
    // } else if (this.form.controls.filter.value === 'inProgress') {
    //
    //   this.paginateQuerryRequest = {
    //     skip: 0,
    //     take: 0,
    //     processStatus: 'inProgress'
    //   }
    //
    //   this.propertyIndicationService.listByStatus(this.paginateQuerryRequest).subscribe(
    //     success => {
    //       this.propertyListAll = success;
    //       this.modalFilter = false;
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   )
    // } else if (this.form.controls.filter.value === 'disapproved') {
    //
    //   this.paginateQuerryRequest = {
    //     skip: 0,
    //     take: 0,
    //     processStatus: 'disapproved'
    //   }
    //
    //   this.propertyIndicationService.listByStatus(this.paginateQuerryRequest).subscribe(
    //     success => {
    //       this.propertyListAll = success;
    //       this.modalFilter = false;
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   )
    // } else if (this.form.controls.filter.value === 'approved') {
    //
    //   this.paginateQuerryRequest = {
    //     skip: 0,
    //     take: 0,
    //     processStatus: 'approved'
    //   }
    //
    //   this.propertyIndicationService.listByStatus(this.paginateQuerryRequest).subscribe(
    //     success => {
    //       this.propertyListAll = success;
    //       this.modalFilter = false;
    //     },
    //     error => {
    //       console.error(error)
    //     }
    //   )
    // } else if (
    //   this.form.controls.filter.value === '') {
    //   const toast = await this.toastController.create({
    //     message: `Selecione um filtro!`,
    //     duration: 1500,
    //     position: 'top',
    //     color: 'danger',
    //   });
    //   toast.present();
    // }

  }

  getAnnouncementByPropertyIndicationId(index: number, propertyIndicationId: string) {
    //@ts-ignore
    if (!this.propertyListAll.result[index]?.announcement) {
      this.announcementService.getAnnouncementAndFinancialMovementByPropertyIndicationId(propertyIndicationId).subscribe({
        next: response => {
          if (Object.keys(response).length > 0) {
            //@ts-ignore
            Object.assign(this.propertyListAll.result[index], {
              announcement: response.announcement,
              financialMovement: response.financialMovement
            })
          }

        },
        error: err => {
          console.error(err);
        }
      });
    }
  }

  async copyToClipboard(link: string) {
    navigator.clipboard.writeText(`${this.linkPropertyIndication}${link}`);
    const toast = await this.toastController.create({
      message: `Link copiado para área de transferência!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }

}
