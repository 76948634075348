import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { AnnouncementGetResponseDto } from '../../../dtos/announcement/announcement-get-response.dto';
import { AnnouncementRegister10RequestDto } from '../../../dtos/announcement/announcement-register-10-request.dto';
import { AnnouncementService } from '../../../services/announcement.service';
import { ModelsAdvertiseStageTenComponent } from '../components/modal-advertise-stage-ten/models-advertise-stage-ten.component';

@Component({
  selector: 'app-advertise-property-stage-ten',
  templateUrl: './advertise-property-stage-ten.component.html',
  styleUrls: ['./advertise-property-stage-ten.component.scss'],
})
export class AdvertisePropertyStageTenComponent implements OnInit {

  form: FormGroup;
  valueCurrency: 0;

  request: AnnouncementRegister10RequestDto;

  response: AnnouncementGetResponseDto;

  viewSale = true;

  rentValue: number = 0;
  iptuInstallmentValue: number = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private toastController: ToastController,
    private modalController: ModalController
  ) {
    this.form = this.formBuilder.group({
      exclusivity: ['', [Validators.required]],
    });
  }



  ngOnInit() { }

  ionViewWillEnter() {
    this.announcementService.getById(localStorage.getItem('announcementId')).subscribe(
      success => this.organizeData(success),
      error => console.error(error)
    )
  }

  organizeData(success) {
    this.response = success;

    if (success.exclusivity !== null) {
      this.form.patchValue({
        exclusivity: success.exclusivity
      })
    }
    this.iptuInstallmentValue = (Number(this.response?.valueOfIptu || 0)/12);

  }

  view(valueView: string) {
    if(valueView === 'rent') {
      this.viewSale = false
    } else {
      this.viewSale = true
    }
  }

  changeRentValue(tax: number) {
    this.rentValue = Number(this.response?.leaseValue || 0) - (Number(this.response?.leaseValue || 0) * tax) + (Number(this.response?.valueOfIptu || 0)/12);
  }

  async openModalAdvertiseStageTen() {
    const modal = await this.modalController.create({
      component: ModelsAdvertiseStageTenComponent
    });
    return await modal.present();
  }

  async confirm() {
    if (this.form.controls['exclusivity'].value !== '') {
      this.request = Object.assign({}, this.form.value);
      if (this.response.exclusivity !== this.request.exclusivity) {
        this.announcementService.updateStep10(localStorage.getItem('announcementId'), this.request).subscribe(
          success => this.registerSuccess(success),
          error => this.runError(error)
        )
      } else {
        this.router.navigate(['logged/advertise-property-stage-eleven'])
      }
    } else {
      const toast = await this.toastController.create({
        message: `Escolha a modalidade do anuncio!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
    }
  }

  async registerSuccess(success) {
    localStorage.setItem('announcementId', success._id);
    this.form.reset();
    this.router.navigate(['logged/advertise-property-stage-eleven']);
  }

  async runError(error) {
    console.error(error)
  }

  backAdvertisePropertyStageOne() {
    if (this.response.typeOfAd === 'sale') {
      this.router.navigate(['logged/advertise-property-stage-nine'])
    }
    this.router.navigate(['logged/advertise-property-stage-seven']);
  }

}
