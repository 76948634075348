import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { IndicateRequestDto } from '../../dtos/indicate-request.dto';
import { PropertyIndicationOwnerDto } from '../../dtos/property-indication-owner.dto';
import { UserGetResponseDto } from '../../dtos/user-get-response.dto';
import { CepService } from '../../services/cep.service';
import { PropertyIndicationService } from '../../services/property-indication.service';
import { Cep } from '../../utils/cep';

@Component({
  selector: 'app-indicate',
  templateUrl: './indicate.component.html',
  styleUrls: ['./indicate.component.scss'],
})
export class IndicateComponent implements OnInit {

  public maskCep: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,];
  public maskTel: Array<any> = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  form: FormGroup;

  ownerRequest: PropertyIndicationOwnerDto;
  ownerGroup: any = [];

  request: IndicateRequestDto;
  name: string;
  email:string;
  isNameDisabled: boolean = false;
  isEmailDisabled: boolean = false;

  modalSuccess: boolean = false;

  user: UserGetResponseDto;

  constructor(
    private formBuilder: FormBuilder,
    private cepService: CepService,
    private toastController: ToastController,
    private propertyIndicationService: PropertyIndicationService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      cep: ['', [Validators.required]],
      road: ['', [Validators.required]],
      numberResidence: ['', [Validators.required]],
      complement: [''],
      ownername: [''],
      email: [''],
      ownerContact: [''],
      itsLeasing: [false],
      itsSale: [false],
    })
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));

    this.form.controls['cep'].valueChanges.subscribe(value => {
      if(value.length === 9) {
        this.buscarCep();
      }
    });
  }


  buscarCep() {
    if (this.form.controls.cep.value.length === 9) {
      const cep = this.form.controls.cep.value.replace(/\D/g, '');

      if (cep) {
        this.cepService.buscarCep(cep).then((cep: Cep) => {
          this.form.patchValue({
            road: cep.logradouro,
          });
        });
      }
    }
  }

  async addContent() {
    this.isNameDisabled = true;
    this.isEmailDisabled = true;

    if (this.form.controls.ownerContact.value === '' || this.form.controls.ownername.value === '') {
      const toast = await this.toastController.create({
        message: `Preencha todos os campos para adicionar proprietário!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
    } else {
      this.ownerRequest = {
        contact: `+55${this.form.controls.ownerContact.value.replace(/\D/g, '')}`,
        email: this.form.controls.email.value,
        name: this.form.controls.ownername.value,
      }

      this.ownerGroup.push(this.ownerRequest);

      this.form.patchValue({
        ownerContact: '',
      })
    }
  }

 async addOwnerToGroup():Promise<boolean> {
    if (this.form.controls.ownerContact.value === '' || this.form.controls.ownername.value === '') {
      const toast = await this.toastController.create({
        message: `Preencha todos os campos para adicionar proprietário!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
      return false
    } else {
      this.ownerRequest = {
        contact: `+55${this.form.controls.ownerContact.value.replace(/\D/g, '')}`,
        email: this.form.controls.email.value,
        name: this.form.controls.ownername.value,
      }

      this.ownerGroup.push(this.ownerRequest);
      return true
    }
  }

  removeOwner(item) {
    this.ownerGroup.splice(item, 1);
  }

  clearinput() {
    this.form.reset();
  }

  async confirmIndicate() {

    if (this.form.controls['itsLeasing'].value === '' && this.form.controls['itsSale'].value === '') {
      const toast = await this.toastController.create({
        message: `Escolha o tipo de indicação!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
      return
    } else {

      /* this.addContent(); */

      this.registerIndication();
    }
  }

   async registerIndication() {
  
    if (await this.addOwnerToGroup()) {
    this.request = {
      cep: this.form.controls['cep'].value.replace(/\D/g, ''),
      address: this.form.controls['road'].value,
      number: this.form.controls['numberResidence'].value,
      complement: this.form.controls['complement'].value,
      itsLeasing: this.form.controls['itsLeasing'].value,
      itsSale: this.form.controls['itsSale'].value,
      owners: this.ownerGroup
    }

    this.propertyIndicationService.register(this.request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Propriedade indicada com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();

        this.ownerGroup = [];

        this.form.reset();
        this.modalSuccess = true;
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao indicar propriedade!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
      }
    )
  }
}

  closeModal() {
    this.modalSuccess = false;
    setTimeout(() => {
      this.router.navigate(['logged/property-list']);
    }, 500);
  }

}
