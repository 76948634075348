import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { CounterProposalRequestDto } from '../../../../../../dtos/counter-proposal-request.dto';
import { ProposalGetResponseDto } from '../../../../../../dtos/proposal-get-response.dto';
import { ProposalService } from '../../../../../../services/proposal.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-counter-proposal',
  templateUrl: './counter-proposal.component.html',
  styleUrls: ['./counter-proposal.component.scss'],
})
export class CounterProposalComponent implements OnInit {
  form: FormGroup;
  editpenproperty = true;
  editpenvehicle = true;
  editDirectInstallInput: boolean = true;
  editpropertyinput = true;
  editvehicleinput = true;
  editFinancingInput: boolean = true;
  response: any;
  proposalById: any;
  announcement: any;


  request: CounterProposalRequestDto;

  typeSale: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private proposalService: ProposalService,
    private toastController: ToastController,
    private decimalPipe: DecimalPipe
  ) {
    this.form = this.formBuilder.group({
      checkProperty: [false, [Validators.required]],
      checkVehicle: [false, [Validators.required]],
      valueProperty: ['', [Validators.required]],
      valueVehicle: ['', [Validators.required]],
      checkInstallment: [false, [Validators.required]],
      checkfinancing: [false, [Validators.required]],
      suggestedRentAmount: [''],
      suggestedSaleAmount: [''],
      saleFinancingAsPaymentAmount: [''],
      salePropertyAsPaymentAmount: [''],
      saleCarAsPaymentAmount: [''],
      saleDirectInstallmentAsPaymentAmount: ['']
    });
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    let proposal: any = localStorage.getItem('proposalId');
    proposal = JSON.parse(proposal)
    this.proposalById = proposal;

    this.response = proposal;
    if (proposal?.counterProposal) {
      this.proposalById = proposal.counterProposal;
    } else {
      this.proposalById = proposal.proposal;
    }

    if (proposal.proposal.type === 'sale') {
      this.typeSale = true;
    } else {
      this.typeSale = false;
    }
    this.announcement = proposal.proposal.announcement;

  }


  send() {
    this.request = {
      type: this.response.type,
      suggestedRentAmount: parseFloat(this.toNumber(this.form.controls['suggestedRentAmount'].value).toFixed(2)),
      suggestedSaleAmount: parseFloat(this.toNumber(this.form.controls['suggestedSaleAmount'].value).toFixed(2)),
      saleFinancingAsPaymentAmount: parseFloat(this.toNumber(this.form.controls['saleFinancingAsPaymentAmount'].value).toFixed(2)),
      salePropertyAsPaymentAmount: parseFloat(this.toNumber(this.form.controls['salePropertyAsPaymentAmount'].value).toFixed(2)),
      saleCarAsPaymentAmount: parseFloat(this.toNumber(this.form.controls['saleCarAsPaymentAmount'].value).toFixed(2)),
      saleDirectInstallmentAsPaymentAmount: parseFloat(this.toNumber(this.form.controls['saleDirectInstallmentAsPaymentAmount'].value).toFixed(2)),
      parentProposalId: this.proposalById._id
    }


    this.proposalService.counterProposal(this.request).subscribe({
      next: async data => {
        const toast = await this.toastController.create({
          message: `Contraproposta enviada com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.router.navigate(['logged/owner-registration']);
      },
      error: async error => {
        const toast = await this.toastController.create({
          message: `Erro ao enviar contraproposta!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        console.error(error)
      }
    })
  }

  public toNumber(paremetro1: string) {
    return Number(paremetro1)
  }

  public clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  cancel() {
    this.router.navigate(['logged/property-proposal']);
  }

  editProperty() {
    this.editpenproperty = !this.editpenproperty;
    this.editpropertyinput = !this.editpropertyinput;
  }

  editDirectInstall() {
    this.editDirectInstallInput = !this.editDirectInstallInput
  }

  editFinancing() {
    this.editFinancingInput = !this.editFinancingInput
  }

  editVehicle() {
    this.editpenvehicle = !this.editpenvehicle;
    this.editvehicleinput = !this.editvehicleinput;
  }
}
