import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AnnouncementVisitGetResponseDto } from 'src/app/dtos/announcement-visit/announcement-visit-get-response.dto';
import { AnnouncementVisitService } from 'src/app/services/announcement-visit.service';
import { DatamokService } from 'src/app/services/datamok.service';

@Component({
  selector: 'app-broker-registration',
  templateUrl: './broker-registration.component.html',
  styleUrls: ['./broker-registration.component.scss'],
})
export class BrokerRegistrationComponent implements OnInit {
  changeSubscription: Subscription;
  changeSubscriptionHeader: Subscription;
  changeSubscriptionShowHeader: Subscription;

  annoucementVisitListAll: AnnouncementVisitGetResponseDto[];
  
  isModalscheduling = false;
  isModalsproposals = false;
  headeredittime = false;
  headertophome = true;
  newspage = true;
  contractspage = false;
  visitspage = false;
  home = true;
  owner: any;
  user = {
    name: '',
  };

  schedulesNews: any = [];

  schedulesSelected: any;

  today: Date =  new Date();

  constructor(
    private datamockservice: DatamokService,
    private announcementVisitService: AnnouncementVisitService,
    private router: Router,
    private toastController: ToastController,
  ) {
    this.changeSubscription = this.datamockservice.getcloseAnnouncment().subscribe(() => {
      this.isModalsproposals = true;
    });
    this.changeSubscriptionHeader = this.datamockservice.getchangeHeader().subscribe(() => {
      this.headeredittime = true;
      this.headertophome = false;
    });
    this.changeSubscriptionShowHeader = this.datamockservice.getshowHeaderTop().subscribe(() => {
      this.headeredittime = false;
      this.headertophome = true;
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userDto'));
    this.announcementVisitService.list().subscribe(
      success => {        
        this.annoucementVisitListAll = success
        console.log(this.annoucementVisitListAll)
      },
      error => {
        console.error(error)
      }
    )
  }
  setOpen(isOpen: boolean) {
    this.isModalscheduling = isOpen;
  }
  closeAnnouncment() {
    this.isModalsproposals = false;
  }
  closeEditTime() {
    this.datamockservice.closeEditTimeTop();
    this.headeredittime = false;
    this.headertophome = true;
  }

  async acceptVisit(item) {
    let filter = this.schedulesNews.indexOf(item);
    //const result = this.announcementVisitService.updateVisitBroker(item);
    this.announcementVisitService.updateVisitBroker(item).subscribe(
      async success =>{
        this.schedulesNews.splice(filter, 1);
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        this.announcementVisitService.Visitlist().subscribe(
          success => {
            this.annoucementVisitListAll = success
          },
          error => {
            console.error(error)
          }
        )
        toast.present();
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();

      }

    )
  
  }

  async refuseVisit() {
    let filter = this.schedulesNews.indexOf(this.schedulesSelected);
    this.schedulesNews.splice(filter, 1);

    const toast = await this.toastController.create({
      message: `Sua visita foi removida da lista!`,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();


    this.isModalsproposals = false
  }

  openRefuseVisit(item) {
    this.schedulesSelected = item;
    this.isModalsproposals = true
  }


}
