import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { UserUpdateWorkDataRequestDto } from '../../../../../dtos/user-update-work-data-request.dto';
import { CepService } from '../../../../../services/cep.service';
import { UserService } from '../../../../../services/user.service';
import { Cep } from '../../../../../utils/cep';

@Component({
  selector: 'app-work-data-form',
  templateUrl: './work-data-form.component.html',
  styleUrls: ['./work-data-form.component.scss'],
})
export class WorkDataFormComponent implements OnInit {

  form: FormGroup;

  request: UserUpdateWorkDataRequestDto;

  seeAddMoreFuctions: boolean = false;

  public maskTel: Array<any> = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public maskCep: Array<any> = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  fuctionsRequest: any = [];


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cepService: CepService,
    private toastController: ToastController
  ) {
    this.form = this.formBuilder.group({
      workSchedule: ['', [Validators.required]],
      morningPorter1: ['', [Validators.required]],
      morningPorter2: [''],
      afternoonPorter1: [''],
      nigthPorter1: ['', [Validators.required]],
      nigthPorter2: [''],
      janitor: ['', [Validators.required]],
      extraPorter: [''],
      vigilant: [''],
      supervisor: [''],
      function: [''],
      functionName: [''],
      functionContact: [''],
      manager: [''],
      name: ['', [Validators.required]],
      addressStreet: ['', [Validators.required]],
      addressNumber: ['', [Validators.required]],
      addressDistrict: ['', [Validators.required]],
      addressCep: ['', [Validators.required]],
      addressComplement: ['', [Validators.required]],
      syndic: ['', [Validators.required]],
      syndicContact: ['', [Validators.required]],
      category: ['', [Validators.required]],
      numberOfTowers: ['', [Validators.required]],
      howManyFloors: ['', [Validators.required]],
      complement: ['', [Validators.required]],
      apartmentsPerrFloor: ['', [Validators.required]],
      howManyApartmentsForLease: ['', [Validators.required]],
      howManyApartmentsForSale: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    let user: any = localStorage.getItem('userDto');
    user = JSON.parse(user);

    if (user.workData !== null) {
      if (user.workData.workSchedule === '12x36') {
        this.form.patchValue({
          workSchedule: user.workData.workSchedule,

          morningPorter1: user.workData.morningPorter1,

          morningPorter2: user.workData.morningPorter2,

          nigthPorter1: user.workData.nigthPorter1,

          nigthPorter2: user.workData.nigthPorter2,

          janitor: user.workData.janitor,
        })
      } else if (user.workData.workSchedule === '5x1') {
        this.form.patchValue({
          workSchedule: user.workData.workSchedule,

          morningPorter1: user.workData.morningPorter1,

          afternoonPorter1: user.workData.morningPorter1,

          nigthPorter1: user.workData.nigthPorter1,

          janitor: user.workData.janitor,

          extraPorter: user.workData.extraPorter,
        })
      }
      this.form.patchValue({
        name: user.workData.condominium.name,

        addressStreet: user.workData.condominium.addressStreet,

        addressNumber: user.workData.condominium.addressNumber,

        addressDistrict: user.workData.condominium.addressDistrict,

        addressCep: user.workData.condominium.addressCep,

        addressComplement: user.workData.condominium.addressComplement,

        syndic: user.workData.condominium.syndic,

        syndicContact: user.workData.condominium.syndicContact,

        category: user.workData.condominium.category,

        numberOfTowers: user.workData.condominium.numberOfTowers,

        howManyFloors: user.workData.condominium.howManyFloors,

        complement: user.workData.condominium.complement,

        apartmentsPerrFloor: user.workData.condominium.apartmentsPerrFloor,

        howManyApartmentsForLease: user.workData.condominium.howManyApartmentsForLease,

        howManyApartmentsForSale: user.workData.condominium.howManyApartmentsForSale,
      })
    }
  }

  seeAddMoreFunctions() {
    this.seeAddMoreFuctions = true;
  }

  addMoreFunction() {

    let fuctionContent: any = {
      function: this.form.controls.function.value,
      name: this.form.controls.functionName.value,
      phone: this.form.controls.functionContact.value,
    }

    this.fuctionsRequest.push(fuctionContent);

  }

  removeFuction(item) {
    this.fuctionsRequest.splice(item, 1);
  }

  async confirm() {

    if (this.seeAddMoreFuctions === true) {
      if (this.form.controls.function.value !== '' || this.form.controls.functionName.value !== '' || this.form.controls.functionContact.value !== '') {
        this.addMoreFunction();
      }
    }

    let condominioDto = {
      name: this.form.controls.name.value,

      addressStreet: this.form.controls.addressStreet.value,

      addressNumber: this.form.controls.addressNumber.value,

      addressDistrict: this.form.controls.addressDistrict.value,

      addressCep: this.form.controls.addressCep.value,

      addressComplement: this.form.controls.addressComplement.value,

      syndic: this.form.controls.syndic.value,

      syndicContact: this.form.controls.syndicContact.value,

      category: this.form.controls.category.value,

      numberOfTowers: parseInt(this.form.controls.numberOfTowers.value.toString().replace(/\D/g, '')),

      howManyFloors: parseInt(this.form.controls.howManyFloors.value.toString().replace(/\D/g, '')),

      complement: this.form.controls.complement.value,

      apartmentsPerrFloor: parseInt(this.form.controls.apartmentsPerrFloor.value.toString().replace(/\D/g, '')),

      howManyApartmentsForLease: parseInt(this.form.controls.howManyApartmentsForLease.value.toString().replace(/\D/g, '')),

      howManyApartmentsForSale: parseInt(this.form.controls.howManyApartmentsForSale.value.toString().replace(/\D/g, '')),
    }


    if (this.form.controls.workSchedule.value === '12x36') {

      this.request = {
        workSchedule: this.form.controls.workSchedule.value,

        morningPorter1: this.form.controls.morningPorter1.value,

        morningPorter2: this.form.controls.morningPorter2.value,

        nigthPorter1: this.form.controls.nigthPorter1.value,

        nigthPorter2: this.form.controls.nigthPorter2.value,

        janitor: this.form.controls.janitor.value,

        condominium: condominioDto,

        functions: this.fuctionsRequest
      }
      this.registerUpdate();
    } else if (this.form.controls.workSchedule.value === '5x1') {
      this.request = {
        workSchedule: this.form.controls.workSchedule.value,

        morningPorter1: this.form.controls.morningPorter1.value,

        afternoonPorter1: this.form.controls.afternoonPorter1.value,

        nigthPorter1: this.form.controls.nigthPorter1.value,

        janitor: this.form.controls.janitor.value,

        extraPorter: this.form.controls.extraPorter.value,

        condominium: condominioDto,

        functions: this.fuctionsRequest
      }
      this.registerUpdate();
    } else {
      const toast = await this.toastController.create({
        message: `Escolha o tipo de escala!`,
        duration: 1500,
        position: 'top',
        color: 'danger',
      });
      toast.present();
    }

  }

  registerUpdate() {


    this.userService.editWorkData(this.request).subscribe(
      async success => {
        const toast = await this.toastController.create({
          message: `Dados alterados com sucesso!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.getUser();
      }, async error => {
        const toast = await this.toastController.create({
          message: `Erro ao alterar dados!`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        console.error(error)
      }
    )
  }

  getUser() {
    this.userService.getUser().subscribe(
      success => {
        let user: any = JSON.stringify(success);
        localStorage.setItem('userDto', user)
      },
      error => {
        console.error(error)
      }
    )
  }

  buscarCep(event) {

    if (event.target.value.length === 9) {
      const cep = this.form.controls.addressCep.value;

      if (cep)
        this.cepService.buscarCep(cep).then((cep: Cep) => {
          this.form.patchValue({
            addressStreet: cep.logradouro,
            addressDistrict: cep.bairro,
          });
        });
    }

  }



  selectCategory = [
    { category: 'Apartamentos' },
    { category: 'Casas' },
    { category: 'Mansões' },
    { category: 'Casarão' },
    { category: 'Palácio' },
    { category: 'Galpão' },

  ]

  // SELECT UNITY
  selectUnity = [
    { unity: '1' }, { unity: '2' }, { unity: '3' }, { unity: '4' }, { unity: '5' }, { unity: '6' }, { unity: '7' }, { unity: '8' }, { unity: '9' }, { unity: '10' }, { unity: '11' }, { unity: '12' }, { unity: '13' }, { unity: '14' }, { unity: '15' }, { unity: '16' }, { unity: '17' }, { unity: '18' }, { unity: '19' }, { unity: '20' }, { unity: '21' }, { unity: '22' }, { unity: '23' }, { unity: '24' }, { unity: '25' }, { unity: '26' }, { unity: '27' }, { unity: '28' }, { unity: '29' }, { unity: '30' }, { unity: '31' }, { unity: '32' }, { unity: '33' }, { unity: '34' }, { unity: '35' }, { unity: '36' }, { unity: '37' }, { unity: '38' }, { unity: '39' }, { unity: '40' }, { unity: '41' }, { unity: '42' }, { unity: '43' }, { unity: '44' }, { unity: '45' }, { unity: '46' }, { unity: '47' }, { unity: '48' }, { unity: '49' }, { unity: '50' },
  ]



}
