import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ToastController} from "@ionic/angular";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PhotographerService} from "../../../../services/photographer.service";
import {ProfileClientEnum} from "../../../../dtos/enum/profile-client.enum";
import {AcceptPhotographerDto} from "../../../../dtos/accept-photographer.dto";

@Component({
  selector: 'app-scheduled-photographer',
  templateUrl: './scheduled-photographer.component.html',
  styleUrls: ['./scheduled-photographer.component.scss'],
})
export class ScheduledPhotographerComponent implements OnInit {

  optionsPhotographer:any [];
  Selected: any;
  formCancel: FormGroup

  photographerList:any [] = [];

  photographerCancelId: number;

  request: AcceptPhotographerDto

  isModalsproposals = false;
  constructor(
    private router: Router,
    private toastController: ToastController,
    private formBuilder: FormBuilder,
    private photographerService:PhotographerService,
  ) {
    this.formCancel = this.formBuilder.group({
      becauseCancel: ['']
    })
  }
  ngOnInit() {
    this.listphotographer();
  }

  goToAddPhotos(_id: string, photo_id: string, i: number) {
    this.photographerService.announcement_Id = _id
    this.photographerService.photo_id = photo_id


    this.router.navigate(['/logged/photographer-add-photos'])
    this.photographerList.splice(i,1)

  }


  listphotographer() {
    this.photographerService.listPhotographer().subscribe(
      success => {
        for (const item of success) {
          if (item.status == 'scheduledphoto'){
            this.photographerList.push(item)
          }
        }
      },
      error => { console.error(error) }
    )
  }

  async OpenModalCancel(i: number) {
  this.photographerCancelId = i
    this.isModalsproposals = true

  }

  closeModal() {
    this.isModalsproposals = false;
  }
  openCancelarVisita() {
    this.isModalsproposals = true
  }
  async confirmCancel() {
    this.request ={
      status : 'pendingPhotos',
      photographerId: ProfileClientEnum.fotografo
    };
    this.photographerService.updateStatus(this.photographerList[this.photographerCancelId]._id, this.request).subscribe(
      async success =>{
        this.photographerList.splice(this.photographerCancelId,1)
        const toast = await this.toastController.create({
          message: `Visita agendada!`,
          duration: 1500,
          position: 'top',
          color: 'success',
        });
        toast.present();
        this.isModalsproposals = false
      },
      async error => {
        const toast = await this.toastController.create({
          message: `Erro ao agendar visita`,
          duration: 1500,
          position: 'top',
          color: 'danger',
        });
        toast.present();
        this.isModalsproposals = false

      }

    )
  }
}
