import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { InsertCodeComponent } from './insert-code/insert-code.component';
import { InsertTelComponent } from './insert-tel/insert-tel.component';
import { LoginOrRegisterComponent } from './login-or-register/login-or-register.component';
import { SignUpComponent } from './sign-up/sign-up.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login-or-register',
                component: LoginOrRegisterComponent,
            },
            {
                path: 'pre-anuncio/:anuncioId/advertiser/:email',
                component: LoginOrRegisterComponent,
            },
            {
              path: 'login-or-register/:id',
              component: LoginOrRegisterComponent,
          },
            {
                path: 'insert-tel',
                component: InsertTelComponent,
            },
            {
                path: 'insert-code',
                component: InsertCodeComponent,
            },
            {
                path: 'sign-up',
                component: SignUpComponent,
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRouterModule { }
