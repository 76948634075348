import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private toastController: ToastController,
        private router: Router,
    ) { }

    async canActivate() {

        if (!this.authenticationService.getAuthenticatedUser()) {

            this.authenticationService.removeAuthenticatedUser();

            const toast = await this.toastController.create({
                message: `Usuário não autenticado!`,
                duration: 1500,
                position: 'top',
                color: 'warning',
            });
            toast.present();


            this.router.navigate(['/auth/insert-tel']);

        }

        return true;
    }
}